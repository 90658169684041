<template>
    <div class="mt-xl-2 mb-3 bg-gray text-xs">
        <div class="row">
            <div class="col-12 d-flex flex-column flex-md-row flex-wrap ms-4 mt-3">
                <div class="mb-2 me-md-3 pe-5 pe-md-0 ms-md-4 justify-content-center">
                    <span class="label">From:</span>
                    <span class="value font-weight-bold ms-2">{{ selectedSchemes?.[0]?.schemeName }}</span>
                </div>
                <div class="mb-2 me-md-3">
                    <span v-if="folioData?.folioNumber" class="label">Folio No:</span>
                    <span v-if="folioData?.folioNumber" class="value font-weight-bold ms-2">{{ folioData?.folioNumber ||
                        'N/A' }}</span>
                </div>
                <div class="mb-2 me-md-3">
                    <span v-if="folioData?.amount" class="label">Total Amount:</span>
                    <span v-if="folioData?.amount" class="value font-weight-bold ms-2">₹{{ folioData?.amount || 'N/A'
                        }}</span>
                </div>
                <div v-if="selectedAmount" class="mb-2 me-md-3">
                    <span class="label">Selected Amount:</span>
                    <span class="value font-weight-bold ms-2">₹{{ selectedAmount }}</span>
                </div>
                <div v-if="stpData?.amount" class="mb-2 me-md-3 ms-md-4 ms-xl-0">
                    <span class="label">Selected Amount:</span>
                    <span class="value font-weight-bold ms-2">₹{{ stpData?.amount }}</span>
                </div>
                <div v-if="stpData?.frequency" class="mb-2 me-md-3">
                    <span class="label">Frequency:</span>
                    <span class="value font-weight-bold ms-2">{{ stpData?.frequency || 'N/A'
                        }}</span>
                </div>
                <div v-if="stpData?.date" class="mb-2 me-md-3">
                    <span class="label">STP Date:</span>
                    <span class="value font-weight-bold ms-2">{{ stpData?.date || 'N/A'
                        }}</span>
                </div>
                <div v-if="stpData?.installment" class="mb-2 me-md-3">
                    <span class="label">No of Installment:</span>
                    <span class="value font-weight-bold ms-2">{{ stpData?.installment || 'N/A'
                        }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Second Line -->
            <div class="col-12 d-flex flex-column flex-md-row flex-wrap ms-4">
                <div v-if="schemeData?.schemeName" class="mb-2 me-md-3 pe-5 pe-md-0 ms-md-4 justify-content-center">
                    <span class="label">To:</span>
                    <span class="value font-weight-bold ms-xl-4 ms-2">{{ schemeData?.schemeName }}</span>
                </div>
                <div class="mb-3">
                    <span v-if="schemeData?.minimumPurchaseAmount" class="label">Min Amount:</span>
                    <span v-if="schemeData?.minimumPurchaseAmount" class="value">{{ minAmount }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: 'SwitchBand',
    data() {
        return {
            schemes: [],
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    props: {
        folioData: {
            type: Object,
            default: () => ({})
        },
        schemeData: {
            type: Object,
            default: () => ({})
        },
        selectedAmount: {
            type: String,
            default: "",
        },
        stpData: {
            type: Object,
            default: () => ({})
        },

    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
    },
};
</script>