import { defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
// Password strength validator
Object.keys(rules).forEach((rule) => {
    defineRule(rule, rules[rule]);
  });
defineRule('password', (value) => {
  if (!value) {
    return "Password is required.";
  }
  if (value.length < 8) {
    return "Password must be at least 8 characters long.";
  }
  if (!/[A-Z]/.test(value)) {
    return "Password must have an uppercase letter.";
  }
  if (!/[a-z]/.test(value)) {
    return "Password must have a lowercase letter.";
  }
  if (!/[0-9]/.test(value)) {
    return "Password must include a number.";
  }
  if (!/[^A-Za-z0-9]/.test(value)) {
    return "Password must include a special character.";
  }
  return true;
});
defineRule('phone', value => {
  if (!value) {
    return "Phone number is required";
  }
  if (!/^\d{10}$/.test(value)) {
    return "Phone number must contain exactly 10 numbers";
  }
  return true;
});
defineRule('email', value => {
  if (!value) {
    return "Email is required.";
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return "Enter a valid email.";
  }
  return true;
});
defineRule('terms', value => {
  if (value === 'on') {
    return "Please accept the terms and conditions.";
  }
  return true;
});
defineRule('check', value => {
  if (value === 'on') {
    return "Select the checkbox above to proceed.";
  }
  return true;
});
defineRule('requiredSelect', (value) => {
  const placeholderRegex = /^Select\s/i;

  if (!value || placeholderRegex.test(value)) {
    return 'Please select a valid option.';
  }
  return true;
});
defineRule('customAccountNumber', value => {
  if (!value) {
    return "Account number is required.";
  }

  if (value.length < 9) {
    return "Account number must be at least 9 digits.";
  }
  if (value.length > 18) {
    return "Account number must not exceed 18 digits.";
  }

  const accountNumberRegex = /^\d+$/;
  if (!accountNumberRegex.test(value)) {
    return "Account number must contain only digits.";
  }

  return true;
});
defineRule('ifsc', value => {
  if (!value) {
    return "The IFSC Code is required";
  }
  if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
    return "Invalid IFSC Code format";
  }
  return true;
});
defineRule('validDob', (value, guardianName) => {
  if (!value) {
    return 'Date of Birth is required.';
  }

  const today = new Date();
  const birthDate = new Date(value);

  if (isNaN(birthDate)) {
    return 'Invalid Date of Birth.';
  }

  if (birthDate > today) {
    return 'Date of Birth cannot be in the future.';
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  if (age < 18) {
    if (!guardianName) {
      return 'Guardian name required for minors.';
    } else {
      return true;
    }
  }

  return true;
});
import { configure } from "vee-validate";
configure({
  validateOnInput: true,
});
