<template>
    <div class="card multisteps-form__panel selection-card-height border-radius-xl bg-white shadow-top-bottom "
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :descriptionText="descriptionText" :pageName="pageName" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content px-4 mt-4 mb-6">
                    <div class="card-body p-0 pt-3 px-3 my-3">
                        <Form role="form text-left" @submit="onSubmit">
                            <div class="row">
                                <div class="col-4">
                                    <span class="text-xs">Enter the Amount</span>
                                </div>
                                <div class="col-8 ps-4">
                                    <div class="input-group mb-md-1 mb-3 justify-content-end">
                                        <soft-input id="amount"
                                            :rules="{ required: true, min_value: minimumRedemptionAmount, max_value: maxValue }"
                                            type="text" placeholder="Enter Amount" v-model="selectedAmount"
                                            name="Amount" :disabled="!switchAll" @input="onInputChange" />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-2 mt-2">
                                <soft-checkbox id="switchAll" name="switchAll" checked="false" v-model="switchAll">
                                    <template v-slot:label>
                                        <label class="font-weight-bold">
                                            Switch all</label>
                                    </template>
                                </soft-checkbox>
                            </div>
                            <hr class="text-light-mauve font-weight-light">
                            <div class="row mt-4 pb-3">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <span class="text-xxs me-1"> Total amount available (Approx)</span>
                                        <i class="fa-solid fa-circle-exclamation fa-xs icon-background-color mt-1"
                                            :title=infoTitle></i>
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <span class="ms-2 w-100 text-xs text-end font-weight-bolder">
                                                ₹ {{ formatAmount(folioData?.amount) }}
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between pt-7">
                                    <soft-button @click.prevent="previous"
                                        class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left"
                                        type="button">
                                        <i class="fas fa-chevron-left" aria-hidden="true"></i>
                                        <span class="ms-2">previous</span>
                                    </soft-button>
                                    <soft-button ref="buttonRef" class="bg-primary icon-move-right" type="submit">
                                        Next
                                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                                    </soft-button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftCheckbox from "@/views/common/components/SoftCheckbox.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { Form } from "vee-validate";
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';

export default {
    name: "Folios",
    components: {
        SoftButton,
        SoftCheckbox,
        SoftInput,
        Form,
        LeftPanelView,
    },
    data() {
        return {
            schemes: [],
            selectedFolioNumber: null,
            switchAll: "on",
            selectedAmount: "",
            pageName: "Amount",
            descriptionText: 'Specify the desired amount you wish to switch, or choose to transfer the entire balance.',
            infoTitle:"The total amount available in the folio"
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    props: {
        folioData: {
            type: Object,
            default: () => ({})
        },
        currentStep: Number,
    },
    watch: {
        switchAll(newVal) {
            if (newVal) {
                this.selectedAmount = "";
            } else {
                this.selectedAmount = this.folioData?.amount || '';
            }
        },
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        minimumRedemptionAmount() {
            return this.selectedSchemes[0]?.minimumRedemptionAmount;
        },
        maximumRedemptionAmount() {
            return this.selectedSchemes[0]?.maximumRedemptionAmount;
        },
        maxValue() {
            if (this.maximumRedemptionAmount == 0) {
                return this.folioData?.amount;
            } else {
                return this.maximumRedemptionAmount;
            }
        },
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
    },
    methods: {
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
        },
        previous() {
            this.resetData();
            this.$parent.prevStep();
        },
        resetData() {
            this.$emit('update', null);
            this.selectedAmount = "";
        },
        onSubmit() {
            this.$emit('update', this.selectedAmount);
            this.$parent.nextStep();
        },
        onInputChange(event) {
            const value = event.target.value.replace(/[^0-9]/g, '');
            this.selectedAmount = value;
            event.target.value = value;
        },
    }

};
</script>