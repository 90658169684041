<template>
    <div class="card multisteps-form__panel selection-card-height border-radius-xl bg-white shadow-top-bottom "
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :pageName="pageName" :descriptionText="descriptionText" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content">
                    <div class="card-body mb-4 pt-5">
                        <div v-if="message.workFlow == 'SUCCESS'">
                            <div class="mt-3 align-item-center ms-2">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div class="card-body ps-4 py-3">
                                <div>
                                    <h4 class="text-center me-4 ps-3 mb-3">Order successful</h4>
                                    <p class="justify-content-center text-xxs text-center mb-3 text-capitalize">
                                        Your {{ requestType }} request for
                                        <b class="mx-1">₹{{ displayAmount }}</b> has been successfully processed.
                                    </p>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small class="text-center text-xs">Ref: <b>OS-{{ orderId }}</b></small>
                                </div>
                            </div>
                            <div v-if="countdown > 0" class="text-center justify-content-center px-4">
                                <p class="text-xs">Redirecting to the order status page in {{ countdown }} seconds. If
                                    you're not redirected, <a href="#/orderstatus"  @click.prevent="stopCountdown" ><b><u>click here</u></b></a>.
                                </p>
                            </div>
                        </div>
                        <div v-if="message.workFlow == 'FAILED'">
                            <div class="mt-3 align-item-center ms-1">
                                <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                                </svg>
                            </div>

                            <div class="card-body ps-4 py-3">
                                <div>
                                    <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                                    <p class="d-flex justify-content-center text-xxs pt-2 text-capitalize ps-2">{{ message?.errorMessage
                                        }} </p>
                                </div>
                            </div>
                            <div v-if="countdown > 0" class="text-center justify-content-center px-3">
                                <p class="text-xs">Redirecting to the order status page in {{ countdown }} seconds. If
                                    you're not redirected, <a href="#/orderstatus"
                                        @click.prevent="stopCountdown"><b><u>click here</u></b></a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
    name: "SwitchConfirmation",
    components: {
        LeftPanelView,
    },
    props: {
        currentStep: {
            type:Number,
            default:5,
        },
        message: {
            type: Object,
            default: () => ({
            workFlow: '', 
            errorMessage: '', 
        }),
        },
        selectedAmount: {
            type: String,
            default: "",
        },
        orderId: {
            type: String,
            default: "",
        },
        stpData: {
            type: Object,
            default: () => ({})
        },
        swpData: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            otp: "",
            countdown: 10,
            countdownStarted: false,
            countdownInterval: null,
            pageName:"confirm",
            descriptionText:"Congratulations! You've successfully completed the process. If your order wasn't processed, please contact customer support for assistance.",
        };
    },
    mounted() {
    const navbar = document.querySelector('.navbar'); 
    if (navbar) {
        navbar.addEventListener('click', this.handleNavbarClick);
    }
},
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        shouldStartCountdown() {
            return this.message.workFlow && !this.countdownStarted;
        },
        title() {
            if (this.getSwitchFund.type === 'STP') {
                return 'STP Confirm';
            } else if (this.getSwitchFund.type === 'SWP') {
                return 'SWP Confirm';
            }
            return 'Switch Confirm';
        },
        requestType() {
            if (this.getSwitchFund.type === 'STP') {
                return 'STP';
            } else if (this.getSwitchFund.type === 'SWP') {
                return 'SWP';
            }
            return 'Switch';
        },
        displayAmount() {
            if (this.getSwitchFund.type === 'STP' && this.stpData && this.stpData.amount) {
            return this.stpData.amount;
        } else if (this.getSwitchFund.type === 'SWP' && this.swpData && this.swpData.amount) {
            return this.swpData.amount;
        }
            return this.selectedAmount;
        },
    },
    methods: {
        redirect() {
            this.closeModals();
            this.$router.push('/orderstatus');
        },
        startCountdown() {
            if (this.countdownStarted) return;
            this.countdownStarted = true;
            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.countdownInterval = null;
                    this.countdownStarted = false;
                    this.closeModals();
                    this.redirect();
                }
            }, 1000);
        },
        stopCountdown() {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
                this.countdownInterval = null;
            }
            this.countdown = 0;
            this.countdownStarted = false; 
        },
        closeModals() {
        const modals = document.querySelectorAll('.modal.show');
        modals.forEach(modal => {
            const bootstrapModal = bootstrap.Modal.getInstance(modal);
            if (bootstrapModal) {
                bootstrapModal.hide();
            }
        });
    },
    handleNavbarClick() {
        this.stopCountdown();
    },
    },
    watch: {
        shouldStartCountdown(newVal) {
            if (newVal) {
                this.startCountdown();
            }
        },
    },
};
</script>