<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-column flex-md-row">
      <div class="col p-0">
        <div class="mt-6 page-header min-height-160 border-radius-xl dashboard-headerimage">
        </div>
        <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
          <div class="row w-100 px-0">
            <div class="col-12">
              <div class="card-header p-0">
                <h4 class="mb-2 ms-3">Investment Overview<br><span class="text-sm ms-2">As of {{ currentDate }}</span>
                </h4>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <outlined-counter-card :duration="500" prefix="₹" :count="totalInvested" title="Invested Value" />
                  <outlined-counter-card prefix="₹" :count="totalCurrent" title="Current Value" percentage="%" />
                  <outlined-counter-card class="mt-4 mt-lg-0" :duration="500" prefix="₹" :count="totalPNL"
                    title="Total P & L" />
                  <outlined-counter-card :duration="500" class="mt-4 mt-lg-0" :count="totalReturn" title="Returns"
                    suffix="%" :is-percentage="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="totalValue.amt && totalValue.fmt">
          <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5">
            <div class="mt-4 row w-100 mb-3 mt-4">
              <div class="col-lg-6 ms-auto">
                <reports-doughnut-chart :chart-id="chartid" :title="chartTitle" :total-consumption="totalValue.amt"
                  :consumption-unit="totalValue.fmt" :rooms="schemeTypeChart" :button-title="buttonTitles" />
              </div>
              <div class="col-lg-6 ms-auto">
                <reports-doughnut-chart :chart-id="id" :title="chartsTitle" :total-consumption="totalValue.amt"
                  :consumption-unit="totalValue.fmt" :rooms="schemeNameChart" :button-title="buttonTitle" />
              </div>
            </div>
          </div>
        </div>
        <div class="card card-body blur shadow mx-3 mx-md-4 mt-6 mb-5">
          <LandingpageCards />
        </div>
      </div>
    </div>
  </div>
  <div class="modal" ref="modal" id="statusModal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <!-- Header -->
        <div class="modal-header border-0 d-flex justify-content-end">
          <button type="button" class="btn-close text-black btn-close-opacity" data-bs-dismiss="modal"
            aria-label="Close" @click="hideModal">
            <i class="fa-solid fa-xmark text-primary fa-lg"></i>
          </button>
        </div>

        <div class="modal-body my-3">
          <!-- Authorization Sections -->
          <Form role="form">
            <div class="d-flex justify-content-between align-items-center border border-radius-xl py-2 px-3 mb-2"
              :class="{ 'highlighted': activeSection === 'registration' }">
              <div class="text-start">Verify</div>
              <div class="mb-2">
                <svg v-if="registrationStatus === 'ACTIVE'" class="checkmark animation-status me-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle-status " cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <svg v-else-if="registrationStatus === 'FAILED'" class="crossmark animation-status me-2 mt-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" data-bs-toggle="popover"
                  data-bs-placement="left" data-bs-content="FAILED">
                  <circle class="crossmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
                <svg v-else class="exclamationmark exclamationmark-status me-2 mt-1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="IN PROGRESS">
                  <circle class="exclamationmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <line class="exclamationmark__line" x1="26" y1="15" x2="26" y2="35" stroke="orange"
                    stroke-width="3" />
                  <circle class="exclamationmark__dot" cx="26" cy="40" r="2" fill="orange" />
                </svg>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center border py-2 px-3 border-radius-xl mb-2"
              :class="{ 'highlighted': activeSection === 'mandate' }">
              <div class="text-start">Autopay </div>
              <div class="mb-2">
                <svg v-if="mandateApproveStatus === 'ACTIVE'" class="checkmark animation-status me-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" data-bs-toggle="popover"
                  data-bs-placement="left" :data-bs-content="mandateApproveStatus">
                  <circle class="checkmark__circle-status " cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>

                <svg v-else-if="mandateApproveStatus === 'FAILED'" class="crossmark animation-status me-2 mt-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" data-bs-toggle="popover"
                  data-bs-placement="left" data-bs-content="FAILED">
                  <circle class="crossmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
                <svg v-else class="exclamationmark exclamationmark-status me-2 mt-1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="IN PROGRESS">
                  <circle class="exclamationmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <line class="exclamationmark__line" x1="26" y1="15" x2="26" y2="35" stroke="orange"
                    stroke-width="3" />
                  <circle class="exclamationmark__dot" cx="26" cy="40" r="2" fill="orange" />
                </svg>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center border py-2 px-3 border-radius-xl mb-2"
              :class="{ 'highlighted': activeSection === 'approval' }">
              <div class="text-start">Nominee & ELOG </div>
              <div class="mb-2">
                <svg v-if="approvedStatus === 'ACTIVE'" class="checkmark animation-status me-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle-status " cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <svg v-else-if="approvedStatus === 'FAILED'" class="crossmark animation-status me-2 mt-1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="crossmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
                <svg v-else class="exclamationmark exclamationmark-status me-2 mt-1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="IN PROGRESS">
                  <circle class="exclamationmark__circle-status" cx="26" cy="26" r="25" fill="none" />
                  <line class="exclamationmark__line" x1="26" y1="15" x2="26" y2="35" stroke="orange"
                    stroke-width="3" />
                  <circle class="exclamationmark__dot" cx="26" cy="40" r="2" fill="orange" />
                </svg>
              </div>
            </div>
            <!-- Privacy Info -->
            <div class="alert bg-light px-0 py-0 mb-2" role="alert">
              <div class="ps-3 pe-3 py-2">
                <p class="text-xxs mb-0 d-flex align-items-center">
                  <i class="fa fa-info-circle me-2 mb-2" aria-hidden="true"></i>
                  <b>We will be placing one or more sample lumpsum order for verification. You may ignore those
                    emails.</b>
                </p>
                <p class="text-xxs mb-0 d-flex align-items-center">
                  <i class="fa fa-info-circle me-2 mb-2" aria-hidden="true"></i>
                  <b>Please be aware that it may take 4 to 5 working days to place an order using a mandate after it has
                    been approved.</b>
                </p>
              </div>
            </div>
            <div v-if="registrationStatus === 'FAILED'"
              class="text-center alert bg-light-yellow px-4 py-1 mt-2 text-dark font-weight-bold text-justify font-weight-bold"
              role="alert">
              <small class="text-xs">A UCC verification email for Customer ID <b>{{ clientCode }}</b> has been sent to
                your
                registered
                email address. Please check your inbox and complete the verification. Once verified, click the 'Refresh'
                button to update the status. If you did not receive the email, <a v-if="actionButton === 'RESEND'"
                  :class="{ 'text-muted disabled-link': isLinkDisabled, 'text-info cursor-pointer': !isLinkDisabled }"
                  @click="!isLinkDisabled && regenerateMandate()">click here</a> to resend it.</small>
                  <p v-if="isLinkDisabled && actionButton === 'RESEND'" class="text-dark font-weight-bold text-center text-xs mt-2">
              Please wait {{ countdownDisplay }} before you can resend again.
            </p>
            </div>
            <div v-if="mandateApproveStatus === 'IN_PROGRESS' && actionButton === 'AUTHORIZE'"
              class="text-center alert bg-light-yellow px-4 py-1 mt-2 text-dark font-weight-bold text-justify"
              role="alert">
              <small class="text-xs">OneS uses mandate as a payment method. You need to authorize your mandate to
                proceed further. By clicking "Authorize," you will get redirected to the mandate Authorization page. If
                you have already Authorized your mandate via email, please wait for 24 hours until it gets approved. In
                the meantime, you may proceed with further verification.</small>
            </div>
            <div v-if="mandateApproveStatus === 'FAILED' && actionButton == 'RETRY'"
              class="text-center alert bg-light-yellow px-4 py-1 mt-2 text-dark font-weight-bold text-justify"
              role="alert">
              <small class="text-xs">The mandate got rejected by your bank. Please try again.</small>
            </div>

            <div v-if="approvedStatus === 'FAILED' && registrationStatus === 'ACTIVE' && actionButton === 'RESEND'"
              class="text-center alert bg-light-yellow font-weight-bold px-4 py-1 mt-2 text-dark text-justify"
              role="alert">
              <small class="text-xs">
                ELOG and nominee authentication emails have been sent to your registered email address. If you haven't
                received the authentication email, please <a v-if="actionButton === 'RESEND'"
                  :class="{ 'text-muted disabled-link': isLinkDisabled, 'text-info cursor-pointer': !isLinkDisabled }"
                  @click="!isLinkDisabled && regenerateMandate()">click here </a> to try again. Kindly note that by
                trying again, you may have to go through the entire verification process one more time.
              </small>
              <p v-if="isLinkDisabled && actionButton === 'RESEND'" class="text-dark font-weight-bold text-center text-xs mt-2">
              Please wait {{ countdownDisplay }} before you can resend again.
            </p>
            </div>
            <div class="text-center"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small></div>
            <!-- Click Here Button -->
            <div class="mt-4 d-flex justify-content-center">
              <soft-button v-if="!isActive" class="my-4 text-primary bg-white border border-primary" type="button"
                @click="refreshVerification">
                Refresh
              </soft-button>
              <soft-button v-if="actionButton == 'AUTHORIZE'" class="my-4 ms-2" type="button" color="primary"
                data-bs-dismiss="modal" @click="showRedirectModal">
                Authorize
              </soft-button>
              <soft-button v-else-if="actionButton == 'RETRY'" class="my-4 ms-2" type="button" color="primary"
                @click="retryMandates">
                Try Again
              </soft-button>
              <div v-if="isActive" class="align-items-center">
                <svg class="checkmark check-status" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle-status " cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <p class="text-success mt-2 text-center">You're good to go!</p>
                <p class="text-sm text-muted">This window will close automatically in {{ countdown }} seconds.</p>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" ref="redirectModal" id="redirectModal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header border-0 d-flex justify-content-end">
          <button type="button" class="btn-close text-black btn-close-opacity" data-bs-dismiss="modal"
            aria-label="Close" @click="closeRedirectModal">
            <i class="fa-solid fa-xmark text-primary fa-lg"></i>
          </button>
        </div>
        <div class="modal-body text-center">
          <h5 class="modal-title mb-4">Redirecting away from OneS </h5>
          <p class="text-sm text-justify px-4">
            You are being redirected from <b>OneS</b> to the following URL: https://app.digio.in... After completing the
            authentication process, you will be automatically returned back to Ones
          </p>
          <p class="text-danger text-xs">Redirecting in {{ countdown }} seconds...</p>

          <div class="mt-4 d-flex justify-content-center">
            <soft-button class="btn bg-primary mt-3 w-50" type="button" @click="onSubmit">
              Redirect
            </soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" ref="modallink" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal-default"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal- modal-dialog-centered" role="document">
      <div class="modal-content">
        <div v-if="errorOccurred" class="modal-header border-0 d-flex justify-content-end">
          <button type="button" class="btn-close text-black btn-close-opacity" data-bs-dismiss="modal"
            aria-label="Close">
            <i class="fa-solid fa-xmark text-primary fa-lg"></i>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="card card-plain">
            <div v-if="success">
              <div class="mt-0 align-item-center ms-1">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
            </div>
            <div v-if="success" class="card-body ps-4 py-3">
              <div>
                <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">success </h3>
                <h6 class="text-center me-4 ps-4 mb-3 text-capitalize">{{ statusMessage }}</h6>
              </div>
            </div>
            <div v-if="errorOccurred" class="align-item-center ms-1">
              <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
              </svg>
            </div>
            <div v-if="errorOccurred" class="card-body ps-4 py-3">
              <div>
                <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                <h6 class="text-center me-4 ps-4 mb-3 text-capitalize">{{ statusMessage }}</h6>
              </div>
            </div>
            <div class="mb-4">
              <label class="ones-label-size ms-xl-0 me-1 ms-md-0">{{ bankName }}</label>
              <span class="ms-xl-4 ps-xl-2 ms-md-4 ms-2 text-sm">{{ maskedAccountNumber }}</span>
            </div>
            <div v-if="errorOccurred" class="d-flex justify-content-center mt-3 mx-4 mb-3">
              <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="button" @click="retry()"> Try
                again
              </soft-button>
            </div>
            <div v-if="success" class="text-center justify-content-center px-4 mb-3 mt-3">
              <soft-button type="button" class="btn bg-primary text-white w-100" data-bs-dismiss="modal">Done</soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportsDoughnutChart from "@/components/ReportsDoughnutChart.vue";
import OutlinedCounterCard from "../common/components/OutlinedCounterCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import LandingpageCards from "../common/components/LandingpageCards.vue";
import { mapState } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import SoftButton from "@/views/common/components/SoftButton.vue";
import moment from "moment-timezone";
export default {
  name: "Dashboard",
  components: {
    ReportsDoughnutChart,
    OutlinedCounterCard,
    LandingpageCards,
    SoftButton,
  },
  data() {
    return {
      showMenu: false,
      currentDate: this.getCurrentDate(),
      buttonTitle: "Discover our top-performing mutual funds, organized by sector allocation, to enhance your portfolio's diversification and growth potential.",
      buttonTitles: "Asset allocation is an investment strategy that involves distributing a portfolio's investments among different asset classes in order to achieve a specific risk and return objective.",
      chartid: "chart-consumption",
      id: "chart-mutualFund",
      chartTitle: "Asset Allocation",
      chartsTitle: "Top Mutual Funds",
      accountNumber: "",
      ifscCode: "",
      linkUrl: "",
      iframeSrc: '',
      toDate: "",
      fromDate: this.formatDate(new Date()),
      mandateId: "",
      statusMessage: "",
      success: false,
      errorOccurred: false,
      bankName: "Not Available",
      mandateStatus: "",
      cognitoStatus: "",
      schemes: [],
      isRetryMandateEligible: true,
      error: "",
      isSuccess: true,
      buttonText: 'Authorize',
      stpType: 'STP',
      swpType: 'SWP',
      portfolioLoaded: false,
      isLinkDisabled: false,
      registrationStatus: "",
      mandateApproveStatus: "",
      approvedStatus: "",
      actionButton: "",
      isActive: false,
      modalApperance: false,
      countdown: 10,
      intervalId: null,
      resendCountdown: 0,
      resendIntervalId: null,
      clientCode: "",
      createdTime: "",
    };

  },
  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.linkUrl = sessionStorage.getItem("linkUrl");
    const currentUrl = new URL(window.location.href);
    console.log("current URL:", currentUrl);
    const hash = window.location.hash;
    const hashParams = new URLSearchParams(hash.slice(hash.indexOf('?')));
    const status = hashParams.get('status');
    const message = hashParams.get('message');
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.cognitoStatus = userPayload['custom:user_signup_status'];
    if (status) {
      this.accountNumber = sessionStorage.getItem("Account Number");
      this.bankName = sessionStorage.getItem("bank Name");
      this.processStatus(status, message);
      this.showModal();
    } else if (this.cognitoStatus !== "VERIFIED" && this.isActive === false) {
      const modalApperance = sessionStorage.getItem("modalApperance");
      if (!modalApperance) {
        this.refreshVerification();
        this.modal();
        sessionStorage.setItem("modalApperance", true);
      }
    }
    if (this.cognitoStatus === "VERIFIED") {
      const portfolioLoaded = sessionStorage.getItem("portfolioLoaded");
      if (!portfolioLoaded) {
        this.getPortfolioApi();
        sessionStorage.setItem("portfolioLoaded", true);
      } else {
        const storedSchemes = sessionStorage.getItem("schemes");
        this.schemes = JSON.parse(storedSchemes);
      }
    }
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(function (popoverTriggerEl) {
      new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'hover', // Show popover on hover
      });
    });
    if (this.showDashboardModal) {
      this.refreshVerification();
      this.modal();
      this.$store.dispatch('productStore/hideDashboardModal');
    }
    this.initResendCountdown();
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  beforeUnmount() {
    if (this.resendIntervalId) {
      clearInterval(this.resendIntervalId);
      this.resendIntervalId = null;
    }
  },
  watch: {
    // Watch for changes in the Vuex state
    showDashboardModal(newVal) {
      if (newVal) {
        this.refreshVerification();
        this.modal();
        this.$store.dispatch("productStore/hideDashboardModal");
      }
    },
    isActive(newVal) {
      if (newVal) {
        this.closeCountdown();
      }
    }
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "color",
      "showSidenav",
    ]),
    maskedAccountNumber() {
      if (this.accountNumber) {
        return this.accountNumber.slice(0, -4).replace(/./g, '*') + this.accountNumber.slice(-4);
      } else {
        return "Not Available";
      }
    },
    maskedIfscCode() {
      if (this.ifscCode) {
        return this.ifscCode.slice(0, 4) + this.ifscCode.slice(4, -3).replace(/./g, '*') + this.ifscCode.slice(-3);
      } else {
        return "unavailable";
      }
    },
    schemeTypeChart() {
      let chartData = [{ name: "Equity", percentage: 0, amount: 0 },
      { name: "Debt", percentage: 0, amount: 0 },
      { name: "ELSS", percentage: 0, amount: 0 },
      { name: "Hybrid", percentage: 0, amount: 0 },
      { name: "Other", percentage: 0, amount: 0 }]
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        var schemeMap = Object.groupBy(this.schemes, ({ schemeType }) => schemeType);
        if (schemeMap) {
          for (const [key, value] of Object.entries(schemeMap)) {
            if (value) {
              var data = chartData.find((c) => key && c.name.toLowerCase() == key.toLowerCase());
              if (data) {
                data.amount = data.amount + value.map((v) => v.amount).reduce((sum, a) => sum + a, 0);
                data.percentage = Math.round((data.amount / total) * 10000) * .01;
              } else {
                var otherData = chartData.find((c) => c.name == 'Other');
                otherData.amount = otherData.amount + value.map((v) => v.amount).reduce((sum, a) => sum + a, 0);
                otherData.percentage = Math.round((otherData.amount / total) * 10000) * .01;
              }
            }
          }
        }
      }
      return chartData;
    },
    schemeNameChart() {
      var schemeArray = [];
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        var schemeGroup = Object.groupBy(this.schemes, ({ fundId }) => fundId);
        var sumMap = {};
        for (const [key, value] of Object.entries(schemeGroup)) {
          if (value) {
            var sum = [...value].reduce((sum, a) => sum + a.amount, 0);
            sumMap[key] = sum;

          }
        }
        var topScheme = Object.keys(schemeGroup).sort((a, b) => sumMap[b] - sumMap[a])?.slice(0, 5);
        for (let item of topScheme) {
          var data = {};
          data.name = schemeGroup[item]?.[0].schemeName;
          data.amount = sumMap[item];
          data.percentage = (Math.round((data.amount / total) * 10000) * .01).toFixed(2);
          schemeArray.push(data);
        }
      }
      return schemeArray;
    },
    totalValue() {
      var totalFormatted = { amt: 0, fmt: "RS" };
      if (this.schemes && this.schemes.length > 0) {
        var total = this.schemes.reduce((sum, a) => sum + a.amount, 0);
        if (total >= 10000000) {
          totalFormatted.amt = (total / 10000000).toFixed(2);
          totalFormatted.fmt = "Crores";
        } else if (total >= 1000) {
          totalFormatted.amt = (total / 100000).toFixed(2);
          totalFormatted.fmt = "Lakhs";
        } else {
          totalFormatted.amt = total.toFixed(2);
          totalFormatted.fmt = "RS";
        }
      }
      return totalFormatted;
    },
    totalInvested() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.amount, 0)).toFixed(2);

      }
      return parseFloat(value);
    },
    totalCurrent() {
      var value = 0
      if (this.schemes && this.schemes.length > 0) {
        value = (this.schemes.reduce((sum, a) => sum + a.currentValue, 0)).toFixed(2);

      }
      return parseFloat(value);
    },
    totalPNL() {
      return parseFloat(this.totalCurrent - this.totalInvested);
    },
    totalReturn() {
      if (this.totalInvested == 0) {
        return 0;
      } else {
        return parseFloat((Math.round(((this.totalCurrent - this.totalInvested) / this.totalInvested) * 10000) * .01).toFixed(2));
      }
    },
    activeSection() {
      if (this.registrationStatus === 'FAILED' || this.registrationStatus === 'IN_PROGRESS') {
        return 'registration';
      }
      if (this.registrationStatus === 'ACTIVE' && this.actionButton === 'AUTHORIZE' &&
        (this.mandateApproveStatus === 'IN_PROGRESS' || this.mandateApproveStatus === 'FAILED')) {
        return 'mandate';
      }
      if (this.registrationStatus === 'ACTIVE' &&
        (this.approvedStatus === 'FAILED' || this.approvedStatus === 'IN_PROGRESS')) {
        return 'approval';
      }
      return null;
    },
    showDashboardModal() {
      return this.$store.getters['productStore/showDashboardModal'];
    },
    countdownDisplay() {
      const minutes = Math.floor(this.resendCountdown / 60);
      const seconds = this.resendCountdown % 60;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds; // Pad single digit seconds
      return `${minutes}m ${formattedSeconds}s`;
    }
  },
  methods: {
    getPortfolioApi() {
      var self = this;
      this.$store
        .dispatch("productStore/getPortfolio", { root: true })
        .then(response => {
          self.schemes = response.server.data;
          const schemeGroup = {};
          self.schemes.forEach(scheme => {
            if (!schemeGroup[scheme.fundId]) {
              schemeGroup[scheme.fundId] = [];
            }
            schemeGroup[scheme.fundId].push(scheme);
          });
          Object.entries(schemeGroup).forEach(([fundId, schemes]) => {
            const data = {
              fundId: fundId,
              stpType: self.stpType,
              swpType: self.swpType,
            };

            self.$store
              .dispatch("schemeStore/getSchemeDetails", data, { root: true })
              .then(response => {
                schemes.forEach(item => {
                  const stpDetailsArray = response.server.data.stpDetails || [];
                  item.stpFrequencies = {};
                  stpDetailsArray.forEach(detail => {
                    item.stpFrequencies[detail.frequency] = {
                      astpfrequency: detail.frequency,
                      astpOutMinInstallmentAmount: detail.outMinInstAmt,
                      astpOutMaxInstallmentAmount: detail.outMaxInstAmt,
                      astpMinInstallment: detail.minInstNumbers,
                      astpMaxInstallment: detail.maxInstNumbers,
                      astpRegOut: detail.regOut,
                      astpStatus: detail.status,
                      astpOutmultiplierAmount: detail.outMultiplierAmt,
                    };
                  });
                  item.schemeName = response.server.data.schemeName;
                  item.schemeType = response.server.data.schemeType;
                  item.minimumRedemptionQty = response.server.data.minimumRedemptionQty;
                  item.maximumRedemptionQty = response.server.data.maximumRedemptionQty;
                  item.switchFlag = response.server.data.switchFlag;
                  item.isin = response.server.data.schemeDetails.isin;
                  item.redemptionAllowed = response.server.data.redemptionAllowed;
                  item.amcCode = response.server.data.amcDetails.amcCode;
                  item.maximumRedemptionAmount = response.server.data.redemptionAmountMaximum;
                  item.minimumRedemptionAmount = response.server.data.redemptionAmountMinimum;
                  item.lockinPeriodFlag = response.server.data.lockInPeriodFlag || 'N';
                  item.lockinPeriod = response.server.data.lockinPeriod;
                  item.aswpStatus = response.server.data.swpDetails.status;
                  item.aswpMinInstallmentAmount = response.server.data.swpDetails.minInstAmt;
                  item.aswpMaxInstallmentAmount = response.server.data.swpDetails.maxInstAmt;
                  item.aswpMinInstallment = response.server.data.swpDetails.minInstNumbers;
                  item.aswpMaxInstallment = response.server.data.swpDetails.maxInstNumbers;
                  item.aswpMultiplierAmount = response.server.data.swpDetails.multiplierAmt;
                  item.amcCode = response.server.data.amcDetails.amcCode;
                  item.redemptionAmountMultiple = response.server.data.redemptionAmountMultiple;
                });
                const uniqueIsins = [...new Set(schemes.map(item => item.isin))];
                uniqueIsins.forEach(isin => {
                  self.$store
                    .dispatch("schemeStore/getNav", isin, { root: true })
                    .then(navResponse => {
                      const navValue = navResponse.server.data.navValue;
                      schemes.forEach(item => {
                        if (item.isin === isin) {
                          item.nav = navValue;
                          item.currentValue = Math.round(navValue * item.quantity * 100) * 0.01;
                          item.returns = Math.round(((item.currentValue - item.amount) / item.amount) * 10000) * 0.01;
                        }
                      });
                      sessionStorage.setItem("schemes", JSON.stringify(self.schemes));
                    })
                    .catch(error => {
                      schemes.forEach(item => {
                        if (item.isin === isin) {
                          item.nav = 0;
                          item.currentValue = 0;
                          item.returns = 0;
                        }
                      });
                      console.log(error);
                    });
                });
              })
              .catch(error => {
                schemes.forEach(item => {
                  item.schemeName = 'Not Available';
                  item.schemeType = 'Not Available';
                  item.isin = 'Not Available';
                  console.log(error);
                });
              });
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCurrentDate() {
      const today = new Date();
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return today.toLocaleDateString(undefined, options);
    },
    modal() {
      const modalElement = this.$refs.modal;
      const modalOptions = {
        backdrop: 'static',
        keyboard: false,
        focus: true
      };
      const myModal = new bootstrap.Modal(modalElement, modalOptions);
      myModal.show();
      document.body.classList.add('modal-open');
    },
    hideModal() {
      const modalElement = this.$refs.modal;
      if (modalElement) {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
          document.body.classList.remove('modal-open');
        } else {
          console.warn('Modal instance not found');
        }
      } else {
        console.warn('Modal element not found');
      }
    },
    processStatus(status, message) {
      switch (status.toLowerCase()) {
        case 'success':
          this.statusMessage = message;
          this.success = true;
          this.errorOccurred = false;
          this.mandateStatus = "MANDATE_REGISTRATION_COMPLETED";
          this.mandateStatusUpdate();
          break;
        case 'cancel':
          this.statusMessage = message;
          this.success = false;
          this.errorOccurred = true;
          this.mandateStatus = "MANDATE_REGISTRATION_CANCELLED";
          this.mandateStatusUpdate();
          break;
        case 'failure':
          this.statusMessage = message;
          this.success = false;
          this.errorOccurred = true;
          this.mandateStatus = "MANDATE_REGISTRATION_FAILED";
          this.mandateStatusUpdate();
          break;
      }
    },
    showModal() {
      const modalElement = this.$refs.modallink;
      const modalOptions = {
        backdrop: 'static',
        keyboard: false,
        focus: true
      };
      const myModal = new bootstrap.Modal(modalElement, modalOptions);
      myModal.show();
    },
    onSubmit() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      let data = {
        "mandateId": this.mandateId,
      };
      var self = this;
      this.$store
        .dispatch("productStore/getMandatesId", data, { root: true })
        .then(function (response) {
          self.isSuccess = true;
          self.linkUrl = response.server.data;;
          const redirectUri = encodeURIComponent(window.location.origin + "/#/dashboard");
          self.linkUrl = self.linkUrl.replace(/(redirect_url=).*?(&)/, '$1' + redirectUri + '$2');
          window.location.href = self.linkUrl;
          sessionStorage.setItem("linkUrl", self.linkUrl);
        })
        .catch(function (error) {
          self.error = error.message || 'Mandate is under processing. Please retry after 5 minutes.';
          self.isSuccess = false;
          self.modal();
        });
      this.closeRedirectModal();
    },
    closeModal() {
      const modalInstance = bootstrap.Modal.getInstance(this.$refs.modallink);
      if (modalInstance) {
        modalInstance.hide();
        document.body.classList.remove('disable-elements');
      }
    },
    getAllMandates() {
      var self = this;
      this.$store
        .dispatch("productStore/getMandates", { root: true })
        .then(function (response) {
          var mandateData = response.server.data;
          if (mandateData && mandateData.length > 0 && mandateData[0].mandateCfmId) {
            self.accountNumber = response.server.data[0].accountNumber;
            self.bankName = response.server.data[0].bankName;
            self.mandateId = response.server.data[0].mandateCfmId;
            sessionStorage.setItem("mandateId", self.mandateId);
            sessionStorage.setItem("Account Number", self.accountNumber);
            sessionStorage.setItem("bank Name", self.bankName);
          } else {
            self.isRetryMandateEligible = true;
            self.retryMandates();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.isRetryMandateEligible = true;
          self.retryMandates();
        });
    },
    retryMandates() {
      if (this.isRetryMandateEligible) {
        var self = this;
        this.$store
          .dispatch("productStore/retryMandate", { root: true })
          .then(function () {
            self.refreshVerification();
            self.isRetryMandateEligible = false;
          })
          .catch(function (error) {
            console.log(error);
            self.isRetryMandateEligible = true;
          });
      }
    },
    mandateStatusUpdate() {
      let data = {
        "mandateId": sessionStorage.getItem("mandateId"),
        "status": this.mandateStatus,
      };
      this.$store
        .dispatch("productStore/updateMandateStatus", data, { root: true })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    },
    retry() {
      window.location.href = this.linkUrl;
    },
    regenerateMandate() {
      if (this.isRetryMandateEligible) {
        this.isLinkDisabled = true;
        sessionStorage.setItem('isLinkDisabled', this.isLinkDisabled);
        let data = {
          retryEmail: "true",
        }
        var self = this;
        this.$store
          .dispatch("productStore/retryMandate", data, { root: true })
          .then(function () {
            self.isRetryMandateEligible = true;
            self.refreshVerification();
            self.initResendCountdown();
            setTimeout(() => {
              self.isLinkDisabled = false;
              sessionStorage.setItem('isLinkDisabled', self.isLinkDisabled);
              clearInterval(self.resendIntervalId);
            }, 300000);
          })
          .catch(function (error) {
            console.log(error);
            self.isRetryMandateEligible = true;
            self.isLinkDisabled = false;
            clearInterval(self.countdownIntervalId);
          });
      }
    },
    refreshVerification() {
      var self = this;
      self.isSuccess = true;
      this.$store
        .dispatch("userStore/verifiicationRefresh", { root: true })
        .then(function (response) {
          console.log(response);
          self.isActive = response.server.data.active;
          sessionStorage.setItem("isActive", self.isActive);
          self.$store.dispatch("userStore/updateIsActiveFromStorage");
          self.registrationStatus = response.server.data.regStatus;
          sessionStorage.setItem("regStatus", self.registrationStatus);
          self.approvedStatus = response.server.data.approvalStatus;
          sessionStorage.setItem("approvedStatus", self.approvedStatus);
          self.mandateApproveStatus = response.server.data.mandateStatus;
          self.actionButton = response.server.data.actionButton;
          self.clientCode = response.server.data.clientCode;
          self.createdTime= response.server.data.modifiedDate;
          sessionStorage.setItem("createdTime", self.createdTime);
          self.initResendCountdown();
          if (self.actionButton === "AUTHORIZE") {
            self.getAllMandates();
          }
          if (self.isActive) {
            self.updateUserSignupStatus();
          }


        })
        .catch(function (error) {
          console.log(error);
          self.error = error.message
        });
    },
    showRedirectModal() {
      this.countdown = 10;
      const modalElement = this.$refs.redirectModal;
      const modalOptions = {
        backdrop: 'static',
        keyboard: false,
        focus: true,
      };
      const myModal = new bootstrap.Modal(modalElement, modalOptions);
      myModal.show();
      this.startCountdown();
    },
    startCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
          this.onSubmit();
        }
      }, 1000);
    },
    closeRedirectModal() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      const modalInstance = bootstrap.Modal.getInstance(this.$refs.redirectModal);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
    startResendCountdown() {
      const now = moment();
      const createdTime = moment.utc(this.createdTime).local();
      const differenceInSeconds = now.diff(createdTime, "seconds");
      const remainingTime = 300 - differenceInSeconds;
      this.resendCountdown = remainingTime; 
      sessionStorage.setItem('resendCountdown', this.resendCountdown);
      if (this.resendCountdown > 0 && !this.isLinkDisabled) {
        this.isLinkDisabled = true;
      }
      if (this.resendIntervalId) {
        clearInterval(this.resendIntervalId);
      }
      this.resendIntervalId = setInterval(() => {
        if (this.resendCountdown > 0) {
          this.resendCountdown--;
          sessionStorage.setItem('resendCountdown', this.resendCountdown);
        } else {
          this.isLinkDisabled = false;
          clearInterval(this.resendIntervalId);
          this.resendIntervalId = null;
          sessionStorage.removeItem('resendCountdown');
        }
      }, 1000);
    },
    closeCountdown() {
      this.countdown = 10; // Reset countdown to 10 seconds
      if (this.countdownIntervalId) {
        clearInterval(this.countdownIntervalId);
      }
      this.countdownIntervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.hideModal();
          clearInterval(this.countdownIntervalId);
        }
      }, 1000);
    },
    initResendCountdown() {
      const storedCreatedTime = sessionStorage.getItem("createdTime");
      const storedResendCountdown = sessionStorage.getItem("resendCountdown");
      const storedIsLinkDisabled = sessionStorage.getItem("isLinkDisabled");
      if (storedCreatedTime) {
        this.isLinkDisabled = storedIsLinkDisabled === 'true';
        this.createdTime = storedCreatedTime;
        this.resendCountdown = storedResendCountdown
        this.startResendCountdown();
      }
    },
    updateUserSignupStatus() {
      const userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
      userPayload['custom:user_signup_status'] = 'VERIFIED';
      sessionStorage.setItem('userPayload', JSON.stringify(userPayload));
    }
  },
};
</script>