<template>
    <div class="modal fade" id="skipModal" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm px-md-0" role="document">
            <div class="modal-content">
                <div class="modal-header border-0 d-flex justify-content-start pb-0 px-4">
                    <button v-if="workFlow != 'VALIDATION'" type="button" class="btn-close text-black btn-close-opacity"
                        data-bs-dismiss="modal" aria-label="Close" @click="resetModal">
                        <i class="fa-solid fa-xmark text-primary fa-lg"></i>
                    </button>
                    <div v-else class="py-2 my-0 px-4 ms-1">
                        <small class="d-block text-xxs"></small>
                    </div>
                </div>
                <div class="modal-body p-0 h-100">
                    <div class="card card-plain modal-card">
                        <div v-if="workFlow == 'VALIDATION'" class="card-body mt-4">
                            <TwoFactorAuth @verification-success="handleVerificationSuccess" @previous="handleRedirect"
                                @resent-otp="handleOtpSuccess">
                            </TwoFactorAuth>
                        </div>
                        <div v-if="workFlow == 'SUCCESS'">
                            <div class="mt-5 align-item-center ms-1">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div class="card-body ps-4 py-3">
                                <div>
                                    <h4 class="text-center me-4 ps-2 mb-3">{{ actionType }}</h4>
                                    <div class="alert bg-light px-0 py-0 my-2" role="alert">
                                        <div v-if="type == 'STP'" class="ps-3 pe-3 text-justify my-2">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>From: {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>To: {{ scheme?.toScheme.toLowerCase() }}</p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount: <b>₹{{
                                                    formattedAmount(scheme?.amount) }}</b></p>
                                        </div>
                                        <div v-else class="ps-3 pe-3 text-justify my-3">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-1"
                                                    aria-hidden="true"></i>
                                                {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount:<b>
                                                    ₹{{ formattedAmount(scheme?.amount) }}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <p class="text-center text-xxs justify-content-center mb-2 text-capitalize">
                                        {{ completionMessage }}</p>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small class="text-center text-xs">Ref: <b>OS-{{ orderId }}</b></small>
                                </div>
                            </div>
                        </div>
                        <div v-if="workFlow == 'ERROR'">
                            <div class="mt-5 align-item-center ms-2">
                                <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                                </svg>
                            </div>
                            <div class="card-body ps-4 py-4">
                                <div class="error-message">
                                    <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                                    <div class="alert bg-light px-0 py-0 my-2" role="alert">
                                        <div v-if="type == 'STP'" class="ps-3 pe-3 text-justify my-2">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>From: {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>To: {{ scheme?.toScheme.toLowerCase() }}</p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount: <b>₹{{
                                                    formattedAmount(scheme?.amount) }}</b></p>
                                        </div>
                                        <div v-else class="ps-3 pe-3 text-justify my-3">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-1"
                                                    aria-hidden="true"></i>
                                                {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount:<b>
                                                    ₹{{ formattedAmount(scheme?.amount) }}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <p class="text-center text-xxs pt-2 text-capitalize ps-2 text-danger">{{
                                        errorMessage }} </p>
                                </div>
                            </div>
                        </div>
                        <div v-if="workFlow == 'SKIP'">

                            <div class="card-body">
                                <div v-if="action == 'PAUSE'">
                                    <div class="mb-2 text-center">
                                        <i class="fas fa-exclamation-triangle fa-5x text-primary"></i>
                                    </div>
                                    <div class="mb-2  text-center">
                                        <h6>Are you sure you want to skip the next installment?</h6>
                                    </div>
                                    <div class="alert bg-light px-0 py-0 my-3 mt-4" role="alert">
                                        <div class="ps-3 pe-3 text-justify my-3">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-1"
                                                    aria-hidden="true"></i>
                                                {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount:<b>
                                                    ₹{{ formattedAmount(scheme?.amount) }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="action == 'CANCEL' && type == 'SIP'">
                                    <h6>Choose the reason to cancel the {{ type }}?</h6>
                                    <div class="scrollable-container">
                                        <div v-for="reason in cancelReasons" :key="reason" class="mb-2">
                                            <soft-radio v-model="selectedReasonValue" :value="reason.value"
                                                checked="true" rules="required:true" name="cancelReason">
                                                <div class="w-100">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div
                                                                class="d-flex justify-content-between align-items-center">
                                                                <p class="mb-0 text-xs">{{ reason.label }}</p>
                                                                <div class="d-flex align-items-end flex-grow-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </soft-radio>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="action == 'CANCEL' && type != 'SIP'">
                                    <div class="mb-2 text-center">
                                        <i class="fas fa-exclamation-triangle fa-5x text-primary"></i>
                                    </div>
                                    <div class="mb-3 text-center">
                                        <h6>Are you sure you want to cancel the {{ type }}?</h6>
                                    </div>
                                    <div class="alert bg-light px-0 py-0 my-3" role="alert">
                                        <div v-if="type == 'STP'" class="ps-3 pe-3 text-justify my-2">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>From: {{ scheme?.fromScheme }}</p>
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>To: {{ scheme?.toScheme }}</p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount: <b>₹{{
                                                    formattedAmount(scheme?.amount) }}</b></p>
                                        </div>
                                        <div v-if="type == 'SWP'" class="ps-3 pe-3 text-justify my-3">
                                            <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-1"
                                                    aria-hidden="true"></i>
                                                {{ scheme?.fromScheme.toLowerCase() }}
                                            </p>
                                            <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2"
                                                    aria-hidden="true"></i>Amount:
                                                <b>₹{{ formattedAmount(scheme?.amount) }}</b>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-center error-height mb-2"><small class=" text-danger text-xxs"
                                        v-if="errorOccurred">{{ error }}</small>
                                </div>
                                <div class="d-flex justify-content-center mt-4">
                                    <soft-button ref="buttonRef" class="mt-0 ms-1 w-75" color="primary"
                                        @click="confirmSkip">
                                        {{ buttonTitle }}
                                    </soft-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import TwoFactorAuth from "@/components/TwoFactorAuthentication.vue";
import SoftRadio from "@/components/SoftRadio.vue";
export default {
    name: "SkipCancel",
    components: {
        SoftButton,
        TwoFactorAuth,
        SoftRadio,
    },
    props: {
        action: String,
        scheme: {
            type: Object,
            default: () => ({})
        },
        type: String,
    },
    data() {
        return {
            installments: "",
            validation: false,
            success: false,
            errorOccurred: false,
            completionMessage: "",
            workFlow: "SKIP",
            errorMessage: "",
            orderId: "",
            frequencyType: "MONTHLY",
            cancelReasons: [
                { label: "Goal Achieved", value: "GA" },
                { label: "I have decided to invest elsewhere", value: "IDIE" },
                { label: "Not comfortable with market volatility", value: "NCMV" },
                { label: "Scheme not performing", value: "SNP" },
                { label: "Others", value: "OTH" }
            ],
            selectedReason: "",
            selectedReasonValue: "",
        };
    },
    watch: {
        action() {
            this.resetModal();
        },
    },
    computed: {
        formattedDate() {
            if (this.scheme?.date) {
                const date = new Date(this.scheme.date);
                const day = String(date.getDate()).padStart(2, '0');
                const month = date.toLocaleString('en-GB', { month: 'short' });
                const year = String(date.getFullYear()).slice(-2);
                return `${day} ${month} '${year}`;
            }
            return "";
        },
        nextDueDate() {
            if (this.scheme?.date) {
                const date = new Date(this.scheme.date);
                date.setMonth(date.getMonth() + 1);
                const day = String(date.getDate()).padStart(2, '0');
                const month = date.toLocaleString('en-GB', { month: 'short' });
                const year = String(date.getFullYear()).slice(-2);
                return `${day} ${month} '${year}`;
            }
            return "";
        },
        actionType() {
            if (this.action === 'PAUSE') {
                return `${this.type} Skipped`;
            } else if (this.action === 'CANCEL') {
                return `${this.type} Cancelled`;
            }
            return '';
        },
        buttonTitle() {
            return this.action === 'PAUSE' ? 'Skip' : 'Proceed';
        },
        formattedAmount() {
            return (amount) => {
                if (!amount) return "0.00";
                const formatted = parseFloat(amount);
                return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formatted);
            };
        },
    },
    methods: {
        confirmSkip() {
            this.submitData();
        },
        submitData() {
            if (this.action === 'CANCEL' && this.type === 'SIP') {
                if (!this.selectedReasonValue) {
                    this.errorOccurred = true;
                    this.error = "Please select a reason for cancellation.";
                    return;
                }
            }
            let data = {
                "planId": this.scheme?.id,
                "type": this.type,
                "transaction": this.action,
            };
            if (this.type === 'SIP' && this.action === 'CANCEL') {
                data.reason = this.selectedReasonValue;
            }
            var self = this;
            this.$store
                .dispatch("manageStore/manageOrders", data, { root: true })
                .then(function (response) {
                    self.orderId = response.server.data.orderId;
                    self.workFlow = "VALIDATION";

                })
                .catch(function (error) {
                    self.errorOccurred = true;
                    self.error = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
        resetModal() {
            this.errorOccurred = false;
            this.selectedReason = "";
            this.workFlow = "SKIP";
        },
        handleVerificationSuccess(otp) {
            this.otp = otp;
            if (this.type === 'SIP' && this.action === 'PAUSE') {
                this.skipOrder();
            }
            else if (this.action === 'CANCEL') {
                this.cancel();
            } else {
                console.log("Unhandled action type or workflow");
            }
        },
        skipOrder() {
            let data = {
                "orderId": this.orderId,
                "type": this.type,
                "otp": this.otp,
                "subType": this.action,
            };
            var self = this;
            this.$store
                .dispatch("manageStore/skipOrder", data, { root: true })
                .then(function () {
                    self.workFlow = "SUCCESS"
                    self.completionMessage = `Installment scheduled for ${self.formattedDate} has been paused.`;
                })
                .catch(function (error) {
                    self.workFlow = "ERROR"
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the process. Please try again.";
                });
        },
        cancel() {
            let data = {
                "orderId": this.orderId,
                "type": this.type,
                "otp": this.otp,
                "subType": this.action,
            };
            var self = this;
            self.$store
                .dispatch("manageStore/cancelOrders", data, { root: true })
                .then(function () {
                    self.workFlow = "SUCCESS"
                    self.completionMessage = ` ${self.type} has been Cancelled successfully.`;
                })
                .catch(function (error) {
                    self.workFlow = "ERROR";
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the process. Please try again.";
                });
        },
        handleOtpSuccess() {
            this.errorOccurred = false;
            this.success = false;
            this.resentOtp()
        },
        resentOtp() {
            let data = {
                "orderId": this.orderId,
                "type": this.type,

            };
            var self = this;
            this.$store.dispatch("manageStore/resentOtp", data, { root: true })
                .then(function (response) {
                    console.log(response);
                    self.success = true;
                })
                .catch(function (error) {
                    self.errorOccurred = true;
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
        handleRedirect() {
            this.workFlow = "SKIP";
        },
        retrySubmission() {
            this.errorOccurred = false;
            this.submitData();
        },

    },
};
</script>