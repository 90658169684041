<template>
  <div class="container-fluid px-0">
    <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-5 px-0">
      <div class="row flex-column flex-md-row">
        <div class="col-12 p-0 ps-2 z-index-sticky text-black">
          <div>
            <div class="container me-0 position-relative">
              <div class="table-responsive pt-3">
                <table id="datatable-search" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  header_width">
                        Scheme name
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                        Invested Value
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Current Value
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Current return
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";

export default {
  name: "Table",
  components: {
  },
  data() {
    return {
      fiterText: "",
      direction: "",
      pageNo: 0,
      schemes: [],
    };
  },
  mounted() {
    const storeValue = sessionStorage.getItem("schemes");
    if (storeValue) {
      const parsedSchemes = JSON.parse(storeValue);
      if (parsedSchemes) {
        const schemeGroup = Object.groupBy(parsedSchemes, ({ fundId }) => fundId);
        const sumMap = {};
        for (const [key, value] of Object.entries(schemeGroup)) {
          if (value) {
            const sum = value.reduce((sum, a) => sum + a.amount, 0);
            sumMap[key] = sum;
          }
        }
        const Scheme = Object.keys(schemeGroup);
        for (let obj of Scheme) {
          const data = [];
          data.push(schemeGroup[obj][0].schemeName);
          data.push(sumMap[obj]);
          data.push(schemeGroup[obj].reduce((sum, a) => sum + a.currentValue, 0));
          data.push(((data[2] - data[1]) / data[1] * 100).toFixed(2));
          const getUnlockedAmount = (folio) => {
            const lockedCurrentAmount = Math.round(folio.nav * folio.lockInQuantity * 100) * 0.01;
            return (lockedCurrentAmount && lockedCurrentAmount >= 0) ? (folio.currentValue - lockedCurrentAmount) : folio.currentValue;
          };
          const minRedemptionAmount = schemeGroup[obj][0].minimumRedemptionAmount;
          const minimumRedemptionFlag = schemeGroup[obj].some(folio => { return getUnlockedAmount(folio) >= minRedemptionAmount });

          const aswpMinInstallmentAmount = schemeGroup[obj][0].aswpMinInstallmentAmount;
          const aswpMinInstallmentFlag = schemeGroup[obj].some(folio => getUnlockedAmount(folio) >= aswpMinInstallmentAmount);
          const aswpMultiplierFlag = schemeGroup[obj].some(folio => {
            const unlockedAmount = getUnlockedAmount(folio);
            if (unlockedAmount === null || unlockedAmount === undefined || unlockedAmount === 0) {
              return false;
            }
            return Math.floor(unlockedAmount) % schemeGroup[obj][0].aswpMultiplierAmount === 0;
          });
          const stpFrequencies = schemeGroup[obj][0].stpFrequencies;
          const astpMultiplierFlag = Object.values(stpFrequencies).every((frequencyData) => {
            const multiplier = frequencyData.astpOutmultiplierAmount;
            return schemeGroup[obj].some(folio => {
              const unlockedAmount = getUnlockedAmount(folio);
              if (unlockedAmount == null || unlockedAmount === 0) {
                return false;
              }
              return Math.floor(unlockedAmount) % multiplier === 0;
            });
          });
          const astpMinInstallmentFlag = Object.values(stpFrequencies).every((frequencyData) => {
            const astpMinInstallmentAmount = frequencyData.astpOutMinInstallmentAmount;
            return schemeGroup[obj].some(folio => {
              const unlockedAmount = getUnlockedAmount(folio);
              if (unlockedAmount == null || unlockedAmount === 0) {
                return false;
              }
              return getUnlockedAmount(folio) >= astpMinInstallmentAmount;
            });
          });
          const allowSTP = ['DAILY', 'MONTHLY', 'WEEKLY'].some(freq => {
            const frequencyData = stpFrequencies[freq];
            return frequencyData && frequencyData.astpRegOut === 'Y' && frequencyData.astpStatus === true;
          });
          const fundDetails = {
            fundId: schemeGroup[obj][0].fundId,
            switchFlag: schemeGroup[obj][0].switchFlag,
            redemptionAllowed: schemeGroup[obj][0].redemptionAllowed,
            minRedemptionAmount: minRedemptionAmount,
            minimumRedemptionFlag: minimumRedemptionFlag,
            lockinPeriodFlag: schemeGroup[obj][0].lockinPeriodFlag,
            astpRegOut: schemeGroup[obj][0].astpRegOut,
            astpStatus: schemeGroup[obj][0].astpStatus,
            astpMinInstallmentFlag: astpMinInstallmentFlag,
            aswpStatus: schemeGroup[obj][0].aswpStatus,
            aswpMinInstallmentAmount: aswpMinInstallmentAmount,
            aswpMinInstallmentFlag: aswpMinInstallmentFlag,
            aswpMultiplierFlag: aswpMultiplierFlag,
            astpMultiplierFlag: astpMultiplierFlag,
            allowSTP: allowSTP
          };
          data.push(JSON.stringify(fundDetails));
          this.schemes.push(data);
        }
        this.updateTable(this.schemes, 0);
      }
    }
  },
  unmounted() {
    if (this.dataTab != null) {
      this.dataTab.destroy();
    }
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    updateTable(tbody, pageNo) {
      var self = this;
      if (self.dataTab != null) {
        this.dataTab.destroy();
      }
      this.dataTab = new DataTable("#datatable-search", {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        perPage: 5,
        columns: [
          {
            select: 0, sortable: false,
            render: function (data, td, rowIndex, cellIndex) {
              const items = data.split('~~');
              var tableData = `<div class="text-wrap" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                var schemeName = items[0].replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
                tableData += `<div><span class="text-sm font-weight-bold">${schemeName}</span></div>`;
              }
              if (items[1]) {
                tableData += `<div><span class="text-xs badge badge-secondary"><i class="fa-solid fa-tags me-2"></i>${items[1]}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 1, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const formatted = Number(data).toLocaleString('en', options);
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span>₹ ${formatted}</span></div>`;
            }
          },
          {
            select: 2, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const value = isNaN(data) ? 0 : data;
              const formatted = Number(value).toLocaleString('en', options);
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span>₹ ${formatted}</span></div>`;
            }
          },
          {
            select: 3, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const value = isNaN(data) ? 0 : data;
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span> ${value}%</span></div>`;
            }
          },
          {
            select: [4], sortable: false, render: function (data, td, rowIndex, cellIndex) {
              var schemeData = JSON.parse(data)
              const fundId = schemeData.fundId;
              const switchFlag = schemeData.switchFlag;
              const redemptionAllowed = schemeData.redemptionAllowed;
              const minimumRedemptionFlag = schemeData.minimumRedemptionFlag;
              const lockinPeriodFlag = schemeData.lockinPeriodFlag;
              const aswpStatus = schemeData.aswpStatus;
              const allowSTP = schemeData.allowSTP;
              const aswpMinInstallmentFlag = schemeData.aswpMinInstallmentFlag;
              const astpMinInstallmentFlag = schemeData.astpMinInstallmentFlag;
              if (!fundId) {
                return `<div class="text-sm text-muted text-center" name='td_${cellIndex}' data-row='${rowIndex}'></div>`;
              }
              return `
              <div class="text-sm font-weight-normal text-capitalize td-padding" name='td_${cellIndex}' data-row='${rowIndex}'>
        <div class="cursor-pointer">
          <button class="btn btn-icon  box-shadow-none" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end shadow-lg" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" href="#/details/${fundId}" target="_self">Details</a></li>
            ${redemptionAllowed === 'Y' && minimumRedemptionFlag ? `<li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#redemption_${fundId}">Redeem</a></li>` : ''}
            ${switchFlag === 'Y' && redemptionAllowed === 'Y' && minimumRedemptionFlag ? `<li><a class="dropdown-item" data-action="switch" data-id="${fundId}" data-type="SWITCH">Switch Fund</a></li>` : ''}
            ${aswpStatus && lockinPeriodFlag === 'N' && aswpMinInstallmentFlag ? `<li><a class="dropdown-item" href="#" data-action="SWP" data-id="${fundId}" data-type="SWP">Start SWP</a></li>` : ''}
            ${allowSTP && lockinPeriodFlag === 'N' && astpMinInstallmentFlag ? `<li><a class="dropdown-item" href="#" data-action="STP" data-id="${fundId}" data-type="STP">Start STP</a></li>` : ''}
          </ul>
        </div>
      </div>`;
            }
          },
        ],
        data: {
          "data": this.schemes
        }
      });
      this.dataTab.page(Number(pageNo ? pageNo : 1))
      this.addEventListeners();
      this.dataTab.on("datatable.page", this.addEventListeners);
    },
    addEventListeners() {
      const actionLinks = document.querySelectorAll('a[data-action]');
      actionLinks.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          const action = event.currentTarget.getAttribute('data-action');
          const fundId = event.currentTarget.getAttribute('data-id');
          const actionType = event.currentTarget.getAttribute('data-type');
          const switchData = {
            fundId: fundId,
            type: actionType,
          };
          this.$store.commit('orderStore/setSwitchFund', switchData);
          switch (action) {
            case 'switch':
              window.location.href = `#/switch`;
              break;
            case 'SWP':
              window.location.href = `#/swp`;
              break;
            case 'STP':
              window.location.href = `#/stp`;
              break;
            default:
              console.warn('Unknown action:', action);
              break;
          }
        });
      });
    },

  }
};
</script>