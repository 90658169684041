<template>
  <div class="modal fade" :id="'modalBuy' + uniqueNumber" tabindex="-1" role="dialog" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-sm px-md-0" role="document">
      <div class="modal-content">
        <div class="modal-header border-0 d-flex justify-content-start pb-0 px-4">
          <h6 class="modal-title ms-1" id="modal-title-notification">{{ actionType }}</h6>
        </div>
        <div class="py-0 my-0 px-4 ms-1">
          <small class="d-block text-xxs"></small>
        </div>
        <div class="modal-body p-0 h-100">
          <div class="card card-plain modal-card">
            <div v-if="twoFactorAuth && !showSpinner && !success && !errorOccurred" class="card-body">
              <TwoFactorAuth @verification-success="handleVerificationSuccess" @previous="handleRedirect"
                @resent-otp="handleOtpSuccess" @otpResentSuccessfully="handleOtpResentSuccess" :setTimer="setTimer">
              </TwoFactorAuth>
            </div>
            <div v-if="showSpinner" class="d-flex flex-column align-items-center justify-content-center pt-8">
              <VueSpinnerIos size="100" color="#c71c4e" />
              <div class="pt-3">
                <h5 class="buccaneer">Processing</h5>
              </div>
            </div>
            <div v-if="success">
              <div class="mt-4 align-item-center ms-1">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
              <div class="card-body ps-4 py-3">
                <div>
                  <h4 class="text-center me-4 ps-2 mb-3">Order successful</h4>
                  <p class="text-center text-xxs text-justify mb-3 text-capitalize">{{ actionType }} Investment of
                    <b>₹{{ amount }}</b> in {{ modalTitle.toLowerCase() }}.
                  </p>
                </div>
                <div class="text-center">
                <small class="text-xs">Ref:<b>OS-{{ orderId }}</b></small>
              </div>
              </div>
              <div class="text-center justify-content-center px-4">
                <soft-button type="button" class="btn bg-primary text-white" full-width data-bs-dismiss="modal"
                  @click="resetModal">Done</soft-button>
              </div>
              <div class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row mt-1">
                <a id="resend" href="javascript:;" @click="redirect" class="font-weight-bolder ms-sm-1 mt-sm-0 text-xxs"
                  data-bs-dismiss="modal"><u>view details</u></a>
              </div>
            </div>
            <div v-if="errorOccurred">
              <div class="mt-5 align-item-center ms-1">
                <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
              </div>
              <div class="card-body ps-4 py-3">
                <div class="error-message">
                  <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                  <p class="text-center text-xxs pt-2 text-capitalize ps-2">{{ errorMessage }} </p>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-3 mx-4">
                <soft-button class="text-primary bg-white border border-primary me-4 w-100" data-bs-dismiss="modal"
                  @click="resetModal">Close</soft-button>
                <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="button"
                  @click="retrySubmission"> Retry
                </soft-button>
              </div>
              <div class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row mt-2">
                <a id="resend" href="javascript:;" @click="redirect" class="font-weight-bolder ms-sm-1 mt-sm-0 text-xxs"
                  data-bs-dismiss="modal"><u>view details</u></a>
              </div>
            </div>
            <div>

              <div v-if="!showSpinner && !success && !errorOccurred && !twoFactorAuth" class="card-body">
                <Form role="form text-left" @submit="onSubmit">
                  <div class="mb-4">
                    <soft-input id="amount" :rules="amountTouched ? { required: true, min_value: minValue, max_value: maxValue } : {}"
                      type="text" isRequired="true" placeholder="Investment Amount" v-model="amount" name="Amount" @input="onInputChange"/>
                  </div>
                  <div v-if="actionType === 'SIP'" class="mb-3">
                    <SipCalender v-model="date" />
                  </div>
                  <div v-if="actionType === 'One-Time'">
                    <div class="container px-0 ms-2">
                      <div class="row">
                        <div class="col-4 px-0">
                          <button type="button"
                            class="btn btn-rounded px-2 py-2 btn-outline-light-mauve text-buccaneer btn-rounded ms-1"
                            @click="addAmount(100000)">+₹1 lakh</button>
                        </div>
                        <div class="col-4 px-0">
                          <button type="button"
                            class="btn btn-rounded px-2 py-2  btn-outline-light-mauve text-buccaneer btn-rounded"
                            @click="addAmount(200000)">+₹2 lakh</button>
                        </div>
                        <div class="col-4 px-0">
                          <button type="button"
                            class="btn btn-rounded px-2 py-2  btn-outline-light-mauve text-buccaneer btn-rounded"
                            @click="addAmount(500000)">+₹5 lakh</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="alert bg-light px-0 py-0 mt-1" role="alert">
                    <div class="ps-3 pe-3 text-justify pt-3">
                      <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2" aria-hidden="true"></i>The Minimum
                        Amount is
                        <b>{{ minValue }}</b>
                      </p>
                      <p class="text-xxs mb-0"><i class="fa fa-info-circle me-2" aria-hidden="true"></i>The Current Nav
                        is
                        <b>{{ navValue }}</b>
                      </p>
                      <p class="text-xxs mb-0 text-capitalize"><i class="fa fa-info-circle me-2"
                          aria-hidden="true"></i>{{ modalTitle.toLowerCase() }}.</p>
                      <p class="text-xxs"><i class="fa fa-info-circle me-2" aria-hidden="true"></i>We have activated the
                        mandate for our transactions using the given mandate ID <b>{{ mandateId }}</b>.</p>
                    </div>
                  </div>
                  <div class="text-center error-height"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                  </div>
                  <div class=" d-flex justify-content-center mt-4">
                    <soft-button  type="button" class="text-primary bg-white border border-primary me-4 w-100" data-bs-dismiss="modal"
                      @click="resetModal">Cancel</soft-button>
                    <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="submit"> Invest
                    </soft-button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import { VueSpinnerIos, } from 'vue3-spinners';
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
import SipCalender from "@/components/SipCalender.vue";
import TwoFactorAuth from "@/components/TwoFactorAuthentication.vue";
export default {
  name: "ModalBuy",
  components: {
    SoftInput,
    SoftButton,
    Form,
    VueSpinnerIos,
    SipCalender,
    TwoFactorAuth,
  },
  data() {
    return {
      amount: "",
      showSpinner: false,
      success: false,
      errorOccurred: false,
      errorMessage: "",
      date: "",
      frequencyType: "MONTHLY",
      mandateId: "",
      twoFactorAuth: false,
      orderId: "",
      approvalStatus: "",
      isSuccess: true,
      setTimer:'',
      amountTouched: false,
    }
  },
  props: {
    modalTitle: {
      type: String,
      default: "",
    },
    minimumInvestment: {
      type: String,
      default: "",
    },
    navValue: {
      type: String,
      default: "",
    },
    actionType: {
      type: String,
      default: "",
    },
    schemeCode: {
      type: String,
      default: "",
    },
    uniqueNumber: {
      type: String,
      default: "",
    },
    mandateData: {
      type: String,
      default: null,
    },
    additionalPurchase: {
      type: String,
      default: "",
    },
    maximumPurchaseAmount: {
      type: String,
      default: "",
    },
    sipDetails: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    type() {
      return this.actionType === 'SIP' ? 'SIP' : 'LUMP_SUM';
    },
    formattedDate() {
      if (!this.date) return '';
      const now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      const currentDay = now.getDate();
      if (this.date <= currentDay) {
        month = month + 2;
        if (month > 12) {
          month = 1;
          year++;
        }
      } else {
        month++;
      }
      const day = this.date.toString().padStart(2, '0');
      const monthFormatted = month.toString().padStart(2, '0');
      return `${day}/${monthFormatted}/${year}`;
    },
    minValue() {
  // If the action type is SIP, always return the SIP minimum installment amount
  if (this.actionType === 'SIP' && this.sipDetails && this.sipDetails.minInstAmt) {
    return parseFloat(this.sipDetails.minInstAmt);
  }

  // If the action type is not SIP, calculate the minimum investment
  const minInvestment = parseFloat(this.minimumInvestment);
  const additionalPurchase = parseFloat(this.additionalPurchase);

  // Return the greater of minInvestment or additionalPurchase
  return additionalPurchase && minInvestment > additionalPurchase ? minInvestment : additionalPurchase;
},
    maxValue() {
      if (this.actionType === 'SIP' && this.sipDetails && this.sipDetails.maxInstAmt) {
    // Ensure that minInstAmt is always used for SIP
    return parseFloat(this.sipDetails.maxInstAmt);
  } 
    const maxInvestment = parseFloat(this.maximumPurchaseAmount);
    return maxInvestment > 0 ? maxInvestment : 100000000;
    },
    
  isValidDate() {
    const  maximumGap= parseFloat(this.sipDetails[0].sipMaxGap);
      const now = new Date();
      const selectedDay = this.date;
      const selectedDate = new Date(now.getFullYear(), now.getMonth() + 1, selectedDay);
      const minGapDate = new Date(now.getTime()); 
      const maxGapDate = new Date(now.getTime() + maximumGap * 24 * 60 * 60 * 1000); 
      return selectedDate >= minGapDate && selectedDate <= maxGapDate;
    }
  },
  watch: {
    actionType() {
      this.resetModal();
    },
    mandateData(newVal) {
      if (newVal) {
        this.mandateId = newVal;
      }
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    if (this.mandateData) {
      this.mandateId = this.mandateData;
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.$store.state.showFooter = false;
    this.$store.state.showSidenav = false;
    if (this.actionType === 'SIP' && !this.date) {
    // Set the default date or the date from SIP details if available
    this.date = this.sipDetails?.defaultDate || new Date().getDate();
  }
    if (this.mandateData == null) {
      this.getAllMandates();
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    resetModal() {
      this.amount = "";
      this.success = false;
      this.errorOccurred = false;
      this.twoFactorAuth = false;
      this.date = "";
      this.isSuccess= true;
      this.amountTouched = false;
    },
    addAmount(value) {
      let currentAmount = this.amount ? parseInt(this.amount) : 0;
      currentAmount += value;
      this.amount = currentAmount.toString();
    },
    getAllMandates() {
      var self = this;
      this.$store
        .dispatch("productStore/getMandates", { root: true })
        .then(function (response) {
          self.mandateId = response.server.data[0].mandateCfmId;
          self.approvalStatus = response.server.data[0].approvalStatus || 'PENDING';
          self.$emit('approval-status', self.approvalStatus);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateSipAmount(amount) {
    // Convert amount to a number
    const validAmount = amount;
    const multiplier = parseFloat(this.sipDetails?.multiplierAmt) || 1;

    // Check if both amount and multiplier are valid numbers
    if (Number.isNaN(validAmount) || Number.isNaN(multiplier)) {
      console.error("Invalid amount or multiplier:", { validAmount, multiplier });
      return false;
    }

    return (validAmount % multiplier) === 0;
  },
    onSubmit() {
      if (this.actionType === 'SIP' && !this.date) {
        this.isSuccess = false;
        this.error = "Please select a start date .";
        return;
      }
      const amountValue = parseFloat(this.amount);
      if (this.actionType === 'SIP' && !this.validateSipAmount(amountValue)) {
      this.errorOccurred = true;
      this.errorMessage = `The amount should be a multiple of ₹${this.sipDetails.sipMultiplierAmount}.`;
      return;
    }
      this.twoFactorAuth = true;
      this.submitData();
    },
    handleRedirect() {
      this.twoFactorAuth = false;
      this.errorOccurred = false;
      this.success = false;
      this.showSpinner = false;
    },
    handleVerificationSuccess(otp) {
      this.otp = otp;
      this.errorOccurred = false;
      this.success = false;
      this.showSpinner = true;
      this.getOtp()
    },
    handleOtpSuccess() {
      this.errorOccurred = false;
      this.success = false;
      this.resentOtp()
    },
    submitData() {
      this.showSpinner = true;
      setTimeout(() => {
        this.showSpinner = false;
        let data = {};
        if (this.actionType === 'SIP') {
          data = {
            "type": this.type,
            "amount": this.amount,
            "fundId": this.uniqueNumber,
            "frequencyType": this.frequencyType,
            "mandateId": this.mandateId,
            "sipStartDate": this.date,
            "schemeCode": this.schemeCode,
          };
        } else {
          data = {
            "type": this.type,
            "amount": this.amount,
            "schemeCode": this.schemeCode,
            "fundId": this.uniqueNumber,
            "mandateId": this.mandateId,
          };
        }
        var self = this;
        this.$store
          .dispatch("productStore/orders", data, { root: true })
          .then(function (response) {
            self.orderId = response.server.data.orderId;
            self.twoFactorAuth = true;
            self.errorOccurred = false;
            self.success = false;

            self.showSpinner = false;
          })
          .catch(function (error) {
            self.errorOccurred = true;
            self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
          });
      },);
    },
    retrySubmission() {
      this.errorOccurred = false;
      this.submitData();
    },
    redirect() {
      this.$router.push('/orderstatus');
    },
    getOtp() {
      this.showSpinner = true;
      setTimeout(() => {
        this.showSpinner = false;
        let data = {
          "orderId": this.orderId,
          "fundId": this.uniqueNumber,
          "otp": this.otp,

        };
        var self = this;
        this.$store.dispatch("productStore/confirmOrder", data, { root: true })
          .then(function (response) {
            console.log(response);
            self.success = true;
            self.errorOccurred = false;
          })
          .catch(function (error) {
            self.errorOccurred = true;
            self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
          });
      },);
    },
    resentOtp() {
      let data = {
        "orderId": this.orderId,
        "fundId": this.uniqueNumber,

      };
      var self = this;
      this.$store.dispatch("productStore/resentOtp", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.setTimer="resend";

        })
        .catch(function (error) {
          self.errorOccurred = true;
          self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
        });
    },
    onInputChange(event) {
    const value = event.target.value.replace(/[^0-9]/g, '');
    this.amount=value;
    event.target.value = value; 
    if (!this.amountTouched && value !== "") {
        this.amountTouched = true; // Mark input as "touched" once a value is entered
      }
  },

  },
};
</script>