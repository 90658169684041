<template>
  <div class="card">
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="avatar avatar-lg">
          <avatar :size="80" :name="avatarName" :colors="colors" />
          <!-- <img alt="Image placeholder" src="https://source.boringavatars.com/marble/120/Maria%20Mitchell?colors=264653,2a9d8f,e9c46a,f4a261,e76f51" /> -->
        </div>
        <div class="my-auto ms-2">
          <h5 class="mb-0 text-capitalize">{{ title.toLowerCase() }}</h5>
          <p class="mb-0 text-xs">{{ category }} <button
            v-if="lockInPeriod !==0"
            class="btn btn-outline-primary btn-sm mt-2 ms-2 rounded-pill text-xl px-3 text-capitalize no-pointer-cursor"
          >
          <i class="fa-solid fa-lock text-sm me-1"></i> {{ lockInPeriod }} days LockIn
          </button></p>
        </div>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mt-3 text-sm" v-html="description" />
      <p class="mt-5 text-sm" v-if="description.length < 1">Not Available</p>

      <p class="mb-0"></p>
      <hr class="horizontal dark" />
      <div class="d-flex justify-content-end">
        <button v-if="sipFlag === 'Y' && sipDetails.status"  type="button" class="mx-3 btn btn-sm mb-0" :class="`bg-primary text-white`"
          @click="handleAction('SIP')">
          SIP
        </button>
        <productModal :modalTitle="title" :minimumInvestment="minimumInvestment" :navValue="navValue"
          :actionType="currentActionType" :schemeCode="schemeCode" :uniqueNumber="uniqueNumber"
          @approval-status="handleApprovalStatus" :additionalPurchase="additionalPurchase"
          :maximumPurchaseAmount="maximumPurchaseAmount" :sipDetails="sipDetails"></productModal>

        <button v-if="purchaseAllowed=='Y'" type="button" class="mx-3 btn btn-sm mb-0" :class="`bg-primary text-white`"
          @click="handleAction('One-Time')">
          BUY
        </button>
        <mandateModal id="mandateModal" :approvalStatus="approvalStatus" :uniqueNumber="uniqueNumber"
          :errorMessage="errorMessage" :type="currentActionType"></mandateModal>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from "vue-boring-avatars";
import productModal from "@/views/common/components/ProductModal.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import mandateModal from "@/components/MandateAuthorization.vue";
export default {
  name: "DetailCard",

  components: {
    avatar,
    productModal,
    mandateModal
  },
  data() {
    return {
      currentActionType: '',
      message: "createUser",
      approvalStatus: "",
      errorMessage: "",
      regStatus: "",
      approvedStatus: "",
    };
  },
  methods: {
    setActionType(type) {
      this.currentActionType = type;
    },
    handleApprovalStatus(status) {
      this.approvalStatus = status;
    },
    handleAction(type) {
      if (this.isLoggedIn()) {
        if (this.isMandatesRegistrationCompleted() && this.approvalStatus=="APPROVED") {
          this.setActionType(type);
          this.showProductModal();
        } else {
          this.hideModal();
          this.setActionType(type);
          this.regStatus = sessionStorage.getItem("regStatus");
          this.approvedStatus = sessionStorage.getItem("approvedStatus");
          if (type === 'One-Time' && this.regStatus !== 'ACTIVE' && this.approvedStatus !== 'ACTIVE') {
            this.errorMessage = "Please complete the UCC, ELOG and nominee authentication before placing a lumpsum order.";
            this.showMandateModal();
          } else if (type === 'SIP') {
            this.showMandateModal();
          } else {
            this.setActionType(type);
            this.showProductModal();
          }
        }
      } else {
        this.hideModal();
        this.redirectToSignup();
      }
    },
    isLoggedIn() {
      return sessionStorage.getItem('accessToken');
    },
    isMandatesRegistrationCompleted() {
      var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
      const userStatus = userPayload['custom:user_signup_status'];
      return userStatus === 'VERIFIED';
    },
    hideModal() {
      const modalElement = document.querySelector(`[id^="modalBuy"]`);
      if (modalElement) {
        const modal = bootstrap.Modal.getInstance(modalElement);
        if (modal) {
          modal.hide();
        }
        // Remove modal backdrop
        document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '';
      }
    },
    showProductModal() {
      const modalElement = document.querySelector(`[id^="modalBuy"]`);
      if (modalElement) {
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }
    },
    showMandateModal() {
      const mandateModalElement = document.getElementById('mandateModal');
      if (mandateModalElement) {
        const modal = new bootstrap.Modal(mandateModalElement);
        modal.show();
      }
    },
    redirectToSignup() {
      this.$router.push({ name: 'Sign In', params: { message: this.message } });
    },
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Not Available",

    },
    dateTime: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "Not Available",
    },
    action: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => { },
    },
    members: {
      type: Array,
      image: String,
      name: String,
      default: () => [],
    },
    minimumInvestment: {
      type: String,
      default: "",
    },
    navValue: {
      type: String,
      default: "",
    },
    schemeCode: {
      type: String,
      default: "",
    },
    uniqueNumber: {
      type: String,
      default: "",
    },
    sipFlag: {
      type: String,
      default: "",
    },
    purchaseAllowed: {
      type: String,
      default: "",
    },
    additionalPurchase: {
      type: String,
      default: "",
    },
    maximumPurchaseAmount:{
      type: String,
      default: "",
    },
    sipDetails: {
      type: Object,
      default: () => ({})
    },
   lockInPeriod: {
      type: String,
      default: "",
    },
  },
};
</script>