<template>
    <div class="card multisteps-form__panel  selection-card-height border-radius-xl bg-white shadow-lg shadow-top-bottom"
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :pageName="pageName" :descriptionText="descriptionText" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content px-md-4 px-3">
                    <div class="card-body p-0 mt-5 mb-6 ps-2">
                        <div class="container-fluid p-0 mt-2">
                            <Form role="form text-left" @submit.prevent="onSubmit">
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">SWP From</span>
                                    <span class="text-xs col-7"><b>{{ selectedSchemes?.[0]?.schemeName }}</b></span>
                                </div>
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">Folio Number</span>
                                    <span class="text-xs col-7"><b>{{ folioData?.folioNumber }}</b></span>
                                </div>
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">Amount</span>
                                    <span class="text-xs col-7"><b>₹{{ formattedAmount(swpData?.amount) }}</b></span>
                                </div>
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">Frequency</span>
                                    <span class="text-xs col-7"><b>{{ swpData?.frequency }}</b></span>
                                </div>
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">Installment</span>
                                    <span class="text-xs col-7"><b>{{ swpData?.installment }}</b></span>
                                </div>
                                <div class="row mb-3 d-flex justify-content-between ms-md-4 ms-2">
                                    <span class="text-xs col-5">Total Amount</span>
                                    <span class="text-xs col-7 "><b>₹{{ formattedAmount(swpData?.totalAmount)
                                            }}</b></span>
                                </div>
                                <div class="text-center pb-5 error-height"><small class="text-danger text-xs"
                                        v-if="!isSuccess">{{ errorMessage
                                        }}</small>
                                </div>
                                <div>
                                    <div class="d-flex justify-content-between pt-6 px-3">
                                        <!-- Go Back Button -->
                                        <soft-button @click.prevent="previous"
                                            class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left" type="button">
                                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                                                <span class="ms-2">previous</span>
                                        </soft-button>
                                        <soft-button ref="buttonRef" class="bg-primary icon-move-right" type="submit">
                                            Next
                                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                                        </soft-button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
export default {
    name: 'SwpReview',
    components: {
        SoftButton,
        LeftPanelView,
    },
    data() {
        return {
            schemes: [],
            units: 0,
            nav: 0,
            totalAmount: 0,
            errorMessage: "",
            orderId: "",
            pageName: "SwpReview",
            descriptionText: 'Kindly review the provided details and proceed if they are correct, or return to make any required adjustments.',
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    props: {
        folioData: {
            type: Object,
            default: () => ({})
        },
        swpData: {
            type: Object,
            default: () => ({})
        },
        currentStep: Number,
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
        formattedAmount() {
            return (amount) => {
                if (!amount) return "0.00";
                const formatted = parseFloat(amount);
                return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formatted);
            };
        },
    },
    methods: {
        previous() {
            this.$parent.prevStep();
        },
        onSubmit() {
            let data = {
                "type": this.getSwitchFund.type,
                "folioNo": this.folioData.folioNumber,
                "installmentAmount": this.swpData.amount,
                "noOfWithdrawals": this.swpData.installment,
                "startDate": this.swpData.date,
                "frequencyType": this.swpData.frequency,
                "fundId": this.getSwitchFund.fundId
            }
            var self = this;
            this.$store
                .dispatch("orderStore/swpOrder", data, { root: true })
                .then(function (response) {
                    self.orderId = response.server.data.orderId || 0;
                    self.$emit('order-id', self.orderId);
                    self.$parent.nextStep();
                })
                .catch(function (error) {
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
    }

};
</script>