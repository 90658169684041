<template>
    <div class="card multisteps-form__panel  selection-card-height border-radius-xl bg-white shadow-top-bottom "
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :pageName="pageName" :descriptionText="descriptionText" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content px-md-4 mt-5 px-3">
                    <div class="card-body p-0 mb-4 ps-2">
                        <div class="container-fluid p-0 mt-3">
                            <Form role="form text-left" @submit.prevent="onSubmit">
                                <div class="row mb-2 mt-4 ms-md-4 ms-2">
                                    <div class="col-3">
                                        <span class="text-xs">Switch From</span>
                                    </div>
                                    <div class="col-9">
                                        <span class="text-xs"><b>{{ selectedSchemes?.[0]?.schemeName }}</b></span>
                                    </div>
                                </div>
                                <div class="row mb-2 ms-md-4 ms-2">
                                    <div class="col-3">
                                        <span class="text-xs">Switch To</span>
                                    </div>
                                    <div class="col-9">
                                        <span class="text-xs"><b>{{ schemeData?.schemeName }}</b></span>
                                    </div>
                                </div>
                                <div class="row mb-2 ms-md-4 ms-2">
                                    <div class="col-3">
                                        <span class="text-xs">Folio Number</span>
                                    </div>
                                    <div class="col-9">
                                        <span class="text-xs"><b>{{ folioData?.folioNumber }}</b></span>
                                    </div>
                                </div>

                                <div class="row mb-2 ms-md-4 ms-2">
                                    <div class="col-3">
                                        <span class="text-xs">Amount</span>
                                    </div>
                                    <div class="col-9">
                                        <span class="text-xs"><b>₹{{ formattedAmount }}</b></span>
                                    </div>
                                </div>
                                <div class="text-center mt-4"><small class="mt-4 text-danger text-xs"
                                        v-if="!isSuccess">{{
                                            errorMessage
                                        }}</small>
                                </div>
                                <div>
                                    <div class="d-flex justify-content-between pt-8 px-3">
                                        <!-- Go Back Button -->
                                        <soft-button @click.prevent="previous"
                                            class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left" type="button">
                                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                                                <span class="ms-2">previous</span>
                                        </soft-button>
                                        <soft-button ref="buttonRef" class="bg-primary icon-move-right" type="submit">
                                           Next
                                           <i class="fas fa-chevron-right" aria-hidden="true"></i>
                                        </soft-button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
import { mapGetters } from "vuex";
export default {
    name: 'SwitchReview',
    components: {
        SoftButton,
        LeftPanelView,
    },
    data() {
        return {
            schemes: [],
            units: 0,
            nav: 0,
            totalAmount: 0,
            errorMessage: "",
            orderId: "",
            isSuccess: true,
            pageName: "Review",
            descriptionText: 'Kindly review the provided details and proceed if they are correct, or return to make any required adjustments.',
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    props: {
        folioData: {
            type: Object,
            default: () => ({})
        },
        schemeData: {
            type: Object,
            default: () => ({})
        },
        selectedAmount: {
            type: String,
            default: "",
        },
        currentStep: Number,
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
        formattedAmount() {
            if (!this.selectedAmount) return "0.00";
            const amount = parseFloat(this.selectedAmount);
            return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
        },
    },
    methods: {
        previous() {
            this.isSuccess = true;
            this.$parent.prevStep();
        },
        onSubmit() {
            let data = {
                "type": this.getSwitchFund.type,
                "folioNo": this.folioData.folioNumber,
                "amount": this.selectedAmount,
                "fundId": this.schemeData.fundId
            };
            var self = this;
            this.$store
                .dispatch("orderStore/switchOrder", data, { root: true })
                .then(function (response) {
                    self.orderId = response.server.data.orderId || 0;
                    self.$emit('order-id', self.orderId);
                    self.isSuccess = true;
                    self.$parent.nextStep();
                })
                .catch(function (error) {
                    self.isSuccess = false;
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
    }

};
</script>