<template>
    <div class="card multisteps-form__panel  selection-card-height border-radius-xl bg-white shadow-top-bottom "
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :headingText="stepTitle" :progressStep="currentStep" :pageName="pageName"
                    :descriptionText="descriptionText" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content px-md-4 mt-6">
                    <div class="card-body mb-4 px-md-3">
                        <div class="mb-4 text-center text-justify p-0 text-muted ">
                            <span class="text-sm">Please enter the 6-digit OTP sent to {{ maskedEmail }}</span>
                        </div>
                        <Form role="form" @submit="onSubmit">
                            <div class="row gx-2 gx-sm-3">
                                <soft-input class="mb-0" placeholder="Enter OTP" v-model="otp" name="otp"
                                    id="businessName" type="password" rules="required:true" maxlength="6" />
                            </div>

                            <div
                                class="d-flex justify-content-center align-items-center text-sm mb-3 mt-4 flex-column flex-sm-row">
                                <a id="resend" href="javascript:;" @click="resend"
                                    :disabled="!resendEnable"
                                    class="text-info font-weight-bolder ms-sm-1 mt-2 mt-sm-0 text-sm">Resend OTP</a>
                                <span v-if="timer > 0" class="ms-sm-1 mt-2 mt-sm-0 text-xxs">in {{ timer }} seconds</span>
                            </div>
                            <div class="text-center error-height mt-5"><small class="mt-4 text-danger text-xs"
                                    v-if="!isSuccess">{{ error }}</small>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between pt-7 mt-3">
                                    <soft-button @click.prevent="redirect"
                                        class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left"
                                        type="button">
                                        <i class="fas fa-chevron-left" aria-hidden="true"></i>
                                        <span class="ms-2">previous</span>
                                    </soft-button>
                                    <soft-button ref="buttonRef" class="bg-primary icon-move-right" type="submit">
                                        Confirm
                                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                                    </soft-button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { mapGetters } from "vuex";
import { Form } from "vee-validate";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
export default {
    name: "SwitchAuthentication",
    components: {
        SoftButton,
        SoftInput,
        Form,
        LeftPanelView,
    },
    data() {
        return {
            otp: "",
            workFlow: "FAILED",
            count: 60,
            time: "",
            resendEnable: true,
            email: "",
            resetPassword: true,
            error: "",
            schemes: [],
            pageName: "2FA",
            descriptionText: 'Please enter the One-Time Password (OTP) sent to your registered email for authentication.',

        }
    },
    props: {
        currentStep: Number,
        orderId: {
            type: String,
            default: "",
        },
        folioData: {
            type: Object,
            default: () => ({})
        },
        schemeData: {
            type: Object,
            default: () => ({})
        },
        stpRegNo: {
            type: String,
            default: "",
        },
    },
    watch: {
        currentStep(newStep) {
            if (newStep === 4 || newStep === 3) {
                this.setTime();
            } else {
                this.stopTimer();
            }
        },
    },
    mounted() {
        if (this.currentStep === 4) {
            this.setTime();
        } else if (this.currentStep === 3 && this.getSwitchFund.type === 'SWP') {
            this.setTime();
        }
        var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
        this.email = userPayload.email;
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        timer() {
            return this.count;
        },
        maskedEmail() {
            if (this.email) {
                let parts = this.email.split('@');
                let name = parts[0];
                let domain = parts.length > 1 ? parts[1] : '';
                let maskedName = name.charAt(0) + 'xxx';
                return `${maskedName}@${domain}`;
            }
            return '';
        },
        authTitle() {
            if (this.getSwitchFund.type === 'STP') {
                return 'STP Authentication';
            } else if (this.getSwitchFund.type === 'SWP') {
                return 'SWP Authentication';
            }
            return 'Switch Authentication';
        }
    },
    beforeUnmount() {
        this.stopTimer();
    },
    methods: {
        setTime() {
            clearInterval(this.time);
            document.getElementById("resend").classList.remove("text-info");
            document.getElementById("resend").classList.add("text-secondary");
            this.resendEnable = false;
            this.count = 60;
            this.time = setInterval(this.myTimer, 1000);
        },
        stopTimer() {
            clearInterval(this.time);
            this.time = null;
        },
        myTimer() {

            this.count--;
            if (this.count == 0) {
                document.getElementById("resend").classList.remove("text-secondary");
                document.getElementById("resend").classList.add("text-info");
                this.resendEnable = true;
                clearInterval(this.time);
            }
        },
        onSubmit() {
            const type = this.getSwitchFund.type;
            if (type === 'SWITCH') {
                let data = {
                    "orderId": this.orderId,
                    "fundId": this.schemeData.fundId,
                    "otp": this.otp,
                };

                var self = this;
                this.$store
                    .dispatch("orderStore/switchConfirmation", data, { root: true })
                    .then(function () {
                        self.workFlow = "SUCCESS",
                            self.$emit('update', { workFlow: self.workFlow });
                        self.$parent.nextStep();
                    })
                    .catch(function (error) {
                        self.workFlow = "FAILED",
                        self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                        self.$emit("update", {
                            workFlow: self.workFlow,
                            errorMessage: self.errorMessage,
                        });
                        self.$parent.nextStep();

                    });
            } else if (type === 'STP') {
                let data = {
                    "orderId": this.orderId,
                    "fundId": this.schemeData.fundId,
                    "otp": this.otp,
                };
                var test = this
                this.$store
                    .dispatch("orderStore/stpConfirmation", data, { root: true })
                    .then(function () {
                        test.workFlow = "SUCCESS";
                        test.$emit('update', { workFlow: test.workFlow });
                        test.$parent.nextStep();
                    })
                    .catch(function (error) {
                        test.workFlow = "FAILED";
                        test.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                        test.$emit("update", {
                            workFlow: test.workFlow,
                            errorMessage: test.errorMessage,
                        });
                        test.$parent.nextStep();
                    });
            } else if (type === 'SWP') {
                let data = {
                    "orderId": this.orderId,
                    "fundId": this.getSwitchFund.fundId,
                    "otp": this.otp,
                };
                var scope = this
                this.$store
                    .dispatch("orderStore/swpConfirmation", data, { root: true })
                    .then(function () {
                        scope.workFlow = "SUCCESS";
                        scope.$emit('update', { workFlow: scope.workFlow });
                        scope.$parent.nextStep();
                    })
                    .catch(function (error) {
                        scope.workFlow = "FAILED";
                        scope.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                        scope.$emit("update", {
                            workFlow: scope.workFlow,
                            errorMessage: scope.errorMessage,
                        });
                        scope.$parent.nextStep();
                    });
            } else {
                this.error = "Invalid type";
            }

        },
        resend() {
            if (!this.resendEnable) {
                return;
            }
            const type = this.getSwitchFund.type;
            let fundId = type === 'SWP' ? this.getSwitchFund.fundId : this.schemeData.fundId;
            let data = {
                "orderId": this.orderId,
                "fundId": fundId,

            };
            var self = this;
            if (type === 'SWITCH') {
                this.$store.dispatch("orderStore/switchResentOtp", data, { root: true })
                    .then(function (response) {
                        console.log(response);
                        self.setTime();
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.error = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the resent otp. Please try again.";
                    });
            } else if (type === 'STP') {
                this.$store.dispatch("orderStore/stpResentOtp", data, { root: true })
                    .then(function (response) {
                        console.log(response);
                        self.setTime();
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.error = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the resent otp. Please try again.";
                    });
            } else {
                this.$store.dispatch("orderStore/swpResentOtp", data, { root: true })
                    .then(function (response) {
                        console.log(response);
                        self.setTime();
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.error = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the resent otp. Please try again.";
                    });
            }
        },
        redirect() {
            this.otp = "";
            this.isSuccess=true;
            this.$parent.prevStep();
        },
    }

};
</script>