<template>
    <div class="modal fade" :id="'redemption_' + fundId" tabindex="-1" role="dialog" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm px-4 px-md-0" role="document">
            <div class="modal-content">
                <div class="modal-header border-0 d-flex justify-content-start pb-0 px-4">
                    <h6 v-if="workFlow != 'FOLIO'" class="modal-title ms-1" id="modal-title-notification">Redeem</h6>
                    <h6 v-if="workFlow == 'FOLIO'" class="modal-title ms-1" id="modal-title-notification">Choose folio
                    </h6>
                </div>
                <div class="modal-body p-0 h-100">
                    <div class="card card-plain modal-card">
                        <div v-if="workFlow == 'OTP'" class="card-body">
                            <TwoFactorAuth @verification-success="handleVerificationSuccess" @previous="handleRedirect"
                                @resent-otp="handleOtpSuccess" :setTimer="setTimer">
                            </TwoFactorAuth>
                        </div>
                        <div v-if="workFlow == 'FOLIO'" class="card-header pb-0 text-left pt-2 text-justify px-4">
                            <p class=" text-xs mb-0">Your Investment in {{ selectedSchemes?.[0]?.schemeName }} are split
                                into multiple
                                folio.
                            </p>
                        </div>
                        <div v-if="workFlow == 'SUCCESS'">
                            <div class="mt-5 align-item-center ms-2">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div class="card-body ps-4 py-3">
                                <div>
                                    <h4 class="text-center me-4 ps-3 mb-3">Order successful</h4>
                                    <p class="text-center text-xxs text-justify mb-3 text-capitalize">
                                        Your redemption request for
                                        <b>{{ units }}</b> units has been successfully processed.
                                    </p>
                                </div>
                                <small class="text-center text-xs mx-4 ps-5 pb-1">Ref:<b>OS-{{ orderId }}</b></small>
                            </div>
                            <div class="text-center justify-content-center px-4">
                                <soft-button type="button" class="btn bg-primary text-white" full-width
                                    data-bs-dismiss="modal" @click="resetModal">Done</soft-button>
                            </div>
                            <div
                                class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row mt-1">
                                <a id="resend" href="javascript:;" @click="redirect"
                                    class="font-weight-bolder ms-sm-1 mt-sm-0 text-xxs" data-bs-dismiss="modal"><u>view
                                        details</u></a>
                            </div>
                        </div>
                        <div v-if="workFlow == 'ERROR' && errorOccurred" class="mt-5 align-item-center ms-1">
                            <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
                            </svg>
                        </div>
                        <div>
                            <div v-if="workFlow == 'ERROR' && errorOccurred" class="card-body ps-4 py-3">
                                <div>
                                    <h3 class="text-center me-4 ps-4 mb-3 text-capitalize">failed !</h3>
                                    <p class="text-center text-xxs pt-2 text-capitalize ps-2">{{ errorMessage }} </p>
                                </div>
                            </div>
                            <div v-if="workFlow == 'ERROR' && errorOccurred"
                                class="d-flex justify-content-center mt-3 pt-2 mx-4">
                                <soft-button class="text-primary bg-white border border-primary me-4 w-100"
                                    data-bs-dismiss="modal" @click="resetModal">Close</soft-button>
                                <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary" type="button"
                                    @click="retrySubmission"> Retry
                                </soft-button>
                            </div>
                            <div v-if="workFlow == 'ERROR' && errorOccurred">
                                <div
                                    class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row mt-2">
                                    <a id="resend" href="javascript:;" @click="redirect"
                                        class="font-weight-bolder ms-sm-1 mt-sm-0 text-xxs"
                                        data-bs-dismiss="modal"><u>view details</u></a>
                                </div>
                            </div>
                            <div v-if="workFlow == 'FOLIO'" class="card-body px-4">
                                <Form role="form text-left" @submit="redeem">
                                    <div class="scrollable-container">
                                        <div v-for="folio in filteredSchemes" :key="folio.folio" class="mb-1">
                                            <soft-radio v-model="selectedFolioNumber" :value="folio.folio"
                                                checked="true" rules="required:true" name="unchecked"
                                                :disabled="folio.isFullyLocked">
                                                <div class="w-100">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div
                                                                class="d-flex justify-content-between align-items-center">
                                                                <p class="mb-0"> <span
                                                                        v-if="folio.isFullyLocked || folio.isPartiallyLocked"
                                                                        class="text-danger mt-1 me-2">
                                                                        <i class="fa fa-lock text-xs text-primary cursor-pointer"
                                                                            data-bs-toggle="popover"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="bottom"
                                                                            :data-bs-content="getPopoverContent(folio)"
                                                                            aria-label="Order Summary Information"></i>
                                                                    </span><b v-if="!folio.isFullyLocked">₹{{
                                                                        formatAmount(folio.displayAmount) }}</b>
                                                                    <b v-else>Locked</b>
                                                                </p>
                                                                <div class="d-flex align-items-end flex-grow-1">
                                                                    <p v-if="folio.type === 'SIP'"
                                                                        class="mb-0 w-100 text-xs text-end">
                                                                        {{ folio.type }}
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="text-xs">Folio no. {{ folio.folio }}</p>
                                                </div>
                                            </soft-radio>
                                        </div>
                                    </div>
                                    <div class="button-container px-4">
                                        <div class=" d-flex justify-content-center">
                                            <soft-button class="text-primary bg-white border border-primary me-4 w-100"
                                                data-bs-dismiss="modal" @click="resetModal">Cancel</soft-button>
                                            <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary"
                                                type="submit">
                                                Redeem
                                            </soft-button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div v-if="workFlow == 'DETAILS'" class="card-body">
                                <Form role="form text-left" @submit="onSubmit">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <span class="font">NAV applicable for</span>
                                                <div class="d-flex align-items-center flex-grow-1">
                                                    <span class="ms-2 w-100 font text-end">
                                                        <b>{{ currentDate }}</b>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <soft-checkbox id="redeemAll" name="redeemAll" checked="false"
                                            v-model="redeemAll">
                                            <template v-slot:label>
                                                <label class="font-weight-bold">
                                                    Redeem all</label>
                                            </template>
                                        </soft-checkbox>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <span class="font">Enter Units</span>
                                        </div>
                                        <div class="col-7 ps-4">
                                            <div class="input-group mb-1 justify-content-end">
                                                <soft-input id="amount"
                                                    :rules="{ required: true, min_value: minimumRedemptionQty, max_value: maxValue }"
                                                    type="text" placeholder="Units" v-model="units" name="Units"
                                                    :disabled="!redeemAll" @input="onInputChange"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="text-light-mauve font-weight-light mt-5">
                                    <div class="row mb-xl-6 mb-3 pb-3">
                                        <div class="col-12">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <span class="text-xxs me-1">Amount available (Approx)</span>
                                                <i class="fa-solid fa-circle-exclamation fa-xs icon-background-color mt-1"
                                                    :title=infoTitle></i>
                                                <div class="d-flex align-items-center flex-grow-1">
                                                    <span class="ms-2 w-100 text-xs text-end">
                                                        ₹ {{ formattedAmount }}
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" d-flex justify-content-center pt-2">
                                        <soft-button class="text-primary bg-white border border-primary me-4 w-100"
                                            data-bs-dismiss="modal" @click="resetModal">Cancel</soft-button>
                                        <soft-button ref="buttonRef" class="mt-0 ms-1 w-100" color="primary"
                                            type="submit">
                                            Redeem
                                        </soft-button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftCheckbox from "@/views/common/components/SoftCheckbox.vue";
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
import SoftRadio from "@/components/SoftRadio.vue";
import TwoFactorAuth from "@/components/TwoFactorAuthentication.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
    name: "Redemption",
    components: {
        SoftInput,
        SoftButton,
        SoftCheckbox,
        Form,
        SoftRadio,
        TwoFactorAuth,
    },
    props: {
        rowData: {
            type: Object,
            required: true
        },
        fundId: {
            type: String,
            default: "",
            required: true
        }
    },
    data() {
        return {
            units: 0,
            availableAmount: '',
            success: false,
            errorOccurred: false,
            errorMessage: "An error occurred during the transaction. Please try again.",
            currentNav: "",
            redeemAll: "on",
            infoTitle: "The available amount is based on the mutual fund's last working day NAV and may vary if the NAV changes from yesterday.",
            folios: [],
            schemeName: "Axis All Seasons Debt Fund Of Funds Regular Annual Idcw Payout",
            selectedFolioNumber: null,
            folio: false,
            folioNo: "",
            redeemAuth: false,
            orderId: "Order Ref Not Available",
            otp: "",
            workFlow: "FOLIO",
            schemes: [],
            setTimer:'',
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));
        const modal = document.getElementById('redemption_' + this.fundId);
        modal.addEventListener('show.bs.modal', this.resetModal);
        var schemeFolio = this.schemes.filter((c) => c.fundId == this.fundId);
        if (schemeFolio && schemeFolio.length >= 1) {
            this.workFlow = "FOLIO";
        } else {
            this.workFlow = "DETAILS";
        }
        setTimeout(() => {
            const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
            popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl);
            });
        }, 100);
    },
    watch: {
        redeemAll(newVal) {
            if (newVal) {
                this.units = 0;
            } else {
                this.units = this.selectedSchemes.find((c) => c.folio == this.selectedFolioNumber)?.quantity;
            }
        },
    },
    computed: {
        minimumRedemptionQty() {
            return this.selectedSchemes[0]?.minimumRedemptionQty;
        },
        maxmiumRedemptionQty() {
            return this.selectedSchemes[0]?.maximumRedemptionQty;
        },
        maxValue() {
            if (this.maxmiumRedemptionQty == 0) {
                return this.selectedSchemes[0]?.quantity || 0;
            }
            return this.maxmiumRedemptionQty;
        },
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.fundId);
        },
        filteredSchemes() {
            return this.selectedSchemes
            .filter(folio => folio && folio.folio)
                .map(folio => {
                    if (!folio) return; 
                    const lockedCurrentAmount = Math.round(folio.nav * folio.lockInQuantity * 100) * 0.01;
                    const isFullyLocked = folio.currentValue === lockedCurrentAmount;
                    const isPartiallyLocked = lockedCurrentAmount > 0 && lockedCurrentAmount < folio.currentValue;
                    const unlockedAmount = isPartiallyLocked ? folio.currentValue - lockedCurrentAmount : folio.currentValue;
                    const displayAmount = isPartiallyLocked ? unlockedAmount : folio.currentValue;
                        return {
                            ...folio,
                            isFullyLocked,
                            isPartiallyLocked,
                            unlockedAmount,
                            displayAmount,
                        };
                })
                .filter(Boolean)
                .sort((a, b) => b.currentValue - a.currentValue);
        },
        currentDate() {
            const date = new Date();
            const day = date.getDate().toString().padStart(2, '0');
            const months = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'];
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        computedAmount() {
            return (Math.round((this.units * this.selectedSchemes?.[0]?.nav) * 100) * .01).toFixed(2);
        },
        formattedAmount() {
            const amount = this.computedAmount;
            return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
        },
        amountClass() {
            return this.computedAmount !== 0 ? 'text-end' : 'text-end';
        },
        sortedFolios() {
            return this.folios.slice().sort((a, b) => {
                const amountA = parseFloat(a.amount);
                const amountB = parseFloat(b.amount);
                return amountA - amountB;
            });
        },
        getSelectedAmount() {
            const selectedFolio = this.sortedFolios.find(folio => folio.number === this.selectedFolioNumber);
            return selectedFolio ? selectedFolio.amount : 'No folio selected';
        },

    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        redeem() {
            this.workFlow = "DETAILS";
        },
        onSubmit() {

            let selectedFolio = this.selectedSchemes.find(folio => folio.folio === this.selectedFolioNumber);
            if (this.units > selectedFolio?.quantity) {
                this.errorOccurred = true;
                this.workFlow = "ERROR";
                this.errorMessage = "Insufficient quantity in the selected folio. Please choose another folio.";
                return;
            } else {
                this.submitData();
            }
        },
        resetModal() {
            this.workFlow = "FOLIO";
            this.errorOccurred = false;
            this.units = "";
            this.redeemAll = "on";

        },
        retrySubmission() {
            this.errorOccurred = false;
            this.onSubmit();
        },
        submitData() {
            let folioNumber = this.selectedFolioNumber.includes('/')
                ? this.selectedFolioNumber.replaceAll('/', '~-')
                : this.selectedFolioNumber;
            let data = {
                "quantity": this.units,
                "fundId": this.fundId,
                "folioNo": folioNumber,
            };
            var self = this;
            this.$store
                .dispatch("productStore/redemption", data, { root: true })
                .then(function (response) {
                    self.orderId = response.server.data.orderId;
                    self.workFlow = "OTP";
                })
                .catch(function (error) {
                    self.errorOccurred = true;
                    self.workFlow = "ERROR";
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
        handleRedirect() {
            this.workFlow = "DETAILS";
        },
        handleVerificationSuccess(otp) {
            this.otp = otp;
            this.folio = false
            this.redeemAuth = false;
            this.errorOccurred = false;
            this.success = false;
            this.redemption();  // Call the method to submit data
        },
        handleOtpSuccess() {
            this.errorOccurred = false;
            this.success = false;
            this.resentOtp()
        },
        redemption() {
            let data = {
                "orderId": this.orderId,
                "fundId": this.fundId,
                "otp": this.otp,
            };
            var self = this;
            this.$store
                .dispatch("productStore/redemptionConfirmation", data, { root: true })
                .then(function () {
                    self.workFlow = "SUCCESS"
                })
                .catch(function (error) {
                    self.errorOccurred = true;
                    self.workFlow = "ERROR"
                    self.errorMessage = error.server.response.data.message ? error.server.response.data.message : "An error occurred during the transaction. Please try again.";
                });
        },
        resentOtp() {
            let data = {
                "orderId": this.orderId,
                "fundId": this.uniqueNumber,

            };
            var self = this;
            this.$store.dispatch("productStore/resentOtp", data, { root: true })
                .then(function (response) {
                    console.log(response);
                    self.setTimer="resend";
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        redirect() {
            this.$router.push('/orderstatus');
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
        },
        getPopoverContent(folio) {
            if (folio.isFullyLocked) {
                return "This folio is fully locked due to the lock-in period.";
            } else if (folio.isPartiallyLocked) {
                return `Only ₹${this.formatAmount(folio.unlockedAmount)} is available for redemption. The remaining ₹${this.formatAmount(folio.lockedAmount)} is locked.`;
            }
            return "";
        },
        onInputChange(event) {
    const value = event.target.value.replace(/[^0-9]/g, ''); 
    this.units = value;
    event.target.value = value;
  },
    },
};
</script>