<template>
  <div class="card multisteps-form__panel selection-card-height border-radius-xl bg-white shadow-top-bottom"
    data-animation="FadeIn">
    <div class="row">
      <div class="col-12 col-lg-4">
        <LeftPanelView :progressStep="currentStep" :descriptionText="descriptionText" :pageName="pageName" />
      </div>
      <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
        <div class="multisteps-form__content px-md-4">
          <div class="card-body p-0 mb-5 mt-5 px-4">
            <Form role="form text-left" @submit="onSubmit">
              <div class="row">
                <div class="col-12 col-sm-6 mt-sm-2">
                  <label for="income" class="form-label ones-label-size ms-2">Enter the STP Amount</label>
                  <soft-input id="amount"
                  :rules="amountTouched ? { required: true, min_value: minimumInstallmentAmount, max_value: maxValue } : {}" type="text"
                    placeholder="STP Amount" v-model="selectedAmount" name="Amount" class="w-100 w-md-75"
                    @input="onInputChange('amount', $event)" />
                </div>
                <div class="col-12 col-sm-6 col-lg-6 mt-3 mt-sm-2 mb-2 mb-sm-3">
                  <label for="income" class="form-label ones-label-size ms-3">Frequency</label>
                  <SoftSelect v-model="frequency" :options="frequencyOptions" @change="handleFrequencyChange"
                    class="w-100 w-md-75 ms-xl-0 ms-0" placeholder="Select Frequency" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 mt-sm-2">
                  <label for="income" class="form-label ones-label-size ms-3">STP Date</label>
                  <SoftSelect v-if="frequency !== 'DAILY' && frequency !== 'MONTHLY'" v-model="date" rules="required:true|requiredSelect"
                    :options="dateOptions" class="w-100 w-md-75 ms-xl-0 ms-0" placeholder="Select Dates" />
                  <!-- Show SIP Calendar if frequency is DAILY or MONTHLY -->
                  <SipCalender v-if="frequency === 'DAILY' || frequency === 'MONTHLY'" v-model="date"
                    class="w-100 w-md-75" :type="type" :frequency="calendarFrequency" />
                </div>
                <div class="col-12 col-sm-6 col-lg-6 mt-3 mt-sm-2 mb-2 mb-sm-3">
                  <label for="income" class="form-label ones-label-size ms-3">Installment</label>
                  <soft-input id="installment"
                    :rules="amountTouched ? { required: true, min_value: minimumInstallment, max_value: maxInstallment }  : {}" type="text"
                    :placeholder="frequency === 'DAILY' ? 'Installment is 0' : 'No of Installment'"
                    v-model="installment" name="Installment" class="w-100 w-md-75" :disabled="frequency === 'DAILY'"
                    @input="onInputChange('installment', $event)" />
                </div>
              </div>
              <hr class="text-light-mauve font-weight-light">
              <div class="row mt-3 pb-2">
                <div class="col-12">
                  <div class="d-flex justify-content-between align-items-center mb-2">
                    <span class="text-xs me-1"> Total Transfer Amount (Approx)</span>
                    <div class="d-flex align-items-center flex-grow-1">
                      <span class="ms-2 w-100 text-xs text-end font-weight-bolder">
                        ₹ {{ formattedAmount }}
                      </span>

                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center pb-4"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
              </div>
              <div>
                <div class="d-flex justify-content-between pt-2">
                  <soft-button @click="previous"
                    class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left"
                    type="button">
                    <i class="fas fa-chevron-left" aria-hidden="true"></i>
                    <span class="ms-2">previous</span>
                  </soft-button>
                  <soft-button ref="buttonRef" class="bg-primary icon-move-right" type="submit">
                    Next
                    <i class="fas fa-chevron-right" aria-hidden="true"></i>
                  </soft-button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { Form } from "vee-validate";
import SipCalender from "@/components/SipCalender.vue";
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
import SoftSelect from "@/views/common/components/SoftSelect.vue";

export default {
  name: "StpAmount",
  components: {
    SoftButton,
    SoftInput,
    Form,
    SipCalender,
    LeftPanelView,
    SoftSelect,
  },
  data() {
    return {
      schemes: [],
      selectedFolioNumber: null,
      switchAll: false,
      selectedAmount: "",
      date: "",
      installment: "",
      frequency: "",
      error: "",
      isSuccess: true,
      pageName: "StpAmount",
      descriptionText: 'Please provide the amount along with all other required information, needed to proceed with the confirmation process.',
      dateOptions: [],
      frequencyOptions: [{ value: "", text: "Select Frequency", disabled: true }],
      type: 'STP',
      amountTouched: false,
    }
  },
  mounted() {
    this.schemes = JSON.parse(sessionStorage.getItem('schemes'));
    this.updateDateOptions();
    this.updateFrequencyOptions();

  },
  props: {
    folioData: {
      type: Object,
      default: () => ({})
    },
    currentStep: Number,
  },
  computed: {
    calendarFrequency() {
      if (this.frequency === "DAILY") {
        return "day";
      }
      return this.frequency.toLowerCase();
    },
    stpFrequencyData() {
      return this.selectedSchemes[0]?.stpFrequencies?.[this.frequency] || {};
    },
    totalAmount() {
      const amount = parseFloat(this.selectedAmount) || 0;
      const installment = parseInt(this.installment) || 0;
      return installment === 0 ? amount : amount * installment;
    },
    calculatedInstallments() {
      if (this.selectedAmount > 0 && this.folioData?.amount) {
        const installments = Math.floor(this.folioData.amount / this.selectedAmount);
        return installments > 0 ? installments : 0;
      }
      return 0;
    },
    calendarType() {
      return this.getSwitchFund.type || 'SIP';
    },
    formattedDate() {
      const now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = this.date;
      if (this.frequency === 'DAILY' && this.date) {
        const selectedDate = parseInt(this.date);
        const today = now.getDate();

        if (selectedDate < today) {
          month += 1;
          if (month > 12) {
            month = 1;
            year += 1;
          }
        }
      }
      if (this.frequency === "WEEKLY" && this.date) {
        return this.getNextDateForDay(this.date); // Already in DD/MM/YYYY format
      }
      if (this.frequency === "MONTHLY" && this.date) {
        const currentDay = now.getDate();
        if (this.date <= currentDay) {
          month += 1;
        }
        if (month > 12) {
          month = 1;
          year++;
        }
      }
      const dayFormatted = day.toString().padStart(2, '0');
      const monthFormatted = month.toString().padStart(2, '0');
      return `${dayFormatted}/${monthFormatted}/${year}`;
    },
    highestMinInstallmentAmount() {
      const stpFrequencies = this.selectedSchemes[0]?.stpFrequencies || {};
      return Math.max(
        ...Object.values(stpFrequencies).map(frequency => frequency.astpOutMinInstallmentAmount || 0)
      );
    },
    minimumInstallmentAmount() {
      return this.frequency ? this.stpFrequencyData.astpOutMinInstallmentAmount || 0 : this.highestMinInstallmentAmount;
    },
    highestMaxInstallmentAmount() {
      const stpFrequencies = this.selectedSchemes[0]?.stpFrequencies || {};
      return Math.max(
        ...Object.values(stpFrequencies).map(frequency => frequency.astpOutMaxInstallmentAmount || 0)
      );
    },
    maximumInstallmentAmount() {
      return this.frequency ? this.stpFrequencyData.astpOutMaxInstallmentAmount || 0 : this.highestMaxInstallmentAmount;;
    },
    maxValue() {
      if (this.folioData?.amount >= this.maximumInstallmentAmount) {
        return this.maximumInstallmentAmount;
      }
      return this.folioData?.amount;
    },
    ...mapGetters("orderStore", ["getSwitchFund"]),
    selectedSchemes() {
      return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
    },
    formattedAmount() {
      if (!this.totalAmount) return "0.00";
      const amount = parseFloat(this.totalAmount);
      return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
    },
    astpMultiplier() {
      return this.stpFrequencyData.outMultiplierAmt || 1;
    },
    isAmountMultipleOfMultiplier() {
      const amount = parseFloat(this.selectedAmount);
      return amount % this.astpMultiplier === 0;
    },
    minimumInstallment() {
      if (this.frequency === 'DAILY') {
        return 0;
      }
      return this.stpFrequencyData.astpMinInstallment || 1;
    },
    maximumInstallment() {
      return this.stpFrequencyData.astpMaxInstallment || 1;
    },
    maxInstallment() {
      const amount = parseFloat(this.selectedAmount) || 0;
      const totalAmount = parseFloat(this.folioData?.amount) || 0;
      const calculatedInstallments = amount > 0 ? Math.floor(totalAmount / amount) : 0;
      return calculatedInstallments < this.maximumInstallment ? this.maximumInstallment : calculatedInstallments;
    },
    minimumAstpGap() {
      return this.selectedSchemes[0]?.astpMinimumGap || 0;
    },
    maximumAstpGap() {
      return this.selectedSchemes[0]?.astpMaximumGap || 60;
    },
    isValidDate() {
      const now = new Date();
      const selectedDay = 18;
      const selectedDate = new Date(now.getFullYear(), now.getMonth() + 1, selectedDay);
      const minGapDate = new Date(now.getTime());
      const maxGapDate = new Date(now.getTime() + this.maximumAstpGap * 24 * 60 * 60 * 1000);
      return selectedDate >= minGapDate && selectedDate <= maxGapDate;
    }
  },
  watch: {
    currentStep(newStep) {
        if (newStep === 1) {
          this.updateDateOptions();
          this.updateFrequencyOptions(); 
        }
    }
},
  methods: {
    updateFrequencyOptions() {
      const stpFrequencies = this.selectedSchemes[0]?.stpFrequencies || {};
      const availableFrequencies = Object.keys(stpFrequencies);

      if (availableFrequencies.length === 0) {
        this.frequencyOptions = [{ value: "", text: "Select Frequency", disabled: true }];
      } else {
        this.frequencyOptions = [
          { value: "", text: "Select Frequency", disabled: true },
          ...availableFrequencies.map(freq => ({
            value: freq,
            text: freq.charAt(0).toUpperCase() + freq.slice(1).toLowerCase(),
          }))
        ];
      }
    },
    handleFrequencyChange() {
      this.updateDateOptions();
      this.resetDateField();
      if (this.frequency === "DAILY") {
        this.installment = 0;
      }
    },
    previous() {
      this.selectedAmount = "";
      this.date = "";
      this.installment = "";
      this.frequency = "";
      this.isSuccess = true;
      this.$emit('update', null);
      this.$parent.prevStep();
      this.amountTouched = false;
      this.frequencyOptions= [{ value: "", text: "Select Frequency", disabled: true }];
    },
    onSubmit() {
      this.amountTouched="true";
      if (!this.frequency) {
        this.isSuccess = false;
        this.error = "Please select a frequency.";
        return;
      }
      if (!this.date) {
        this.isSuccess = false;
        this.error = "Please select a start date .";
        return;
      }
      if (!this.isValidDate) {
        this.isSuccess = false;
        this.error = `The selected date must be between ${this.minimumAstpGap} and ${this.maximumAstpGap} days from the current date.`;
        return;
      }
      if (!this.isAmountMultipleOfMultiplier) {
        this.isSuccess = false;
        this.error = `The amount should be a multiple of ${this.astpMultiplier}.`;
        return;
      }
      this.$emit('update', {
        frequency: this.frequency,
        amount: this.selectedAmount,
        date: this.formattedDate,
        installment: this.installment,
        totalAmount: this.totalAmount,
      });
      this.isSuccess = true;
      this.$parent.nextStep();
    },
    updateDateOptions() {
      if (this.frequency === "WEEKLY") {
        this.dateOptions = [
          { value: "Select Date", text: "Select Date", disabled: true },
          { text: "Monday", value: "Monday" },
          { text: "Tuesday", value: "Tuesday" },
          { text: "Wednesday", value: "Wednesday" },
          { text: "Thursday", value: "Thursday" },
          { text: "Friday", value: "Friday" },
        ];
      } else {
        this.dateOptions = [
          { value: "", text: "Select Date", disabled: true },
        ];
      }
    },
    getNextDateForDay(day) {
      const daysOfWeek = { "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5 };
      const today = new Date();
      const dayIndex = daysOfWeek[day];
      const currentDay = today.getDay();
      let diff = dayIndex - currentDay;

      if (diff <= 0) {
        diff += 7;
      }

      const nextDate = new Date(today.getTime() + diff * 24 * 60 * 60 * 1000);

      // Formatting date with zero-padding
      const dayFormatted = nextDate.getDate().toString().padStart(2, '0');
      const monthFormatted = (nextDate.getMonth() + 1).toString().padStart(2, '0'); // Adjust for 0-index month
      const year = nextDate.getFullYear();

      return `${dayFormatted}/${monthFormatted}/${year}`;
    },
    resetDateField() {
      this.date = "";
    },
    onInputChange(field, event) {
      const value = event.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
      if (field === 'amount') {
        this.selectedAmount = value;
      } else if (field === 'installment') {
        this.installment = value;
      }
      event.target.value = value;
      if (!this.amountTouched && value !== "") {
        this.amountTouched = true;
      }
    },
  }
};
</script>