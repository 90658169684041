<template>
    <div class="pt-5 mt-1 pt-md-6 mt-md-0 pt-md-6 custom-container">
        <SelectionBand v-if="activeStep < 2" class="fixed-banner" :folio-data="folioData"
            :selectedAmount="selectedAmount" :schemeData="toSchemes" />
    </div>
    <div class="container-fluid pt-md-8 pt-11">
        <div class="row">
            <div class="col-12">
                <div class="multisteps-form mb-5">
                    <div class="row">
                        <div :class="['col-12 col-xl-10 m-auto']">
                            <form :class="['multisteps-form__form mb-5 mx-md-4 mx-xl-5']">
                                <Folio :class="activeStep === 0 ? activeClass : ''" @update-folio="handleFolioUpdate" :currentStep="activeStep" />
                                <SwpAmount :class="activeStep === 1 ? activeClass : ''" :folioData="folioData"
                                    @update="handleUpdate" :currentStep="activeStep" />
                                <Review :class="activeStep === 2 ? activeClass : ''" :folioData="folioData"
                                    :swpData="swpData" @order-id="updateOrderId" :currentStep="activeStep" />
                                <SwpAuthentication :class="activeStep === 3 ? activeClass : ''" @update="handleWorkFlow"
                                    :currentStep="activeStep" :orderId="orderId" :folioData="folioData" />
                                <SwpConfirm :class="activeStep === 4 ? activeClass : ''" :message="workFlow"
                                :swpData="swpData" :orderId="orderId" :currentStep="activeStep" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Folio from ".././stp/components/stpFolios.vue";
import SelectionBand from "@/views/common/components/SelectionBand.vue";
import SwpAmount from "./components/SwpAmount.vue";
import Review from "./components/SwpReview.vue";
import SwpAuthentication from "../switch/components/SwitchAuthentication.vue";
import SwpConfirm from "../switch/components/SwitchConfirmation.vue";
import { mapGetters } from "vuex";

export default {
    name: "SystematicWithdrawalPlan",
    components: {
        Folio,
        SelectionBand,
        SwpAmount,
        Review,
        SwpAuthentication,
        SwpConfirm,
    },
    data() {
        return {
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 5,
            folioData: null,
            schemes: [],
            swpData: null,
            toSchemes: null,
            workFlow: [],
            switchFund: [],
            orderId: "",
        };
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
    },
    methods: {
        nextStep() {
            if (this.activeStep < this.formSteps) {
                this.activeStep += 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            } else {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            }
        },
        prevStep() {
            if (this.activeStep > 0) {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            }
        },
        handleFolioUpdate(data) {
            this.folioData = data;
        },
        handleUpdate(data) {
            this.swpData = data;
        },
        handleSelection(data) {
            this.toSchemes = data;
        },
        handleWorkFlow(data) {
            this.workFlow = data;
        },
        updateOrderId(data) {
            this.orderId = data;
        }
    },
};
</script>