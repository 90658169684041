<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-column flex-md-row">
      <div class="col p-0">
        <div class="mt-6 page-header min-height-160 border-radius-xl orderStatus-headerimage">
        </div>
        <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6 mb-md-5">
          <div class="container-fluid position-relative">
            <div class="row min-vh-50 w-100">
              <div class="col-12 p-0 pb-3 z-index-sticky text-black">
                <div class="p-0">
                  <h4 class="mb-2 ms-md-3">Order Summary
                    <i class="fa fa-info-circle text-xs text-secondary ms-2 cursor-pointer" data-bs-toggle="popover"
                      data-bs-trigger="hover" data-bs-placement="right"
                      data-bs-content="In systematic plans, the subsequent orders may fluctuate by 4 to 5 days."
                      aria-label="Order Summary Information"></i>
                  </h4>
                </div>
                <div v-if="orders.length === 0" class="pt-9 container-fluid me-0 position-relative text-center">
                  <h6>Orders are not available at the moment, please explore our latest mutual funds. <router-link
                      to="/explore" class="text-primary"><u>Explore</u></router-link></h6>
                </div>
                <div v-else class="pt-4 mb-10 container-fluid me-0 position-relative px-0">
                  <div class="table-responsive pt-3">
                    <table id="datatable-search" class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th class="text-uppercase table-width text-center text-secondary text-xxs font-weight-bolder opacity-7">
                            Order Id
                          </th>
                          <th
                            class="text-uppercase header_width text-center text-secondary text-xxs font-weight-bolder opacity-7">
                            Scheme Name
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                            Type
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                            Amount/Unit
                          </th>
                          <th
                            class="text-uppercase table-width text-secondary text-xxs font-weight-bolder opacity-7 table-width text-center">
                            placed On
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                            Status
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                            remarks
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import moment from "moment-timezone";
export default {
  name: "ViewTable",
  data() {
    return {
      dataTab: null,
      direction: "DESC",
      pageNo: 1,
      orders: [],
    };
  },
  mounted() {
    this.Filter();
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
  },
  beforeUnmount() {
    if (this.dataTab) {
      this.dataTab.destroy();
      this.dataTab = null;
    }
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    Filter() {
      this.setTable("1", "10", "id", "DESC");
    },
    setTable(pageNo, pageSize, sortBy, direction, filter = null) {
      if (this.dataTab) {
        this.dataTab.destroy();
        this.dataTab = null;
      }
      let data = {
        "pageNo": pageNo - 1,
        "pageSize": pageSize,
        "sortBy": sortBy,
        "direction": direction,
        "filter": filter,
      };
      var self = this;
      this.$store
        .dispatch("productStore/getStatus", data, { root: true })
        .then(response => {
          let totalElements = response.server.data.totalElements;
          let currentPage = response.server.data.currentPage;
          let perPage = response.server.data.perPage;
          let runningIndex = currentPage * perPage;
          let schemeDetails = response.server.data.orders;
          let tbody = [];
          if (schemeDetails.length === 0) {
            self.orders = [];
            return;
          } else {
            self.orders = [...schemeDetails];
          }
          if (schemeDetails.length !== 0) {
            for (let i = 0; i < totalElements; i++) {
              var prependValue = "!";
              var appendValue = "~";
              if (direction == "DESC") {
                prependValue = "~";
                appendValue = "!";
              }
              if (i < runningIndex) {
                tbody.push([prependValue, '', '', '', '', '']);
              } else if (i >= (runningIndex + perPage)) {
                tbody.push([appendValue, '', '', '', '', '']);
              } else {
                tbody.push(["someThing", "someThing", "someThing", "someThing", "someThing", "someThing"]);
              }
            }
            let schemeMap = new Map();
            let uniqueFundIds = new Set();
            for (let fund of schemeDetails) {
              uniqueFundIds.add(fund.fundId);
            }

            for (let fundId of uniqueFundIds) {
              let data = {
                "fundId": fundId,
              };
              self.$store
                .dispatch("schemeStore/getSchemeDetails", data, { root: true })
                .then(function (response) {
                  let schemeName = response.server.data.schemeName;
                  let schemeType = response.server.data.schemeType;
                  schemeMap.set(fundId, schemeName + " (" + schemeType + ")");
                  if (schemeMap.size === uniqueFundIds.size) {
                    self.processSchemeDetails(schemeDetails, schemeMap, runningIndex, tbody, pageNo, direction)
                  }
                }).catch(error => {
                  schemeMap.set(fundId, "Unknown");
                  if (schemeMap.size === uniqueFundIds.size) {
                    self.processSchemeDetails(schemeDetails, schemeMap, runningIndex, tbody, pageNo, direction)
                  }
                  console.log(error)
                });
            }
          }
        }).catch(error => {
          console.log(error)
        });
    },
    processSchemeDetails(schemeDetails, schemeMap, runningIndex, tbody, pageNo, direction) {
      for (let item of schemeDetails) {
        switch (item.orderType) {
          case "LUMP_SUM":
            item.orderType = "Lumpsum";
            break;
          case "SIP":
            item.orderType = "Sip";
            break;
          case "STP":
            item.orderType = "Stp";
            break;
          case "SWP":
            item.orderType = "Swp";
            break;
          case "SWITCH":
            item.orderType = "Switch";
            break;
          case "REDEEM":
            item.orderType = "Redeem";
            break;
          default:
            item.orderType = "Unknown"; // Default case, if no match
        }
        let status = `${item.status}`;
        tbody[runningIndex] = [item.orderId, schemeMap.get(item.fundId), item.orderType, item.amount + "," + item.quantity, item.placedOn, status, item.remarks];
        runningIndex++;
      }
      this.updateTable(tbody, this, pageNo, direction.toLowerCase());
    },
    applyPagination(pageNo) {
      this.pageNo = pageNo;
      this.setTable(pageNo, "10", "id", this.direction, null);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    updateTable(tbody, self, pageNo, direction) {

      if (self.dataTab != null) {
        self.dataTab.destroy();
      }
      self.dataTab = new DataTable("#datatable-search", {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        columns: [
          {
            select: 0, sort: direction, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 0) {
              const items = data.split(',');
              var tableData = `<div class="text-wrap text-center" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                var id = items[0];
                if (id < 10) {
                  //To solve the sorting issue
                  id = "0" + id;
                }
                tableData += `<div><span class="text-sm">OS-${id}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 1, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 1) {
              const items = data.split(',');
              var tableData = `<div class="text-wrap" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                tableData += `<div><span class="text-sm font-weight-bold">${self.toTitleCase(items[0])}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 2, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 2) {
              const items = data.split(',');
              var tableData = `<div class="text-wrap" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                tableData += `<div class="text-center"><span class="text-xs badge badge-secondary"><i class="fa-solid fa-tags me-2"></i>${items[0]}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 3, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 3) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const items = data.split(',');
              const amount = items[0];
              const units = items[1];
              let displayValue;
              if (amount === "undefined" && units !== "undefined") {
                displayValue = `${ Number(units).toLocaleString('en', options)}`;
              } else if (amount !== "undefined") {
                displayValue = `₹ ${ Number(amount).toLocaleString('en', options)}`; 
              } else if (units ==="undefined" && amount === "undefined") {
                displayValue = `₹ 0.00`; 
              }

              return `<div class="text-sm font-weight-normal text-capitalize text-center" name='td_${cellIndex}' data-row='${rowIndex}'><span>${displayValue}</span></div>`;
            }
          },
          {
            select: 4, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 4) {
              if (!data) return '';
              const serverDateTime = moment.utc(data);
              const clientTimeZone = moment.tz.guess(); 
              const localDateTime = serverDateTime.clone().tz(clientTimeZone);
              const formattedDate = localDateTime.format("DD-MM-YYYY");
              const formattedTime = localDateTime.format("hh:mm A");

              var tableData = `<div class="text-wrap text-center" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (formattedDate) {
                tableData += `<div><span class="text-sm font-weight-bold">${formattedDate}</span></div>`;
              }
              if (formattedTime) {
                tableData += `<div><span class="text-sm font-weight">${formattedTime}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 5, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 5) {
              const items = data.split(',');
              var tableData = `<div class="text-wrap text-center" name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (items[0]) {
                let badgeClass = 'badge badge-status';
                let displayText = self.toTitleCase(items[0]);
                switch (items[0]) {
                  case 'COMPLETED':
                    badgeClass = 'badge badge-success badge-status';
                    break;
                  case 'FAILED':
                    badgeClass = 'badge badge-danger badge-status';
                    break;
                  case 'INITIATED':
                    badgeClass = 'badge badge-secondary badge-status';
                    break;
                  case 'IN_PROGRESS':
                    badgeClass = 'badge badge-info badge-status text-dark';
                    displayText = 'In Progress';
                    break;
                  case 'AUTHENTICATED':
                    badgeClass = 'badge badge-info badge-status';
                    break;
                  default:
                    badgeClass = 'badge badge-secondary badge-status';
                }

                tableData += `<div><span class="${badgeClass} text-center">${displayText}</span></div>`;
              }
              if (items[1]) {
                tableData += `<div><span class="text-xxs font-weight-bold">${self.toTitleCase(items[1])}</span></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
          {
            select: 6, sortable: false,
            render: function (data, td, rowIndex, cellIndex = 1) {
              const remark = (!data || data === "undefined") ? "Redemption Authentication Not Received" : self.toTitleCase(data);
              var tableData = `<div class="text-center  " name='td_${cellIndex}' data-row='${rowIndex}'>`;
              if (remark) {
                tableData += `<div><i class="fa fa-info text-xs text-info" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="left" data-bs-content="${remark}"></i></div>`;
              }
              tableData += `</div>`;
              return tableData;
            }
          },
        ],
        data: {
          "data": tbody
        }
      });
      self.dataTab.page(Number(pageNo ? pageNo : 1));
      self.dataTab.on('datatable.sort', function (column, direction) {
        self.applySort(column, direction.toUpperCase());
      });
      self.dataTab.on('datatable.page', function (page) {
        self.applyPagination(page);
      });
      self.dataTab.on('datatable.init', function () {
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map(function (popoverTriggerEl) {
          return new bootstrap.Popover(popoverTriggerEl)
        });
      });
      setTimeout(() => {
        document.querySelectorAll('.dataTable-pagination .ellipsis').forEach((ellipsis) => {
          ellipsis.addEventListener('click', (event) => {
            event.preventDefault();
          });
        });
      },);
    },
  }
};
</script>