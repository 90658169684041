<template>
  <Header :image="Header_Image" title="Invest in Your Dreams with Mutual Funds" :description="{
    text: 'Discover a simplified, transparent, and effective way to grow your Wealth. Join thousands who trust us to navigate their Investment journey.',
    class: 'pe-md-5 me-md-5 opacity-8',
  }" mask="mask bg-gradient-dark opacity-2">
    <div class="buttons">
      <router-link v-slot="{ navigate }" to="/explore" custom>
        <SoftButton class="mt-4 mb-3 bg-primary" role="link" @click="navigate">Explore </SoftButton>
      </router-link>
    </div>
  </Header>
  <div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n3 mt-md-n6 mb-4">
    <AboutUs />
    <LandingpageCards />
    <LandingpageNFO />
    <div class="container justify-content-center ps-md-8">
      <div class="row">
        <h2 class="text-center">Contact Us</h2>
        <div class="col-md-6 mt-4 mb-3">
          <p class="description">You can contact us with anything related to our Products. We'll get in touch with
            you as soon as possible.<br><br>
          </p>
          <div class="form-group label-floating">
            <label class="control-label">Your name</label>
            <input id="nameInput" type="text" name="name" class="form-control">
          </div>
          <div class="form-group label-floating">
            <label class="control-label">Email address</label>
            <input id="emailInput" type="email" name="email" class="form-control" />
          </div>
          <div class="form-group label-floating">
            <label class="control-label">Phone</label>
            <input id="phoneInput" type="text" name="phone" class="form-control" maxlength="10" />
          </div>
          <div class="form-group label-floating">
            <label class="control-label">Your message</label>
            <textarea id="messageInput" name="message" class="form-control" rows="6"></textarea>
          </div>
          <div class="justify-content-center py-3  ">
            <small id="sub-error" class="text-danger text-center " style="display: none;"> please provide valid details</small>
          </div>
          <div class=" text-center py-3">
            <button id="subscribeBtn" type="button" class="btn btn-primary btn-raised btn-round "> Contact Us</button>
          </div>
        </div>
        <div class="col-md-6 col-md-offset-2 mt-8  ">
          <div class="info info-horizontal">
            <div class="icon icon-primary me-3 mt-1">
              <i class="ni ni-pin-3 text-primary h4 ms-3"></i>
            </div>
            <div class="description">
              <h4 class="info-title ms-4">Find us at the office</h4>
              <p class="ms-4"> Moneytrail Securities Private Limited,<br>
                Shop No. 1130/76/1,City Centre,<br>
                Thrissur, KL-680001 IN.
              </p>
            </div>
          </div>
          <div class="info info-horizontal mt-3">
            <div class="icon icon-primary  me-3 mt-1">
              <i class="fa-solid fa-phone text-primary h4 fa-xl ms-3"></i>
            </div>
            <div class="description">
              <h4 class="info-title ms-4">Give us a ring</h4>
              <p class="ms-4 pe-auto mb-0" onclick="window.location = 'tel:+91 9895 204 233'"> +91 9895 204 233
              </p>
            </div>
          </div>
          <div class="info info-horizontal mt-4">
            <div class="icon icon-primary  me-3 mt-1">
              <i class="ni ni-single-02 text-primary h4 ms-3"></i>
            </div>
            <div class="description">
              <h4 class="info-title ms-4">Email</h4>
              <p class="ms-4 pe-auto mb-0" onclick="window.location = 'mailto:support@ones.money'"> support@ones.money</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast></Toast>
  <Modal></Modal>
</template>
<script>
import Header from "@/components/Header.vue";
import LandingpageNFO from "@/components/LandingpageNfos.vue";
import AboutUs from "@/components/LandingAboutUs.vue";
import LandingpageCards from "../common/components/LandingpageCards.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/views/common/components/SoftButton.vue";
import Modal from "@/components/Modal.vue"
import Toast from "@/components/Toasts.vue"
import HeaderImage_home from "@/assets/img/HeaderImage_Home.jpg";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "OnesHome",
  components: {
    LandingpageNFO,
    AboutUs,
    Header,
    LandingpageCards,
    SoftButton,
    Modal,
    Toast,
  },
  data() {
    return {
      emailInput: '',
      nameInput: '',
      phoneInput: '',
      messageInput: '',
      subscribeBtn: null,
      Header_Image: HeaderImage_home,
    };
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    setNavPills();
    this.nameInput = document.querySelector('input[name="name"]');
    this.emailInput = document.querySelector('input[name="email"]');
    this.phoneInput = document.querySelector('input[name="phone"]');
    this.messageInput = document.querySelector('textarea[name="message"]');
    this.subscribeBtn = document.getElementById('subscribeBtn');
    this.subscribeBtn.addEventListener("click", this.subscribeHandler);
  },

  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },
  methods: {
    subscribeHandler(e) {
      e.preventDefault();
      var name = this.nameInput.value;
      var email = this.emailInput.value;
      var phone = this.phoneInput.value;
      var messagetext = this.messageInput.value;
      var toastElement = document.getElementById('liveToast');

      if (name && email && messagetext && /^[\w.-]+(?:\.[\w.-]+)*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email) && /^\d{10}$/.test(phone)) {
        var message = "Email: " + (email || "N/A") + " | " +
          "Name: " + (name || "N/A") + " | " +
          "Messagetext: " + (messagetext || "N/A") + " | " +
          "Phone: " + (phone || "N/A");
        var url = 'https://ones.money/message/' + encodeURIComponent(message);
        var oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.send();

        document.getElementById('sub-error').style.display = "none";

        var toast = new bootstrap.Toast(toastElement);
        toast.show();
        ;

        this.emailInput.value = '';
        this.emailInput.disabled = true;
        this.nameInput.value = '';
        this.nameInput.disabled = true;
        this.phoneInput.value = '';
        this.phoneInput.disabled = true;
        this.messageInput.value = '';
        this.messageInput.disabled = true;
        this.subscribeBtn.disabled = true;
      } else {
        document.getElementById('sub-error').style.display = "block";
      }
    }
  },
};
</script>
