<template>
    <div class="custom-bg-gradient-primary text-white d-flex flex-column px-0 selection-card-height border-lg-radius-start h-100">
        <div class="px-3 pt-3">
            <button :class="{ invisible: showPortfolioButton }"
                class="btn btn-link text-white d-flex align-items-center btn-icon-only btn-rounded icon-move-left"
                @click.prevent="openExitModal">
                <i class="fas fa-chevron-left fa-2x" aria-hidden="true"></i>
                <span class="ms-2">Portfolio</span>
            </button>
            <div class="text-center">
                <h5 class="font-weight-bold text-white stepTitle">{{ stepTitle }}</h5>
                <p class="text-xs text-info">STEP {{ progressStep + 1 }} / {{ totalSteps }}</p>
            </div>
            <div class="mt-7 px-5 px-md-4 px-xl-6 content-height">
                <p class="mt-4 text-justify text-xs font-italic">&quot;{{ descriptionText }}&quot;</p>
            </div>
        </div>
        <div class="progress-container w-100 px-3 pb-3 mt-7">
            <div class="segmented-progress-bar">
                <div v-for="step in totalSteps" :key="step" class="progress-segment"
                    :class="{ 'active': (step - 1) <= progressStep }"></div>
            </div>
        </div>
        <div class="text-end mb-2 mt-3 me-3 text-xs">
            <a href="#" class="text-info text-decoration-underline" @click.prevent="openHelp" :title="infoTitle"><i
                    class="fa-solid fa-circle-question fa-lg"></i></a>
        </div>
    </div>
    <ExitModal @redirect="redirectToPortfolio" :currentStep="progressStep" :name="pageName" />
    <modal :name="pageName"></modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import ExitModal from "@/views/common/components/ExitModal.vue";
import { mapGetters } from "vuex";
export default {
    name: "LeftPanelView",
    components: {
        Modal,
        ExitModal,
    },
    props: {
        descriptionText: {
            type: String,
            default: "",
        },
        isRTL: {
            type: Boolean,
            default: false
        },
        progressStep: {
            type: Number,
            default: 1,
        },
        pageName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            infoTitle: "Need Help?",
        };
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        stepTitle() {
            if (this.getSwitchFund.type === 'SWP') {
                return 'Systematic Withdrawal Plan';
            } else if (this.getSwitchFund.type === 'STP') {
                return 'Systematic Transfer Plan';
            } else {
                return 'Switch Funds';
            }
        },
        totalSteps() {
            return this.getSwitchFund.type === 'SWP' ? 5 : 6;
        },
        showPortfolioButton() {
            return (
                (this.getSwitchFund.type === 'SWP' && this.progressStep === 4) ||
                ((this.getSwitchFund.type === 'STP' || this.getSwitchFund.type === 'SWITCH') && this.progressStep === 5)
            );
        }
    },
    methods: {
        redirectToPortfolio() {
            window.location.href = '#/portfolio';
        },
        openExitModal() {
            const modalElement = document.getElementById(`exitModal${this.pageName}`);
            if (modalElement) {
                const modal = new bootstrap.Modal(modalElement);
                modal.show();
            }
        },
        openHelp() {
            const modalElement = document.getElementById(`modal${this.pageName}`);
            if (modalElement) {
                const modal = new bootstrap.Modal(modalElement);
                modal.show();
            }
        }
    }
};
</script>