<template>
    <div class="modal fade" :id="modalId" tabindex="-1"  role="dialog" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 d-flex justify-content-start">
            <i class="fa-solid fa-question fa-2x text-primary"></i><h5 class="ms-2 mt-3"><b>Confirm Exit..!!!</b></h5>
          </div>
          <div class="modal-body text-center">
            <div class="card card-plain">
              You are about to exit this page. Are you sure you want to proceed?
              <div class="text-center justify-content-between mt-5">
                <soft-button type="button" class="btn  btn-outline-primary bg-white text-primary w-25 me-3 px-0" data-bs-dismiss="modal" @click="closeModal">Cancel</soft-button>
                <soft-button type="button" class="btn bg-primary text-white w-25" @click.prevent="redirectToPortfolio">Ok</soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ExitModal",
    props: {
        currentStep: {
            type: Number,
            default: 1 
        },
        name:{
          type:String,
          default:"",
        }
    },
    data() {
    return {
      modalId: `exitModal${this.name}`
    };
  },
  watch: {
    name(newName) {
      this.updateModalId(newName);
    }
  },
  mounted() {
    this.updateModalId(this.name);
  },
  methods: {
    updateModalId(newName) {
      this.modalId = `exitModal${newName}`;
    },
    redirectToPortfolio() {
      this.$emit("redirect");
    }
  }
};
</script>