<template>
    <div class="card multisteps-form__panel selection-card-height border-radius-xl bg-white shadow-top-bottom"
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :descriptionText="descriptionText" :pageName="pageName" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content px-4 mt-3">
                    <div class="container position-relative">
                        <div class="row">
                            <div class="col-12 d-flex flex-column mb-1">
                                <div class="d-flex align-items-center mt-3 pe-4">
                                    <input type="text" class="form-control form-control-sm py-0" v-model="fiterText"
                                        placeholder="Search schemes" />
                                    <soft-button color="primary" variant="outline" size="sm"
                                        class="ms-1 py-1 px-3 text-xs" type="button"
                                        @click="applyFilter()">Search</soft-button>
                                </div>
                                <hr class="my-4" />
                                <div class="items-container scroll-container flex-grow-1">
                                    <div class="scrollable-container-card h-100 position-relative"
                                        @scroll="handleScroll">
                                        <div class="row">
                                            <div v-if="schemeDetails.length === 0"
                                                class="placeholder-content text-center">
                                                <p class="text-muted mt-7 mb-3">
                                                    No schemes found. Please search using the input field.
                                                </p>
                                            </div>
                                            <div v-else class="row">
                                                <div v-for="(item, index) in schemeDetails" :key="item.uniqueNo"
                                                    class="col-12">
                                                    <div class="d-flex justify-content-between align-items-center scheme-info"
                                                        @click.prevent="!item.disabled && selectScheme(item.uniqueNo,index)"
                                                        :class="{ 'cursor-pointer': !item.disabled, 'text-muted': item.disabled, 'bg-light-grey': item.disabled }">
                                                        <div class="d-flex align-items-center">
                                                            <div class="scheme-info d-flex align-items-center"
                                                                :class="{ 'text-muted': item.disabled, 'bg-light-grey disabled': item.disabled }">
                                                                <span
                                                                    class="text-xxs me-3 font-weight-bolder span-font-size"
                                                                    :class="{ 'text-muted': item.disabled }">
                                                                    {{ item.schemeName }}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <i v-if="item.disabled"
                                                                    class="fa-solid fa-circle-exclamation fa-xs icon-background-color mt-1 cursor-pointer"
                                                                    :title="infoTitle"></i>
                                                            </div>
                                                        </div>
                                                        <button v-if="!item.disabled"
                                                            class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
                                                            @click.stop.prevent="selectScheme(item.uniqueNo)">
                                                            <i class="ni text-primary"
                                                                :class="isRTL ? 'ni-bold-left' : 'ni-bold-right'"
                                                                aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                    <hr class="horizontal-line m-0" />
                                                </div>
                                            </div>
                                            <div v-if="isLoading" class="text-center my-3">
                                                <VueSpinnerDots size="50" color="#C71C4E" />
                                            </div>
                                            <div v-if="isEndOfList && !isLoading" class="text-center my-3">
                                                <span class="text-xs">You've reached the end of the list.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center px-4 py-3">
                    <soft-button ref="goBackButton"
                        class="btn btn-link text-primary d-flex align-items-center btn-icon-only bg-white icon-move-left"
                        @click.prevent="previous">
                        <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        <span class="ms-2">previous</span>
                    </soft-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueSpinnerDots } from 'vue3-spinners';
import SoftButton from "@/views/common/components/SoftButton.vue";
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
export default {
    name: "Folios",
    components: {
        SoftButton,
        VueSpinnerDots,
        LeftPanelView,
    },
    data() {
        return {
            fiterText: "",
            pageNo: 1,
            pageSize: 10,
            totalEntries: 0,
            schemeDetails: [],
            isLoading: false,
            isEndOfList: false,
            totalLoaded: 0,
            infoTitle: "STP is currently not allowed in this scheme.",
            pageName: "ToSchemes",
            descriptionText: 'Select the scheme that best aligns with your investment strategy to proceed to the subsequent step.',
        };
    },
    props: {
        stpData: {
            type: Object,
            default: () => ({})
        },
        currentStep: Number,
    },
    mounted() {
        this.selectedfundId = JSON.parse(sessionStorage.getItem('schemes'));
        this.applyFilter();
    },
    watch: {
        stpData: {
            handler(newVal) {
                if (newVal.frequency === 'DAILY' || (newVal.amount && newVal.installment)) {
                    this.applyFilter();
                }
            },
        }
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedFromSchemes() {
            return this.selectedfundId.filter((c) => c.fundId === this.getSwitchFund.fundId);
        },
    },
    methods: {
        previous() {
            this.pageNo = 1;
            this.schemeDetails = [];
            this.isEndOfList = false;
            this.totalLoaded = 0;
            this.isLoading = false;
            this.$emit('selection', null);
            this.$parent.prevStep();
        },
        applyFilter() {
            if (!this.stpData?.amount || (this.stpData?.frequency !== 'DAILY' && !this.stpData?.installment)) {
                return;
            }
            this.pageNo = 1;
            this.isEndOfList = false;
            this.totalLoaded = 0;
            if (this.fiterText == "") {
                this.setTables(this.pageNo, this.pageSize, "schemeName", "ASC", null);
            } else {
                this.setTables(this.pageNo, this.pageSize, "schemeName", "ASC", this.fiterText);
            }
        },
        setTables(pageNo, pageSize, sortBy, direction, filter = null) {
            if (this.isLoading || this.isEndOfList) return;

            this.isLoading = true;
            let data = {
                "type": this.getSwitchFund.type.toLowerCase(),
                "amcs": this.selectedFromSchemes[0].amcCode,
                "pageNo": pageNo - 1,
                "pageSize": pageSize,
                "sortBy": sortBy,
                "direction": direction,
                "filter": filter,
                "frequency": this.stpData?.frequency,
            };
            this.$store.dispatch("orderStore/getToSchemes", data, { root: true })
                .then(response => {
                    this.isLoading = true;
                    let newSchemes = response.server.data.userProducts;
                    this.totalEntries = response.server.data.totalElements;
                    const selectedSchemeName = this.selectedFromSchemes?.[0]?.schemeName || "";
                    newSchemes.forEach((scheme) => {
                        if (scheme.stpMasterBean) {
                            const isAstpReginValid = scheme.stpMasterBean.regIn === 'Y';
                            const isStpAmountValid = this.stpData?.amount >= scheme.stpMasterBean.inMinInstAmt && this.stpData?.amount <= scheme.stpMasterBean.inMaxInstAmt;
                            const isInstallmentsValid = this.stpData?.frequency === 'DAILY' ? true : this.stpData?.installment >= scheme.stpMasterBean.minInstNumbers && this.stpData?.installment <= scheme.stpMasterBean.maxInstNumbers;
                            const isAmountMultiple = this.stpData?.amount % scheme.stpMasterBean.inMultiplierAmt === 0;
                            const minInstallmentAmount = scheme.stpMasterBean.minInstNumbers;
                            const maxInstallmentAmount = scheme.stpMasterBean.maxInstNumbers;
                            const isTotalAmountValid = this.stpData?.frequency === 'DAILY' ? true : this.stpData?.totalAmount >= minInstallmentAmount && this.stpData?.totalAmount <= maxInstallmentAmount;
                            scheme.disabled = !(isAstpReginValid && isStpAmountValid && isInstallmentsValid && isAmountMultiple && isTotalAmountValid) || scheme.schemeName === selectedSchemeName;
                        } else {
                            scheme.disabled = true;
                        }
                    });
                    this.totalLoaded += newSchemes.length;

                    if (this.totalLoaded >= this.totalEntries || newSchemes.length < this.pageSize) {
                        this.isEndOfList = true;
                    }

                    this.schemeDetails = [...this.schemeDetails, ...newSchemes];
                    this.pageNo += 1;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        handleScroll(event) {
            const container = event.target;
            if (container.scrollTop + container.clientHeight + 50 >= container.scrollHeight) {
                if (!this.isEndOfList && !this.isLoading) {
                    setTimeout(() => {
                        this.isLoading = true;
                    }, 3000);
                    if (this.fiterText == "") {
                        this.setTables(this.pageNo, this.pageSize, "schemeName", "ASC", null);
                    } else {
                        this.setTables(this.pageNo, this.pageSize, "schemeName", "ASC", this.fiterText);
                    }
                }
            }
        },
        selectScheme(uniqueNo) {
            const selectedScheme = this.schemeDetails.find(scheme => scheme.uniqueNo === uniqueNo); // Match by uniqueNo
            if (selectedScheme) {
                this.selectedScheme = selectedScheme.schemeName;
                this.$emit("selection", {
                    schemeName: this.selectedScheme,
                    fundId: selectedScheme.uniqueNo, // Use uniqueNo for fundId
                });
                this.$parent.nextStep();
            }
        },
    },
};
</script>