import requestCommon from "@/components/web-interface/requestCommon";

const state = {
  email: "",
  phoneNumber: "",
};
const actions = {
  getAmcs(context, data) {
    return requestCommon.getInfo(
      "/ms/bo/amcs",
      data,
      null,
      context,
      "getAmcsSuccessHandler",
      "getAmcsErrorHandler"
    );
  },

  getSchemeTypes(context, data) {
    return requestCommon.getInfo(
      "/ms/bo/amcs/" + data + "/types",
      data,
      null,
      context,
      "getSchemeTypesSuccessHandler",
      "getSchemeTypesErrorHandler"
    );
  },

  getGdos(context, data) {
    return requestCommon.getInfo(
      "/ms/bo/amcs/" + data.amcs + "/types/" + data.schemeType + "/gdos",
      data,
      null,
      context,
      "getSchemeTypesSuccessHandler",
      "getSchemeTypesErrorHandler"
    );
  },

  getAllSchemes(context, data) {
    let fill = "";
    if (data.filter != null) {
      fill = "&filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/ms/bo/amcs/" + data.amcs + "/types/" + data.schemeType +
      "/gdos/" + data.gdos + "/schemes?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
      "&sortBy=" + data.sortBy + "&direction=" + data.direction + fill,
      data,
      null,
      context,
      "getAllSchemesSuccessHandler",
      "getAllSchemesErrorHandler"
    );
  },
  filterSchemesBySchemeType(context, data) {
    let fill = "";
    if (data.filter != null) {
      fill = "&filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/ms/bo/types/" + data.schemeType + "/schemes?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
      "&sortBy=" + data.sortBy + "&direction=" + data.direction + fill,
      data,
      null,
      context,
      "filterSchemesBySchemeTypeSuccessHandler",
      "filterSchemesBySchemeTypeSchemesErrorHandler"
    );
  },
  getNav(context, data) {

    return requestCommon.getInfo(
      "/ms/bo/navs/" + data,
      // /bo/navs/"INF00XX01853",
      data,
      null,
      context,
      "getNavSuccessHandler",
      "getNavErrorHandler"
    );
  },

  getNavHistory(context, data) {

    return requestCommon.getInfo(
      "/ms/bo/navs/" + data + "/history",
      data,
      null,
      context,
      "getNavHistorySuccessHandler",
      "getNavHistoryErrorHandler"
    );
  },

  getSchemeDetails(context, data) {
    return requestCommon.getInfo(
      "/ms/bo/schemes/" + data.fundId+"?type="+data.stpType+"&type="+data.sipType+"&type="+data.swpType,
      data,
      null,
      context,
      "getSchemeDetailsSuccessHandler",
      "getSchemeDetailsErrorHandler"
    );
  },

  getNfos(context, data) {
    return requestCommon.getInfo(
      "/ms/bo/nfos",
      data,
      null,
      context,
      "getNfosSuccessHandler",
      "getNfosErrorHandler"
    );
  },

};
const mutations = {



  getNfosSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getNfosErrorHandler(context, error) {
    console.log(error);
  },



  getAmcsSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getAmcsErrorHandler(context, error) {
    console.log(error);
  },

  getSchemeTypesSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getSchemeTypesErrorHandler(context, error) {
    console.log(error);
  },
  getAllSchemesSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getAllSchemesErrorHandler(context, error) {
    console.log(error);
  },
  filterSchemesBySchemeTypeSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  filterSchemesBySchemeTypeErrorHandler(context, error) {
    console.log(error);
  },

  getNavSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getNavErrorHandler(context, error) {
    console.log(error);
  },
  getNavHistorySuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getNavHistoryErrorHandler(context, error) {
    console.log(error);
  },
  getSchemeDetailsSuccessHandler(context, data) {
    console.log(data);
  },
  //Error Callback 
  getSchemeDetailsErrorHandler(context, error) {
    console.log(error);
  },
};
const getters = {};
export const schemeStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
