<template>
    <div class="pt-5 mt-1 pt-md-6 mt-md-0 pt-md-6 custom-container">
        <SelectionBand v-if="activeStep < 3" class="fixed-banner" :folio-data="folioData"
            :selectedAmount="selectedAmount" :schemeData="toSchemes" :stpData="stpData" />
    </div>
    <div class="container-fluid pt-md-8 pt-11">
        <div class="row">
            <div class="col-12">
                <div class="multisteps-form mb-5">
                    <!--form panels-->
                    <div class="row">
                        <div :class="['col-12 col-xl-10 m-auto']">
                            <form :class="['multisteps-form__form mb-5 mx-md-4 mx-xl-5']">
                                <folio :class="activeStep === 0 ? activeClass : ''" @update-folio="handleFolioUpdate" :currentStep="activeStep" />
                                <StpAmount :class="activeStep === 1 ? activeClass : ''" :folioData="folioData"
                                    @update="handleUpdate" :currentStep="activeStep" />
                                <SelectedScheme :class="activeStep === 2 ? activeClass : ''"
                                    @selection="handleSelection" :stpData="stpData" :currentStep="activeStep"/>
                                <Review :class="activeStep === 3 ? activeClass : ''" :folioData="folioData"
                                    :stpData="stpData" :schemeData="toSchemes" @order-id="updateOrderId"  :currentStep="activeStep"/>
                                <StpAuthentication :class="activeStep === 4 ? activeClass : ''" @update="handleWorkFlow"
                                    :currentStep="activeStep" :orderId="orderId" :folioData="folioData"
                                    :schemeData="toSchemes" />
                                <StpConfirm :class="activeStep === 5 ? activeClass : ''" :message="workFlow"
                                    :stpData="stpData" :orderId="orderId"  :currentStep="activeStep"/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Folio from "./components/stpFolios.vue";
import SelectionBand from "@/views/common/components/SelectionBand.vue";
import StpAmount from "./components/StpAmount.vue";
import SelectedScheme from "./components/StpToScheme.vue";
import Review from "./components/StpReview.vue";
import StpAuthentication from "../switch/components/SwitchAuthentication.vue";
import StpConfirm from "../switch/components/SwitchConfirmation.vue";

export default {
    name: "SystematicTransferPlan",
    components: {
        Folio,
        SelectionBand,
        StpAmount,
        SelectedScheme,
        Review,
        StpAuthentication,
        StpConfirm,
    },
    data() {
        return {
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 5,
            folioData: null,
            schemes: [],
            toSchemes: null,
            workFlow: [],
            switchFund: [],
            orderId: "",
            stpData: null,
            stpRegNo: "",
        };
    },
    methods: {
        nextStep() {
            if (this.activeStep < this.formSteps) {
                this.activeStep += 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            } else {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            }
        },
        prevStep() {
            if (this.activeStep > 0) {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            }
        },
        handleFolioUpdate(data) {
            this.folioData = data;
        },
        handleUpdate(data) {
            this.stpData = data;
        },
        handleSelection(data) {
            this.toSchemes = data;
        },
        handleWorkFlow(data) {
            this.workFlow = data;
        },
        updateOrderId(data) {
            this.orderId = data;
        },
    },
};
</script>