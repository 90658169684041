<template>
    <div class="col-lg-8 col-md-6">
        <h4 class="mb-3">{{ activeTab }} Investments</h4>
    </div>
    <div class="row gx-4 align-items-center">
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
                <ul class="p-1 nav nav-pills nav-fill" role="tablist">
                    <li class="nav-item position-relative">
                        <a class="px-0 py-1 mb-0 nav-link cursor-pointer" :class="{ 'active': activeTab === 'SIP' }"
                            data-bs-toggle="tab" role="tab" aria-selected="true" @click="showCard('SIP')">
                            <span class="ms-1">SIP</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="px-0 py-1 mb-0 nav-link" :class="{ 'active': activeTab === 'STP' }"
                            data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false"
                            @click="showCard('STP')">
                            <span class="ms-1">STP</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="px-0 py-1 mb-0 nav-link" :class="{ 'active': activeTab === 'SWP' }"
                            data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false"
                            @click="showCard('SWP')">
                            <span class="ms-1">SWP</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InvestmentHeader",
    props: {
        activeTab: {
            type: String,
            required: true
        }
    },
    methods: {
        showCard(tab) {
            this.$emit("tab-changed", tab);
        }
    }
};
</script>