<template>
  <main class="my-5 main-content">
    <div class="page-header min-vh-100 d-flex align-items-center justify-content-center">
      <span class="mask opacity-1"></span>
      <div class="container-fluid justify-content-center">
        <div class="row">
          <div
            class="col-3 d-lg-flex d-none h-100 my-auto ms-10 pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
            <div class="position-relative h-100 m-3 ps-7 border-radius-lg d-flex flex-column justify-content-center">
              <div class="position-relative">
                <img class="w-100 pt-3 position-relative z-index-2" src="@/assets/img/mobile-verification.png" />
              </div>
            </div>
          </div>
          <div class="mx-auto mt-5 col-lg-5 col-xl-4 col-md-7">
            <div class="card border shadow">
              <div class="card-body px-lg-6 py-lg-5">
                <div class="text-center mb-4 info">
                  <div class="py-3 mx-auto text-center shadow icon icon-shape icon-xl rounded-circle bg-primary">
                    <img class="w-75 ms-2" src="@/assets/img/otp-logo.png">
                  </div>
                </div>
                <div class="mb-4 text-center text-muted">
                  <h4><b>Mobile Verification</b></h4>
                </div>
                <div class="text-sm text-center text-buccaneer p-0 col-12 col-md-9 mb-3 w-100">(OTP sent to {{ maskedPhoneNumber
                  }})
                </div>
                <Form role="form" @submit="onSubmit">
                  <div class="row gx-2 gx-sm-3 mb-4">
                    <soft-input class="mb-0" placeholder="Enter OTP" v-model="otp" name="otp" id="businessName"
                      type="password" rules="required:true" maxlength="6" @input="onInputChange"/>
                  </div>
                  <div class="text-center error-height pb-3"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                  </div>
                  <div class="mt-4 d-flex justify-content-center mt-5">
                    <soft-button ref="buttonRef" class="mt-0 ms-1" color="primary">Verify
                    </soft-button>
                  </div>
                </Form>
              </div>
              <div class="d-flex justify-content-center align-items-center text-sm mb-4 flex-column flex-sm-row">
                Haven't received it?
                <a id="resend" href="javascript:;" @click="resendOtp" 
                  class="text-info font-weight-bolder ms-sm-1 mt-2 mt-sm-0">Resend OTP</a>
                <span v-if="timer > 0" class="ms-sm-1 mt-2 mt-sm-0">in {{ timer }} seconds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { mapMutations } from "vuex";
import { Form } from "vee-validate";

export default {
  name: "MobileVerification",
  components: {
    SoftButton,
    SoftInput,
    Form,
  },
  data() {
    return {
      otp: "",
      count: 60,
      time: "",
      resendEnable: true,
      phoneNumber: "",
      isSuccess: true,
      error: "",
      email: "",
      message:"userCreated",
    };
  },
  mounted() {
    this.setTime();
    this.phoneNumber = sessionStorage.getItem("phoneNumber");
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    clearInterval(this.time);
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  computed: {
    timer() {
      return this.count;
    },
    maskedPhoneNumber() {
      if (!this.phoneNumber) {
      return 'xxxxxx'; 
    }
    let visiblePart = this.phoneNumber.slice(-4);
    return 'xx' + visiblePart;
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    setTime() {
      clearInterval(this.time);
      document.getElementById("resend").classList.remove("text-info");
      document.getElementById("resend").classList.add("text-secondary");
      this.resendEnable = false;
      this.count = 60;
      this.time = setInterval(this.myTimer, 1000);
    },
    myTimer() {
      this.count--;
      if (this.count == 0) {
        document.getElementById("resend").classList.remove("text-secondary");
        document.getElementById("resend").classList.add("text-info");
        this.resendEnable = true;
        clearInterval(this.time);
      }
    },
    onSubmit() {
      let data = {
        "email": sessionStorage.getItem("signupEmail"),
        "otp": this.otp,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/otpVerification", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.$router.push({ name: 'Sign In', params: { message: self.message } });
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          console.log(error);
          self.error = "OTP validation failed, please request a new one or contact support.";
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
    resendOtp() {
      if (!this.resendEnable) return;
      let data = {
        "email": sessionStorage.getItem("signupEmail"),
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/resendConfirmationCode", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.setTime();
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
    onInputChange(event) {
    const value = event.target.value.replace(/[^0-9]/g, ''); 
    this.otp = value;
    event.target.value = value;
  },
  },
};
</script>
