<template>
  <footer class="py-4 position-relative overflow-hidden bg-dark font">
    <div class="text-justify position-relative z-index-1 container text-white">
      <div class="row">
        Moneytrail Securities Private Limited. CIN: U65990KL2021PTC070749 has Registered Office at : Moneytrail
        Securities Private Limited,Shop No. 1130/76/1,City Centre,Thrissur, KL-680001 IN. OneS is registered trademark
        of Moneytrail Securities Private Limited,Contact Number - +91 9895204233 , Email - support@ones.money, IRDAI
        License No. ILG54458.<br>
        <br>Investment in Securities markets are subject to market risks, read all the related documents carefully
        before investing. Insurance, NPS are not Exchange-traded products and Moneytrail Securities Private Limited is
        just acting as distributor. All disputes with respect to the distribution activity, would not have access to the
        Exchange Investor Redressal Forum or Arbitration mechanism.<br>
        <br>Insurance is subject to market risks and is a subject matter of solicitation. It is the users’
        responsibility to understand the restrictions and risks involved in different insurance products/policies. Under
        no circumstances can/will Moneytrail Securities Private Limited take any liability for this.We request users to
        read and understand the offer and subject documents carefully.
        <br> <br>Mutual fund investments are subject to market risks. Read all scheme related documents
        carefully.<br><br>
        Moneytrail Securities Private Limited. (with ARN Code No 190417) makes no warranties or representations, express
        or implied, on products offered through the platform. It accepts no liability for any damages or losses, however
        caused, in connection with the use of, or on the reliance of its product or related services. Terms and
        conditions of the website are applicable.<br><br>
        <p class="font text-white px-0">Quick Links: <a class=" text-white" href="https://nseindia.com/"
            target="_blank">NSE</a> |<a class=" text-white" href="https://www.bseindia.com/" target="_blank">BSE</a> |
          <a class=" text-white" href="https://www.mcxindia.com/" target="_blank">MCX </a>| <a class=" text-white"
            href="https://nsdl.co.in/" target="_blank">NSDL</a> | <a class=" text-white"
            href="https://www.cdslindia.com/" target="_blank">CDSL</a> |<a class=" text-white"
            href="https://www.sebi.gov.in/" target="_blank"> SEBI</a> |<a class=" text-white"
            href="https://www.scores.gov.in/" target="_blank">SEBI SCORE</a> |<a class=" text-white"
            href="https://evoting.cdslindia.com/Evoting/EvotingLogin" target="_blank">CDSL-E-Voting</a> |<a
            class=" text-white" href="https://www.evoting.nsdl.com/" target="_blank">NSDL-E-Voting</a> |<a
            class=" text-white" href="https://eservices.nsdl.com/" target="_blank">NSDL– eservices</a> |<a
            class=" text-white" href="https://smartodr.in/login" target="_blank">Smart ODR</a> </p>
      </div> <br>
      <div class="col-12 ms-auto text-lg-end text-center">
        <div class="d-flex justify-content-center">
          <a href="https://www.facebook.com/OneSFinTech/" target="_blank" class="text-white me-xl-4 me-4 opacity-5">
            <span aria-hidden="true"><i class="fa-brands fa-facebook"></i></span>
          </a>
          <a href="https://instagram.com/fin.trens?igshid=MzMyNGUyNmU2YQ==" target="_blank"
            class="text-white me-xl-4 me-4 opacity-5">
            <span aria-hidden="true"><i class="fa fa-instagram" aria-hidden="true"></i></span>
          </a>
          <a href="https://www.twitter.com/OneSFinTech/" target="_blank" class="text-white me-xl-4 me-4 opacity-5">
            <span class="fa-brands fa-x-twitter" aria-hidden="true"></span>
          </a>
          <a href="https://www.linkedin.com/company/onesfintech/" target="_blank" class="text-white opacity-5">
            <span aria-hidden="true"><i class="fa-brands fa-linkedin"></i></span>
          </a>
        </div>
      </div>
      <br>
      <div class="row">
          <div class="footer-2 container footer-bottom clearfix text-light">
            <div class="copyright text-center">
              ©
              {{ new Date().getFullYear() }}, Copyright <strong><span>Moneytrail
                  Securities Pvt.Ltd </span></strong>. All Rights Reserved
            </div>
            <div class="credits text-center"> 
              Designed by <a class=" fintrens text-fintrens" href="https://fintrens.com/" target="_blank"><b>Fintrens</b></a> (v{{ appVersion }})
            </div>
          </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
};
</script>