<template>
  <div class="py-7 container-fluid">
    <div class="row">
      <div
        class="col-4 ms-auto d-lg-flex d-none h-100 my-auto pe-0 position-relative top-0 end-0 text-center justify-content-center flex-column">
        <img class="w-100 position-relative z-index-2" src="@/assets/img/additional-info.png" />
      </div>
      <div class="mx-auto col-xl-5 col-lg-7 col-md-9">
        <div class="card border shadow-lg z-index-0">
          <div class="row ms-4 me-6 pe-xl-4 px-sm-4">
            <h4 class="text-center mt-3 mb-3 ms-3">Welcome, {{ userName }} !</h4>
            <span class="mb-2 ms-4 ms-xl-4 text-buccaneer text-center px-0">Please fill in the additional
              information.</span>
          </div>
          <div class="card-body mx-2 px-3 mx-sm-4">
            <Form role="form" @submit="onsubmit">
              <div class="row mt-1">
                <div class="col-12 col-sm-6 mt-sm-2">
                  <label for="income" class="form-label ones-label-size ms-3">Gender</label>
                  <soft-select v-model="gender" id="gender" name="gender" placeholder="Select gender"
                    :options="gender_option" rules="required:true|requiredSelect" disabled="option.disabled">
                  </soft-select>
                </div>
                <div class="col-12 col-sm-6 col-lg-6 mt-3 mt-sm-2 mb-3 mb-sm-3">
                  <label for="resident" class="form-label ones-label-size ms-3">Resident</label>
                  <soft-input v-model="resident" id="resident" name="resident" type="text" rules="required:true"
                    disabled class="ms-md-1">
                  </soft-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 mt-sm-2 mb-3 mb-sm-3">
                  <label for="income" class="form-label ones-label-size ms-3">Gross Annual Income</label>
                  <soft-select v-model="income" id="income" name="income" placeholder="Select income"
                    :options="income_option" rules="required:true|requiredSelect" disabled="option.disabled">
                  </soft-select>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-2 mb-4 mb-sm-3">
                  <label for="occupation" class="form-label ones-label-size ms-3">Occupation</label>
                  <soft-select v-model="occupation" id="occupation" name="occupation" type="text"
                    placeholder="Select occupation" :options="option" rules="required:true|requiredSelect" disabled="option.disabled">
                  </soft-select>
                </div>
              </div>
              <soft-checkbox id="term" rules="check" name="term" class="font-weight mt-2 mb-5" checked="false"
                v-model="check">
                <template v-slot:label>
                  <label class="text-buccaneer text-justify">By proceeding, you affirm that you are not politically
                    exposed, were born in India, and are subject to tax obligations in India</label>
                </template>
              </soft-checkbox>
              <div class="text-center"><small class="mt-4 text-danger text-xs" v-if="!isSuccess">{{ error
                        }}</small>
                    </div>
              <div class="mt-4 d-flex justify-content-center">
                <soft-button class="text-primary bg-white border border-primary"
                  @click="previouspage">previous</soft-button>
                <soft-button type="submit" name="button" class="m-0 btn bg-primary ms-2">
                  Proceed
                </soft-button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftCheckbox from "@/views/common/components/SoftCheckbox.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftSelect from "@/views/common/components/SoftSelect.vue";
import SoftInput from "@/views/common/components/SoftInput.vue";
import { Form } from "vee-validate";
export default {
  name: "AdditionalInfo",
  components: {
    SoftCheckbox,
    SoftButton,
    SoftSelect,
    SoftInput,
    Form,
  },
  data() {
    return {
      gender: "",
      income: "",
      occupation: "",
      resident: "INDIAN",
      income_option: [
        { value: "Select income", text: "Select income", disabled: true },
        { value: "BELOW_ONE", text: "Less than 1 lakhs" },
        { value: "ONE_AND_FIVE", text: "1-5 lakhs" },
        { value: "FIVE_AND_TEN", text: "5-10 lakhs" },
        { value: "TEN_AND_TWENTY_FIVE", text: "10-25 lakhs" },
        { value: "TWENTY_FIVE_AND_ONE", text: "25-1 crore" },
        { value: "ABOVE_ONE", text: "Greater than 1 crore" },

      ],
      option: [
        { value: "Select occupation", text: "Select occupation", disabled: true },
        { value: "BUSINESS", text: "BUSINESS" },
        { value: "PROFESSIONAL", text: "PROFESSIONAL" },
        { value: "SERVICES", text: "SERVICES" },
        { value: "RETIRED", text: "RETIRED" },
        { value: "STUDENT", text: "STUDENT" },
        { value: "HOUSEWIFE", text: "HOUSEWIFE" },
        { value: "AGRICULTURE", text: "AGRICULTURE" },
        { value: "OTHERS", text: "OTHERS" },
      ],
      check: "on",
      gender_option: [
        { value: "Select gender", text: "Select gender", disabled: true },
        { value: "MALE", text: "MALE" },
        { value: "FEMALE", text: "FEMALE" },
        { value: "OTHERS", text: "OTHERS" },
      ],
      userName: "",
      email: "",
      isSuccess: true,
    };

  },
  mounted() {
    this.userName = this.titleCaseUserName(this.$route.params.userName);
    var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
    this.email = userPayload.email;
  },
  methods: {
    titleCaseUserName(name) {
      if (!name) return '';
      return name.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    onsubmit() {
      let data = {
        "email": this.email,
        "gender": this.gender,
        "grossAnnualIncome": this.income,
        "resident": this.resident,
        "occupation": this.occupation,
      };
      var self = this;
      this.$store
        .dispatch("userStore/additionalInfo", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.$router.push("/nomineedetails");
        })
        .catch(function (error) {
          console.log(error);
          self.isSuccess = false;
          self.error = error.message || 'An issue has occurred, please try again.';
          
        });
    },
    previouspage() {
      this.$router.push("/pancardverification");
    },
  },
};
</script>
