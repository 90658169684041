import requestCommon from "@/components/web-interface/requestCommon";

const state = {
    switchFund: {},
};
const actions = {
    switchOrder(context, data) {
        return requestCommon.postInfo(
            "/us/orders/migrate/" + data.type,
            null,
            data,
            context,
            "switchOrderSuccessHandler",
            "switchOrderErrorHandler"
        );
    },
    switchConfirmation(context, data) {
        return requestCommon.putInfo(
            "/us/orders/migrate/" + data.fundId + "/" + data.orderId,
            null,
            data,
            context,
            "switchConfirmationSuccessHandler",
            "switchConfirmationErrorHandler"
        );
    },
    switchResentOtp(context, data) {
        return requestCommon.patchInfo(
            "/us/orders/switch/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "switchResentOtpSuccessHandler",
            "switchResentOtpErrorHandler"
        );
    },
    stpOrder(context, data) {
        return requestCommon.postInfo(
            "/us/stps/migrate/" + data.type,
            null,
            data,
            context,
            "stpOrderSuccessHandler",
            "stpOrderErrorHandler"
        );
    },
    stpConfirmation(context, data) {
        return requestCommon.putInfo(
            "/us/stps/"  + data.orderId,
            null,
            data,
            context,
            "stpConfirmationSuccessHandler",
            "stpConfirmationErrorHandler"
        );
    },
    stpResentOtp(context, data) {
        return requestCommon.patchInfo(
            "/us/stps/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "stpResentOtpSuccessHandler",
            "stpResentOtpErrorHandler"
        );
    },
    swpOrder(context, data) {
        return requestCommon.postInfo(
            "/us/swps/migrate/order/" + data.type,
            null,
            data,
            context,
            "swpOrderSuccessHandler",
            "swpOrderErrorHandler"
        );
    },
    swpConfirmation(context, data) {
        return requestCommon.putInfo(
            "/us/swps/order/migrate/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "swpConfirmationSuccessHandler",
            "swpConfirmationErrorHandler"
        );
    },
    swpResentOtp(context, data) {
        return requestCommon.patchInfo(
            "/us/swps/" + data.orderId + "/" + data.fundId,
            null,
            data,
            context,
            "swpResentOtpSuccessHandler",
            "swpResentOtpErrorHandler"
        );
    },
    getToSchemes(context, data) {
        let fill = "";
        if (data.filter != null) {
            fill = "&filter=" + data.filter
        }
        return requestCommon.getInfo(
            "/ms/bo/"+data.type+"/" + data.amcs + "/schemes?frequency="+ data.frequency + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
            "&sortBy=" + data.sortBy + "&direction=" + data.direction + fill,
            data,
            null,
            context,
            "getToSchemesSuccessHandler",
            "getToSchemesErrorHandler"
        );
    },

};
const mutations = {
    setSwitchFund(state, payload) {
        state.switchFund = payload;
    },
    switchOrderSuccessHandler(context, data) {
        console.log(data);
    },
    switchOrderErrorHandler(context, error) {
        console.log(error);
    },
    switchConfirmationSuccessHandler(context, data) {
        console.log(data);
    },
    switchConfirmationErrorHandler(context, error) {
        console.log(error);
    },
    switchResentOtpSuccessHandler() {
        console.log("successfull resent otp");
    },
    switchResentOtpErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },

    stpOrderSuccessHandler(context, data) {
        console.log(data);
    },
    stpOrderErrorHandler(context, error) {
        console.log(error);
    },
    stpConfirmationSuccessHandler(context, data) {
        console.log(data);
    },
    stpConfirmationErrorHandler(context, error) {
        console.log(error);
    },
    stpesentOtpSuccessHandler() {
        console.log("successfull resent otp");
    },
    stpResentOtpErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },
    swpOrderSuccessHandler(context, data) {
        console.log(data);
    },
    swpOrderErrorHandler(context, error) {
        console.log(error);
    },
    swpConfirmationSuccessHandler(context, data) {
        console.log(data);
    },
    swpConfirmationErrorHandler(context, error) {
        console.log(error);
    },
    swpesentOtpSuccessHandler() {
        console.log("successfull resent otp");
    },
    swpResentOtpErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },
    getToSchemesSuccessHandler(context, data) {
        console.log(data);
    },
    getToSchemesErrorHandler(context, error) {
        console.log(error);
    },
};
const getters = {
    getSwitchFund: (state) => {
        return state.switchFund;
    },
};
export const orderStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};