<template>
  <main class="mt-0 mb-2 main-content">
    <div class="page-header align-items-start min-vh-100 border-radius-lg">
      <span class="mask opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 d-lg-flex d-none my-auto pe-0 mx-3 position-relative top-0 end-0 text-center justify-content-center flex-column">
            <img class="position-relative z-index-2" src="@/assets/img/sign-in.png" />
          </div>
          <div class="ms-xl-7 col-xl-5 col-lg-5 col-md-9 pt-7">
            <div class="card border shadow-lg z-index-0">
              <div class="row ms-2 me-5 ms-sm-4 me-sm-6 pe-xl-4 px-sm-4">
                <h2 class="text-center mt-2 mb-4 ms-3">Sign In</h2>
                <strong class="ms-4 mb-2 text-success mb-1 text-center px-0" v-if="showMessage">{{ messageText
                  }}</strong>
                <span class="mb-4 ms-4 ms-xl-4 text-buccaneer text-center px-0">Enter your email and password
                  to sign in</span>
              </div>
              <div class="card-body mx-xl-5 pe-3 mx-2 mx-sm-5 mx-md-5 pt-0">
                <Form role="form" @submit="onSubmit">
                  <div class="mb-4">
                    <label for="email" class="ones-label-size">Email</label>
                    <soft-input id="email" type="email" rules="required:true|email" v-model="email" placeholder="Email"
                      name="email" />
                  </div>
                  <div class="mb-5 mb-sm-4">
                    <label class="ones-label-size">Password</label>
                    <soft-input id="password" type="password" rules="required:true" v-model="password"
                      placeholder="Password" name="password" />
                  </div>
                  <div class="text-center"><small class="text-danger text-xs" v-if="!isSuccess">{{ error }}</small>
                  </div>
                  <div class="text-center"><small class="text-danger text-xs" v-if="token == 'Expired'">Token Expired</small>
                  </div>
                  <div class="text-center">
                    <soft-button class="my-4" color="primary" full-width>Sign in
                    </soft-button>
                  </div>
                </Form>
              </div>
              <div class="px-1 pt-0 text-center card-footer px-lg-2">
                <p class="mx-5 m-3 text-sm">
                  Don't have an account?
                  <router-link to="/signup" class="text-info font-weight-bold">
                    Sign Up
                  </router-link>
                </p>
                <div>
                  <router-link :to="{ name: 'Reset Password', query: { email: email } }"
                    class="text-info text-sm font-weight-bold">
                    Forgot Password?
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SoftInput from "@/views/common/components/SoftInput.vue";
import SoftButton from "@/views/common/components/SoftButton.vue";
import { Form } from "vee-validate";
import { mapMutations } from "vuex";
import store from "@/store/index.js";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "Signin",
  components: {
    SoftInput,
    SoftButton,
    Form,
  },
  data() {
    return {
      email: "",
      password: "",
      isSuccess: true,
      error: "",
      showMessage: false,
      messageText: "",
      tokenRefreshTimer: "",
      message: "",
      tokenStatus:""
    };
  },
  mounted() {
    this.message = this.$route.params.message;
    this.tokenStatus = this.$route.query.error;
    switch (this.message) {
      case "userCreated":
        this.showMessage = true;
        this.messageText = "Thank you for completing the verification! Please log in to continue.";
        break;
      case "createUser":
        this.showMessage = true;
        this.messageText = "Please complete your onboarding process by logging in.";
        break;
        case "linkFailed":
        this.showMessage = true;
        this.messageText = "Invalid session, please sign in to continue.";
        break;
      default:
        this.showMessage = false;
        break;
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    if (this.tokenRefreshTimer) {
      clearTimeout(this.tokenRefreshTimer);
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    onSubmit() {
      this.$store.state.schemeStore.email = this.email;
      let data = {
        "email": this.email,
        "password": this.password,
      };
      var self = this;
      this.$store
        .dispatch("userStore/signIn", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          var userPayload = JSON.parse(sessionStorage.getItem('userPayload'));
          var exp = userPayload.exp;
          var iat = userPayload.iat;
          self.setupTokenRefreshTimer(exp,iat);
          self.userStatus = store.getters['userStore/getUserStatus'];
          if (self.userStatus === "USER_CREATED" || self.userStatus === "CKYC_SEARCH_COMPLETED") {
            self.$router.push('/pancardverification');
          } else if (self.userStatus === "CKYC_DOWNLOAD_COMPLETED") {
            self.$router.push('/nomineedetails');
          } else if (self.userStatus === "NOMINEE_ENROLLMENT_INITIATED"|| self.userStatus==="BANK_DETAILS_SAVED") {
            self.$router.push('/bankaccountregister');
          } else if (self.userStatus === "BANK_DETAILS_VERIFIED") {
            self.$router.push('/emailverification');
          } else if (self.userStatus === "EMAIL_VERIFIED" || self.userStatus === "BSE_USER_MANDATE_FAILED" || self.userStatus === "BSE_USER_MANDATE_COMPLETED" || self.userStatus === "VERIFIED") {
            self.$router.push('/dashboard');
          } else {
            self.$router.push('/signin');
          }
        })
        .catch(function (error) {
          if (error.message === "User is not confirmed.") {
            sessionStorage.setItem("signupEmail", self.email);
            self.resendOtp();
            self.$router.push('/mobileverification');
          } else {
            self.error = error.message;
          }
          self.isSuccess = false;
        });
    },
    toast() {
      var toastElement = document.getElementById('liveToast');
      var toast = new bootstrap.Toast(toastElement);
      toast.show();
    },
    setupTokenRefreshTimer(exp, iat) {
      if (this.tokenRefreshTimer) {
        clearTimeout(this.tokenRefreshTimer);
      }
      const now = iat;
      const margin = 300;
      const delayInSeconds = exp - now - margin;
      if (delayInSeconds > 0) {
        setTimeout(() => {
          this.refreshToken();
        }, delayInSeconds * 1000);
      } else {
        this.refreshToken();
      }
    },
    refreshToken() {
      const refreshToken = sessionStorage.getItem("refreshToken");
      let data = {
        "email": this.email,
        "refreshToken": refreshToken,
      };
      var self = this;
      this.$store
        .dispatch("userStore/refreshAccessToken", data, { root: true })
        .then(function (response) {
          console.log(response);
          var newUserPayload = JSON.parse(sessionStorage.getItem('userPayload'));
          var newExp = newUserPayload.exp;
          var newIat = newUserPayload.iat;
          self.setupTokenRefreshTimer(newExp,newIat);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    resendOtp() {
      let data = {
        "email": this.email,
      };
      var self = this;
      self.$store.commit('layoutStore/setSpinnerActive', true);
      this.$store
        .dispatch("userStore/resendConfirmationCode", data, { root: true })
        .then(function (response) {
          console.log(response);
          self.isSuccess = true;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        })
        .catch(function (error) {
          self.error = error.message;
          self.isSuccess = false;
          self.$store.commit('layoutStore/setSpinnerActive', false);
        });
    },
  },
};
</script>
