<template>
  <div class="container-fluid pt-5 mb-4">
    <div class="row min-vh-100 flex-column flex-md-row">
      <div class="col p-0">
        <div class="mt-4 page-header min-height-160 border-radius-xl myInvestment-headerimage">
        </div>
        <div class="mx-md-4 overflow-hidden card card-body bg-light shadow-blur mt-n6">
          <div class="card p-3 border-radius-xl px-md-5 px-xl-5 px-3 pt-3 border-shadow-xl">
            <investment-header :activeTab="activeTab" @tab-changed="handleTabChange" />
            <div v-if="investmentSchemes.length === 0" class="pb-5">
              <div class="multisteps-form__content py-6">
                <h6 class="text-center">At the moment, there are no investments available under {{ activeTab }}. Please check back later or explore other options.</h6>
              </div>
            </div>
            <div v-else class=" pt-5 pb-4 mx-3">
              <div class="multisteps-form__content">
                <div class="card-body mb-2 p-0" v-for="scheme in currentSchemes" :key="scheme.fromFundId">
                  <div class="card pt-3 mt-3 border-radius-xl bg-white px-xl-6">
                    <div class="mb-4 row mx-2">
                      <div
                        class="col-12 col-md-7 col-xl-8 d-flex flex-column flex-md-row align-items-md-center ps-md-5 ps-xl-0">
                        <div class="d-flex flex-column">
                          <span v-if="activeTab !== 'STP'" class="text-xs">{{ scheme.fromScheme }}</span>
                          <div v-if="activeTab === 'STP'" class="d-flex flex-column">
                            <span class="text-xs mb-2">
                              <strong>From:</strong> {{ scheme.fromScheme }}
                            </span>
                            <span class="text-xs mb-2">
                              <strong class="me-3">To:</strong> {{ scheme.toScheme }}
                            </span>
                          </div>
                          <div class="d-flex flex-column flex-md-row mt-2 mt-md-0">
                            <span class="text-xs font-weight-bolder mb-1 mt-1 mb-md-0">
                              <i class="fa fa-money me-1 text-primary"></i>₹{{ formattedAmount(scheme.amount) }}</span>
                            <span v-if="scheme.status === 'ACTIVE' "
                              class="text-xs ms-md-3 mb-1 mt-1 mb-md-0">
                              <i class="fas fa-calendar-day me-1 text-primary"></i>{{ formatDate(scheme.dueDate) }}
                            </span>
                            <span
                              :class="['badge w-md-50 w-md-auto ms-md-3 me-xl-0 mb-1 mb-md-0 text-xxs bg-opacity-10', badgeClass(scheme.status)]">
                              {{ statusText(scheme.status) }}
                            </span>
                            <span v-if="isDueSoon(scheme) && scheme.status !== 'CANCEL_INITIATED'"
                              class="text-xs ms-md-3 mb-1 mt-1 mb-md-0 d-block d-md-none d-lg-block">
                              <i class="fas fa-calendar-day text-info"></i>
                              The due in {{ calculateDaysDifference(scheme.dueDate) }} days.
                            </span>
                          </div>
                          <div v-if="isDueSoon(scheme) && scheme.status !== 'CANCEL_INITIATED'"
                            class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center text-xs mb-1 mt-1 mb-md-0 d-none d-md-block d-lg-none">
                            <span>
                              <i class="fas fa-calendar-day text-info me-1"></i>
                              Due in {{ calculateDaysDifference(scheme.dueDate) }} days.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-4 d-flex justify-content-md-end justify-content-center align-items-center mt-3 mt-md-0 text-center px-2 px-md-0">
                        <a v-if="activeTab === 'SIP' && scheme.pauseFlag" class="me-md-4 ms-md-0 me-3 text-xs"
                          href="#" @click.prevent="openPauseModal(scheme, 'PAUSE')"
                          :class="{ 'disabled-link': isSkipDisabled(scheme), 'cursor-pointer': !isSkipDisabled(scheme) }"
                          :disabled="isSkipDisabled(scheme)">
                          <i class="fas fa-forward"></i> Skip
                        </a>
                        <a class="text-xs" href="#" @click.prevent="openPauseModal(scheme, 'CANCEL')"
                          :class="{ 'disabled-link': isCancelDisabled(scheme), 'cursor-pointer': !isCancelDisabled(scheme) }"
                          :disabled="isCancelDisabled(scheme)">
                          <i class="fas fa-times-circle"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="totalPages > 1" class="mt-3 d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination pagination-sm">
                  <li class="page-item" :class="{ disabled: currentPage <= 1 }">
                    <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item" v-for="page in totalPages" :key="page"
                    :class="{ active: page === currentPage }">
                    <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
                    <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <skip-modal ref="skipModal" :scheme="currentScheme" :action="modalAction" :type="activeTab" />
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import skipModal from "./components/SkipCancel.vue";
import investmentHeader from "./components/InvestmentHeader.vue";
export default {
  name: "MyInvestment",
  components: {
    skipModal,
    investmentHeader,
  },
  data() {
    return {
      activeTab: 'SIP',
      currentPage: 1,
      itemsPerPage: 5,
      installments: "",
      modalAction: 'PAUSE',
      currentScheme: null,
      investmentSchemes: [],
      pauseFlag: "",
      pauseSuccessCount: 0,
      pageNo: 1,
      pageSize: 5,
      sortBy: "createdDate",
      direction: 'DESC',
      totalElements: "",
    };
  },
  mounted() {
    this.getAllSip();
  },
  computed: {
    currentSchemes() {
      return this.investmentSchemes;
    },
    totalPages() {
      return Math.ceil(this.totalElements / this.itemsPerPage);
    },
    paginatedSchemes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      console.log('Paginated Schemes:', this.currentSchemes.slice(start, end));
      return this.currentSchemes.slice(start, end);
    },
    formattedAmount() {
      return (amount) => {
        if (!amount) return "0.00";
        const formatted = parseFloat(amount);
        return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formatted);
      };
    },
  },
  methods: {
    badgeClass(status) {
      if (status === 'ACTIVE') {
        return 'badge-success';
      } else if (status === 'CANCEL_INITIATED') {
        return 'badge-danger';
      } else if (status === 'PAUSED') {
        return 'badge-info';
      } else {
        return 'badge-secondary';
      }
    },
    statusText(status) {
      switch (status) {
        case 'ACTIVE':
          return 'Active';
        case 'PENDING_CONFIRMATION':
          return 'Pending';
        case 'CANCEL_INITIATED':
          return 'Cancelling';
        case 'PAUSED':
          return 'Skipped';
        case 'CANCELLED':
          return 'Cancelled';
          case 'INACTIVE':
          return 'Inactive';
        default:
          return status;
      }
    },
    handleTabChange(tab) {
      this.activeTab = tab;
      this.pageNo = 1;
      this.totalElements = 0;
      this.currentPage = 1;
      this.investmentSchemes = [];
      if (this.activeTab === 'SIP') {
        this.getAllSip();
      } else if (this.activeTab === 'STP') {
        this.getAllStp();
      } else {
        this.getAllSwp();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.pageNo = this.currentPage;
        this.fetchSchemes();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.pageNo = this.currentPage;
        this.fetchSchemes();
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.pageNo = this.currentPage;
      this.fetchSchemes();
    },
    fetchSchemes() {
      this.investmentSchemes = [];
      if (this.activeTab === 'SIP') {
        this.getAllSip();
      } else if (this.activeTab === 'STP') {
        this.getAllStp();
      } else if (this.activeTab === 'SWP') {
        this.getAllSwp();
      }
    },
    openPauseModal(scheme, action) {
      if (action === 'PAUSE' && this.isSkipDisabled(scheme)) {
        return;
      }
      if (action === 'CANCEL' && this.isCancelDisabled(scheme)) {
        return;
      }
      this.currentScheme = scheme;
      this.modalAction = action;
      const modal = new bootstrap.Modal(document.getElementById('skipModal'));
      modal.show();
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    calculateDaysDifference(date) {
      const today = new Date();
      const dueDate = new Date(date);
      const diffTime = dueDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    isDueSoon(scheme) {
      const daysUntilDue = this.calculateDaysDifference(scheme.dueDate);
      return daysUntilDue <= 6 && daysUntilDue > 0;
    },
    getAllSip() {
      let data = {};
      data = {
        "pageNo": this.pageNo - 1,
        "pageSize": this.pageSize,
        "sortBy": this.sortBy,
        "direction": this.direction
      };

      var self = this;
      self.$store
        .dispatch("manageStore/getAllSip", data, { root: true })
        .then(function (response) {
          self.investmentSchemes = response.server.data.sipBeans;
          self.totalElements = response.server.data.totalElements;
          const schemeGroup = {};
          self.investmentSchemes.forEach(investmentSchemes => {
            if (!schemeGroup[investmentSchemes.fundId]) {
              schemeGroup[investmentSchemes.fundId] = [];
            }
            schemeGroup[investmentSchemes.fundId].push(investmentSchemes);
          });
          Object.entries(schemeGroup).forEach(([fundId,investmentSchemes ]) => {
              data = {
                "fundId": fundId,
                "sipType": "SIP"
              };
              self.$store
                .dispatch("schemeStore/getSchemeDetails", data, { root: true })
                .then(function (response) {
                  investmentSchemes.forEach(item => {
                  item.fromScheme = response.server.data.schemeName;
                  item.pauseFlag = response.server.data.sipDetails.pauseFlag;
                  item.pauseModificationCount = response.server.data.sipDetails.pauseModCount;
                })
              })
                .catch(function (error) {
                  console.log(error);
                });
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  },
  getAllStp() {
  let data = {
    "pageNo": this.pageNo - 1,
    "pageSize": this.pageSize,
    "sortBy": this.sortBy,
    "direction": this.direction
  };
  var self = this;

  self.$store
    .dispatch("manageStore/getAllStp", data, { root: true })
    .then(function (response) {
      self.investmentSchemes = response.server.data.stpBeans;
      self.totalElements = response.server.data.totalElements;

      if (self.investmentSchemes && self.investmentSchemes.length > 0) {
        const schemeGroup = {};
        self.investmentSchemes.forEach(investmentScheme => {
          if (!schemeGroup[investmentScheme.fromFundId]) {
            schemeGroup[investmentScheme.fromFundId] = [];
          }
          schemeGroup[investmentScheme.fromFundId].push(investmentScheme);
        });
        const schemeToGroup = {};
        self.investmentSchemes.forEach(investmentScheme => {
          if (!schemeGroup[investmentScheme.toFundId]) {
            if (!schemeToGroup[investmentScheme.toFundId]) {
              schemeToGroup[investmentScheme.toFundId] = [];
            }
            schemeToGroup[investmentScheme.toFundId].push(investmentScheme);
          }
        });
        const processedFundIds = new Set();
        Object.entries(schemeGroup).forEach(([fromFundId, investmentSchemes]) => {
          if (!processedFundIds.has(fromFundId)) {
            processedFundIds.add(fromFundId);
            data = {
              "fundId": fromFundId,
              "stpType": "STP"
            };
            self.$store
              .dispatch("schemeStore/getSchemeDetails", data, { root: true })
              .then(function (response) {
                investmentSchemes.forEach(item => {
                  item.fromScheme = response.server.data.schemeName;
                });
                if (self.activeTab === "STP") {
                  Object.entries(schemeToGroup).forEach(([toFundId, investmentSchemes]) => {
                    if (!processedFundIds.has(toFundId)) {
                      processedFundIds.add(toFundId);
                      data = {
                        "fundId": toFundId,
                        "stpType": self.activeTab
                      };
                      self.$store
                        .dispatch("schemeStore/getSchemeDetails", data, { root: true })
                        .then(function (response) {
                          investmentSchemes.forEach(item => {
                            item.toScheme = response.server.data.schemeName;
                          });
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
},
    getAllSwp() {
      let data = {};
      data = {
        "pageNo": this.pageNo - 1,
        "pageSize": this.pageSize,
        "sortBy": this.sortBy,
        "direction": this.direction
      };
      var self = this;
      self.$store
        .dispatch("manageStore/getAllSwp", data, { root: true })
        .then(function (response) {
          self.investmentSchemes = response.server.data.swpBeans;
          self.totalElements = response.server.data.totalElements;
          const schemeGroup = {};
          self.investmentSchemes.forEach(investmentSchemes => {
            if (!schemeGroup[investmentSchemes.fundId]) {
              schemeGroup[investmentSchemes.fundId] = [];
            }
            schemeGroup[investmentSchemes.fundId].push(investmentSchemes);
          });
          console.log("schemeGroup:",schemeGroup);
          Object.entries(schemeGroup).forEach(([fundId,investmentSchemes ]) => {
              data = {
                "fundId": fundId,
                "stpType": "SWP"
              };
              self.$store
                .dispatch("schemeStore/getSchemeDetails", data, { root: true })
                .then(function (response) {
                  investmentSchemes.forEach(item => {
                  item.fromScheme = response.server.data.schemeName;
                  })
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    isSkipDisabled(scheme) {
      if (scheme.status === 'CANCEL_INITIATED' || scheme.status === 'PAUSED') {
        return true;
      }
      const isDisabled = scheme.pausedCount >= scheme.pauseModificationCount;
      return isDisabled || !scheme.pauseEnabled;
    },
    isCancelDisabled(scheme) {
      if (scheme.status === 'CANCEL_INITIATED') {
        return true;
      }
      return !scheme.cancelEnabled;
    },
  },
};
</script>
