<template>
  <nav class="navbar shadow navbar-expand-lg bg-white top-0 navbar-z-index position-fixed py-2 start-0 end-0">
    <div class="container-fluid mx-4 px-0">
      <router-link to="/ones-home">
        <img class="logo align-self-center ms-5 d-lg-none " src="../../assets/img/ones-logo.png" alt="logo">
      </router-link>
      <router-link to="/ones-home">
        <img class="logo align-self-center d-none d-lg-block" src="../../assets/img/ones-logo.png" alt="logo">
      </router-link>
      <button class="shadow-none navbar-toggler ms-2" type="button" data-bs-toggle="collapse"
        data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
        <span class="mt-2 navbar-toggler-icon">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div id="navigation" class="pt-3 pb-2 collapse navbar-collapse py-lg-0 ms-3 w-100">
        <ul class="mx-auto navbar-nav navbar-nav-hover">
          <!-- responsive -->
          <div v-show="isLoggedIn" class="row d-lg-none bg-light ms-2 me-4">
            <div class="col-12 mt-2 d-flex">
              <span class="fa-stack p-0" :class="displayName !== email ? 'mt-2' : 'mb-2'">
                <i class="fas fa-user-circle fa-stack-2x text-primary"></i>
                <i class="fas status-responsive-icon me-1"
                  :class="[isActive ? 'fa-check-circle text-success' : 'fa-exclamation-circle text-danger']"></i>
              </span>
              <div class="text-container d-flex flex-column ms-2 w-100">
                <p class="text-dark mb-1 text-sm ms-2">Welcome, <b>{{ displayName }}</b></p>
                <p class="text-dark mb-2 text-xs ms-2" v-if="displayName !== email">{{ email }}</p>
              </div>
            </div>
          </div>
          <div v-show="isLoggedIn && !isActive && isCognitoStatusValid" class="row d-lg-none bg-yellow ms-2 me-4 mt-2">
            <div class="text-container d-flex flex-column">
              <p class="text-dark my-1 text-xs ">
                <b>Your account verification is currently being processed.</b>
              </p>
              <p class="text-dark mb-3 text-xs text-wrap">
                Please <a href="#" @click.prevent="handleBadgeClick" class="text-primary fw-bold">click here</a> to
                complete it
              </p>
            </div>
          </div>
          <div v-show="!isLoggedIn && !isSignInPage" class="row d-lg-none background-image ms-lg-2 me-lg-4">
            <div class="col-12 mt-2 d-flex justify-content-center align-items-center">
              <div class="text-container">
                <p class="text-white mb-0 text-xs mx-md-1">Simple and Free Investing</p>
              </div>
              <a href="/#/signin" target="_self" class="btn btn-md btn-round text-white button-size mb-0 pb-4 px-0 ms-4"
                :class="btnBackground ? btnBackground : 'bg-primary'">Sign In</a>
            </div>
          </div>
          <div v-show="!isLoggedIn && !isExplorePage" class="row d-lg-none background-image-explore ms-lg-2 me-lg-4">
            <div class="col-12 mt-2 d-flex justify-content-center align-items-center">
              <div class="text-container">
                <p class="text-white mb-0 text-xs">Explore your Mutual Funds</p>
              </div>
              <a href="/#/explore" target="_self"
                class="btn btn-md btn-round text-white button-size mb-0 pb-4 px-0 ms-4"
                :class="btnBackground ? btnBackground : 'bg-gradient-secondary'">Explore</a>
            </div>
          </div>

          <li v-show="isEmailVerified" class="mx-2 nav-item dropdown dropdown-hover">
            <a id="dropdownMenuBlocks" role="button"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
              <b>Dashboard</b>
              <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
              <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
            </a>
            <div
              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive border-radius-lg mt-lg-3 overflow-hidden shadow-lg"
              aria-labelledby="dropdownMenuBlocks">
              <div class="d-none d-lg-block">
                <ul class="list-group">
                  <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                    <router-link class="py-2 dropdown-item ps-3 border-radius-md" :to="{ name: 'Dashboard' }">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="ni ni-single-copy-04 text-primary"></i>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-between">
                          <div>
                            <p class="p-0 dropdown-header text-dark">Dashboard</p>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                    <router-link class="py-2 dropdown-item ps-3 border-radius-md" :to="{ name: 'Portfolio' }">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="ni ni-laptop text-primary"></i>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-between">
                          <div>
                            <p class="p-0 dropdown-header text-dark">Portfolio</p>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
              <!-- responsive -->
              <div class="row d-lg-none">
                <div class="col-md-12">
                  <router-link class="py-2 ps-3 border-radius-md" :to="{ name: 'Dashboard' }">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="ni ni-single-copy-04 text-primary"></i>
                      </div>
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div>
                          <p class="p-0 dropdown-header text-dark">Dashboard</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <router-link class="pt-3 ps-3 border-radius-md" :to="{ name: 'Portfolio' }">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="fas fa-briefcase text-primary"></i>

                      </div>
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div>
                          <p class="p-0 dropdown-header text-dark">Portfolio</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li v-if="isEmailVerified" class="mx-2 nav-item dropdown dropdown-hover" data-bs-auto-close="inside">
            <a id="dropdownMenuBlocks" role="button"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
              <b>Manage</b>
              <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
              <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
            </a>
            <div
              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive border-radius-lg mt-lg-3 overflow-hidden shadow-lg"
              aria-labelledby="dropdownMenuBlocks">
              <div class="d-none d-lg-block">
                <ul class="list-group">
                  <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                    <router-link class="py-2 dropdown-item ps-3 border-radius-md" :to="{ name: 'Order Status' }">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="ni ni-single-copy-04 text-primary"></i>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-between">
                          <div>
                            <p class="p-0 dropdown-header text-dark">My Orders</p>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                    <router-link class="py-2 dropdown-item ps-3 border-radius-md" :to="{ name: 'MyInvestment' }">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="fas fa-clipboard-list text-primary"></i>
                        </div>
                        <div class="w-100 d-flex align-items-center justify-content-between">
                          <div>
                            <p class="p-0 dropdown-header text-dark">My Plans</p>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
              <!-- responsive -->
              <div class="row d-lg-none">
                <div class="col-md-12">
                  <router-link class="py-2 ps-3 border-radius-md" :to="{ name: 'Order Status' }">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="ni ni-single-copy-04 text-primary"></i>
                      </div>
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div>
                          <p class="p-0 dropdown-header text-dark">My Orders</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-12">
                  <router-link class="py-2 ps-3 border-radius-md" :to="{ name: 'MyInvestment' }">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="fas fa-clipboard-list text-primary"></i>
                      </div>
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div>
                          <p class="p-0 dropdown-header text-dark">My Plans</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li v-if="isLoggedIn" class="mx-2 nav-item dropdown dropdown-hover">
            <a id="dropdownMenuDocs" role="button"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
              <b>Explore</b>
              <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
              <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
            </a>
            <div
              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-lg mt-lg-3 border-radius-lg overflow-auto shadow-lg"
              aria-labelledby="dropdownMenuDocs">
              <div class="d-none d-lg-block">
                <ul class="list-group">
                  <li class="p-0 border-0 nav-item list-group-item">
                    <a class="py-2 dropdown-item ps-3 border-radius-md" href="/#/explore">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="fa-solid fa-magnifying-glass text-primary"></i>
                        </div>
                        <div>
                          <p class="p-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center">
                            Explore
                          </p>
                          <span class="text-sm wrap">
                            Uncover a wealth of investment opportunities and resources on our Explore page
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="p-0 border-0 nav-item list-group-item">
                    <a class="py-2 dropdown-item ps-3 border-radius-md" href="/#/NFO">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="ni ni-laptop text-primary"></i>
                        </div>
                        <div>
                          <p class="p-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center">
                            NFO
                          </p>
                          <span class="text-sm wrap">Explore a diverse portfolio designed for strategic growth and
                            stability</span>
                        </div>
                      </div>
                    </a>
                  </li>

                </ul>
              </div>
              <div class="row d-lg-none">
                <div class="col-md-12 g-0">
                  <a class="py-2 dropdown-item ps-3 border-radius-md" href="/#/explore">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="fa-solid fa-magnifying-glass text-primary"></i>
                      </div>
                      <div>
                        <p class="p-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center">
                          Explore
                        </p>
                        <span class="text-sm  text-wrap">
                          Uncover a wealth of investment opportunities and resources on our Explore page
                        </span>
                      </div>
                    </div>
                  </a>
                  <a class="py-2 dropdown-item ps-3 border-radius-md" href="/#/NFO">
                    <div class="d-flex">
                      <div class="h-10 mt-1 icon me-3 d-flex">
                        <i class="ni ni-laptop text-primary"></i>
                      </div>
                      <div>
                        <p class="p-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center">
                          NFO
                        </p>
                        <span class="text-sm text-wrap">Explore a diverse portfolio designed for strategic growth and
                          stability</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li v-if="isLoggedIn" class="mx-2 nav-item dropdown dropdown-hover">
            <div class="row d-lg-none">
              <div class="col-md-12 g-0">
                <a class="py-2 dropdown-item ps-3 border-radius-md cursor-pointer" @click="signout">
                  <div class="d-flex">
                    <div class="h-10 mt-1 icon me-3 d-flex">
                      <i class="ni ni-user-run ni-lg text-primary"></i>
                    </div>
                    <div>
                      <p class="p-0 dropdown-header text-dark font-weight-bold d-flex align-items-center">
                        Log out
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </li>
        </ul>

        <ul v-if="!isLoggedIn && !isSignInPage" class="navbar-nav d-lg-block d-none me-2">
          <li class="nav-item">
            <router-link to="/signin">
              <button type="button" class="btn bg-gradient-secondary mb-0">Sign In</button></router-link>
          </li>
        </ul>
        <ul v-if="!isLoggedIn && !isExplorePage" class="navbar-nav d-lg-block d-none">
          <li class="nav-item">
            <router-link to="/explore">
              <button type="button" class="btn bg-gradient-primary mb-0">Explore</button></router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-lg-block d-none" v-if="isLoggedIn">
          <li class="nav-item">
            <div class="row d-flex">
              <div class="col-auto px-0">
                <ul class="navbar-nav">
                  <base-dropdown class="nav-item dropdown-left" tag="li" title-tag="a" title-classes="nav-link ps-4">
                    <template v-slot:title-container>
                      <a href="#" class="nav-link pr-0" @click.prevent>
                        <div class="d-flex align-items-center ms-2">
                          <span class="fa-stack fa-2x">
                            <i class="fas fa-circle fa-stack-2x text-white"></i>
                            <i class="fas fa-user-circle fa-stack-2x"></i>

                            <!-- Status Icon placed inside user icon -->
                            <i class="fas status-icon"
                              :class="[isActive ? 'fa-check-circle text-success' : 'fa-exclamation-circle text-danger']"></i>
                          </span>
                        </div>
                      </a>
                    </template>
                    <div class="dropdown-header noti-title pb-0">
                      <div class="row bg-light">
                        <div class="col-12 mt-3 d-flex">
                          <span class="fa-stack p-0" :class="displayName !== email ? 'mt-2' : 'mb-2'">
                            <i class="fas fa-user-circle fa-stack-2x text-primary ps-1"></i>
                            <i class="fas status-responsive-icon"
                              :class="[isActive ? 'fa-check-circle text-success' : 'fa-exclamation-circle text-danger']" ></i>
                          </span>
                          <div class="text-container d-flex flex-column avatar-max-width">
                            <p class="text-dark mb-1 text-sm ms-2" :title="displayName.length > 20 ? displayName : ''">
                              Welcome,
                              <b>{{ truncateName(displayName) }}</b>
                            </p>
                            <p class="text-dark mb-3 text-xs ms-2" v-if="displayName !== email">{{ email }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!isActive && isCognitoStatusValid" class="dropdown-header noti-title pt-0">
                      <div class="row bg-yellow">
                        <div class=" d-flex flex-column avatar-max-width px-2 my-2">
                          <span class="text-dark my-1 text-xs text-justify mx-1">
                            <b class="text-wrap">Your account verification is being processed.</b>
                          </span>
                          <span class="text-dark text-xs text-justify mx-1">
                            Please <a href="#" @click.prevent="handleBadgeClick" class="text-primary fw-bold">click
                              here</a> to complete it.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="signout()">
                      <div class="d-flex">
                        <div class="h-10 mt-1 icon me-3 d-flex">
                          <i class="ni ni-user-run ni-lg text-primary"></i>
                        </div>
                        <div>
                          <p class="p-0 dropdown-header text-dark font-weight-bold d-flex align-items-center">
                            Log out
                          </p>
                        </div>
                      </div>
                    </a>
                  </base-dropdown>
                </ul>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import BaseDropdown from "./BaseDropdown.vue";
import store from "@/store/index.js";
import { isOpenPath } from "@/components/web-interface/projectedPath";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "Navbar",
  components: {
    BaseDropdown,
  },
  props: {
    btnBackground: {
      type: String,
      default: "",
    },
    isBlur: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack,
      userName: "",
      cognitoStatus: "",
      isLoggedIn: false,
      email: "",
      formattedStatus: "IN PROGRESS",
    };
  },
  watch: {
    user(newval) {
      if (newval) {
        this.userName = newval.name;
        this.email = newval.email;
        this.cognitoStatus = newval.userStatus;
        if (this.cognitoStatus === "VERIFIED") {
          this.$store.dispatch("userStore/updateIsActive", true);
          sessionStorage.setItem("isActive", "true");
        }
      } else {
        this.userName = "";
        this.email = "";
        this.cognitoStatus = "";
      }
    },
    '$store.state.userStore.loggedIn'(newVal) {
      this.isLoggedIn = newVal;
    },
  },
  mounted() {
    this.isLoggedIn = this.$store.state.userStore.loggedIn;
    this.$store.dispatch("userStore/updateIsActiveFromStorage"); 
    let user = store.getters['userStore/getUser'];
    if (user) {
      this.userName = user.name;
      this.email = user.email;
      this.cognitoStatus = user.userStatus;
      if (this.cognitoStatus === "VERIFIED") {
      this.$store.dispatch("userStore/updateIsActive", true); 
    }
    } else {
      this.redirectIfUserNull();
    }
    this.setupDropdownListeners();
    document.addEventListener("click", this.handleOutsideNavbarClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideNavbarClick);
  },
  computed: {
    statusClass() {
      return this.formattedStatus === "ACTIVE" ? "bg-success" : "bg-primary";
    },
    user() {
      return store.getters['userStore/getUser'];
    },
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    },
    displayName() {
      if (!this.userName) {
        return this.email;
      }
      let formattedName = this.userName.toLowerCase().replace(/\b\w/g, (word) => {
        return word.toUpperCase();
      });
      return this.userName === this.email ? this.email : formattedName;
    },
    isEmailVerified() {
      const allowedStatuses = [
        'EMAIL_VERIFIED',
        'BSE_USER_MANDATE_COMPLETED',
        'BSE_USER_MANDATE_FAILED',
        'VERIFIED',
      ];
      return this.isLoggedIn && allowedStatuses.includes(this.cognitoStatus);
    },
    isSignInPage() {
      return this.$route.path === "/signin";
    },
    isExplorePage() {
      return this.$route.path === "/explore";
    },
    isCognitoStatusValid() {
    const allowedStatuses = [
      'EMAIL_VERIFIED',
      'BSE_USER_MANDATE_COMPLETED',
      'BSE_USER_MANDATE_FAILED',
    ];
    return allowedStatuses.includes(this.cognitoStatus);
  },
  isActive() {
      return store.getters['userStore/isActive'];

    },
  },
  methods: {
    redirectIfUserNull() {
      const currentPath = this.$route.path;
      if (!this.isLoggedIn && !isOpenPath(currentPath)) {
        this.$router.push("/signin");
      }
    },
    signout() {
      var self = this;
      this.$store
        .dispatch("userStore/logout", { root: true })
        .then(function (response) {
          console.log(response);
          self.isLoggedIn = false;
          self.$store.commit('userStore/clearState');
          self.$router.push("/signin");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setupDropdownListeners() {
  document.querySelector('.navbar').addEventListener('shown.bs.dropdown', (event) => {
    if (window.innerWidth >= 992) { 
      document.querySelectorAll('.navbar .dropdown-menu.show').forEach((dropdown) => {
        if (dropdown !== event.target) {
          bootstrap.Dropdown.getInstance(
            dropdown.closest('.dropdown').querySelector('[data-bs-toggle="dropdown"]')
          ).hide();
        }
      });
    }
  });
},
truncateName(name) {
    if (name) {
      let formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
      return formattedName.length > 20 ? formattedName.substring(0, 10) + '..' : formattedName;
    }
    return '';
  },
  handleOutsideNavbarClick(event) {
    const navbar = document.querySelector('.navbar-collapse');
    const toggleButton = document.querySelector('.navbar-toggler');
    if (navbar && toggleButton && navbar.classList.contains('show') && !navbar.contains(event.target) && !toggleButton.contains(event.target)) {
      toggleButton.click();
    }
  },
  handleBadgeClick() {
      if (this.formattedStatus !== "ACTIVE") {
        if (this.$route.name === 'Dashboard') {
          // Trigger the modal if already on the Dashboard
          this.$store.dispatch('productStore/triggerDashboardModal');
        } else {
          // Set the state and navigate to the Dashboard
          this.$store.dispatch('productStore/triggerDashboardModal');
          this.$router.push({ name: 'Dashboard' });
        }
      }
    },
  },
};
</script>