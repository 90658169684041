<template>
    <div class="card multisteps-form__panel selection-card-height border-radius-xl bg-white shadow-top-bottom"
        data-animation="FadeIn">
        <div class="row">
            <div class="col-12 col-lg-4">
                <LeftPanelView :progressStep="currentStep" :descriptionText="descriptionText" :pageName="pageName" />
            </div>
            <div class="col-12 col-lg-8 px-0 d-flex flex-column justify-content-between">
                <div class="multisteps-form__content">
                    <div class="card-body px-4 mb-6">
                        <form role="form text-left" @submit.prevent="stpFolio">
                            <div class="row scrollable-container mb-2 mt-4">
                                <div v-for="folio in filteredSchemes" :key="folio.folio"
                                    class="col-12 col-lg-4 pe-0 mb-2 mt-3">
                                    <soft-radio v-model="selectedFolioNumber" :value="folio.folio" checked="true"  rules="required:true" name="unchecked" :disabled="folio.isFullyLocked">
                                        <div class="w-100">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="mb-0 text-xs">Folio no:<b> {{ folio.folio }}</b></p>
                                            </div>
                                            <p class="text-xs d-flex mt-1">
                                                <span v-if="folio.type === 'SIP'"
                                                    class="me-3 badge badge-secondary mt-1">
                                                    <i class="fa-solid fa-tags me-2"></i>{{ folio.type }}
                                                </span>
                                                <span v-if="folio.isFullyLocked || folio.isPartiallyLocked"
                                                    class="text-danger mt-1 me-2">
                                                    <i class="fa fa-lock text-xs text-primary cursor-pointer"
                                                        data-bs-toggle="popover" data-bs-trigger="hover"
                                                        data-bs-placement="bottom"
                                                        :data-bs-content="getPopoverContent(folio)"
                                                        aria-label="Folio summary"></i>
                                                </span>
                                                <span v-if="!folio.isFullyLocked" class="mt-1">₹{{
                                                    formatAmount(folio.displayAmount) }}</span>
                                            </p>
                                        </div>
                                    </soft-radio>
                                </div>
                            </div>
                            <div v-if="error" class="text-danger text-xs mb-5 mt-3 mt-xl-7 text-center">{{ error }}
                            </div>
                        </form>
                    </div>
                </div>
                <div class="d-flex justify-content-end align-items-center px-4 py-3">
                    <soft-button ref="proceedButton" class="bg-primary icon-move-right" @click.prevent="stpFolio">
                        Next
                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    </soft-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/views/common/components/SoftButton.vue";
import SoftRadio from "@/components/SoftRadio.vue";
import { mapGetters } from "vuex";
import LeftPanelView from '@/views/common/components/LeftPanelView.vue';
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
    name: "Folios",
    components: {
        SoftButton,
        SoftRadio,
        LeftPanelView,
    },
    props: {
        currentStep: Number,
    },
    data() {
        return {
            schemes: [],
            selectedFolioNumber: null,
            error: "",
            pageName: "Folio",
            descriptionText: "Select the folio that aligns best with your investment strategy to move forward.",
        }
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));
        const switchFund = this.getSwitchFund;
        const fundId = switchFund.fundId;
        if (!fundId) {
            this.redirect();
        }
        const sortedSchemes = this.filteredSchemes;
        const firstSelectableFolio = sortedSchemes.find(folio => !folio.isFullyLocked);
        if (firstSelectableFolio) {
            this.selectedFolioNumber = firstSelectableFolio.folio;
        }
        setTimeout(() => {
            const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
            popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl);
            });
        }, 100);
    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
        filteredSchemes() {
            return this.selectedSchemes
                .filter(folio => folio.currentValue >= folio.minimumRedemptionAmount)
                .map(folio => {
                    const lockedCurrentAmount = Math.round(folio.nav * folio.lockInQuantity * 100) * 0.01;
                    const isFullyLocked = folio.currentValue === lockedCurrentAmount;
                    const isPartiallyLocked = lockedCurrentAmount > 0 && lockedCurrentAmount < folio.currentValue;
                    const unlockedAmount = isPartiallyLocked ? folio.currentValue - lockedCurrentAmount : folio.currentValue;
                    const displayAmount = isPartiallyLocked ? unlockedAmount : folio.currentValue;
                        return {
                            ...folio,
                            isFullyLocked,
                            isPartiallyLocked,
                            unlockedAmount,
                            displayAmount,
                        };
                })
                .filter(Boolean)
                .sort((a, b) => b.currentValue - a.currentValue);
        },
    },
    methods: {
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
        },
        redirect() {
            this.$router.push("/portfolio");
        },
        stpFolio() {
            if (!this.selectedFolioNumber) {
                this.error = "Please select a folio before proceeding.";
                return;
            }

            const selectedFolio = this.filteredSchemes.find(folio => folio.folio === this.selectedFolioNumber);
            if (selectedFolio) {
                this.$emit('update-folio', {
                    folioNumber: this.selectedFolioNumber,
                    amount: Math.floor(selectedFolio.unlockedAmount),
                });
            }
            this.$parent.nextStep();
        },
        getPopoverContent(folio) {
        if (folio.isFullyLocked) {
            return "This folio is fully locked due to the lock-in period.";
        } else if (folio.isPartiallyLocked) {
            return `Only ₹${this.formatAmount(folio.unlockedAmount)} is available for switch. The remaining ₹${this.formatAmount(folio.lockInAmount)} is locked.`;
        }
        return "";
    },
    }
};
</script>