<template>
  <div v-if="nameParam" class="py-1 pt-7 container position-relative">
    <DetailHeader :title=getTitle(nameParam) :description="{
      text: getDescriptionForNameParam(nameParam),
      class: 'pe-md-5 me-md-5 opacity-8',
    }" :firstpoint="{
  text: getFirstpointForNameParam(nameParam),
  class: 'pe-md-5 me-md-5 opacity-8',
}" :secondpoint="{
  text: getSecondpointForNameParam(nameParam),
  class: 'pe-md-5 me-md-5 opacity-8',
}" :thirdpoint="{
  text: getThirdpointForNameParam(nameParam),
  class: 'pe-md-5 me-md-5 opacity-8',
}" mask="mask bg-white opacity-2">

    </DetailHeader>
  </div>
  <div v-else class="py-1 pt-7  container position-relative">
    <div class="mt-3 row w-100">
      <div class="row m-4">
        <div class=" h6">Filter</div>
        <div class="row">
          <div class="col-12 col-lg-4 mb-2 mb-lg-0">
            <select id="choices-category" class="form-control" v-model="selectedAmc" name="choices-category"
              @change="setTypes()">
            </select>
          </div>
          <div class="col-12 col-lg-2 mb-2 mb-lg-0">
            <select id="choices-schemetype" ref="schemeTypes" class="form-control" name="choices-schemetype"
              v-model="selectedType" @change="setGdos()">
              <option value="">Scheme Types</option>
            </select>
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <select id="choices-gdos" ref="gdos" class="form-control" v-model="selectedGdos" @change="selectGdos()"
              name="choices-gdos">
              <option value="">Options</option>
            </select>
          </div>
          <div class="col-12 col-lg-2 d-flex align-items-end ">
            <soft-button color="primary" variant="outline" size="sm" class="mb-1"
              @Click="applyFilter()">&nbsp;&nbsp;Explore</soft-button>
          </div>
        </div>
      </div>
      <div class="d-flex val_height  justify-content-center"><span v-if="aplyValid" class="text-danger">
          Please select all the valid filters
        </span>
      </div>
    </div>
  </div>
  <div class=" mb-10 container position-relative">
    <div class="table-responsive pt-3">
      <div class="d-flex px-4 justify-content-end">
        <div class="col-12 col-lg-3 ">
          <div class="d-flex align-items-center">
            <div class="form-group w-100">
              <div class="input-group bg-gray-200">
                <input v-model="fiterText" class="form-control form-control-sm" type="search"
                  placeholder="Search for schemes" @Search="inputControl()" />
              </div>
            </div>
            <a href="javascript:;" class="btn btn-sm bg-primary ms-2 px-3 text-white"
              :class="[{ 'disabled': fiterText.length < 3 }]" @click="filterTables()"
              :disabled="fiterText.length < 3">Search</a>
          </div>
        </div>
      </div>
      <table id="datatable-search" class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th class="text-uppercase header_width text-secondary text-xxs font-weight-bolder opacity-7">
              Scheme Name
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Min Amount
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-5">
              Actions
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import SoftButton from "@/views/common/components/SoftButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
import Choices from "choices.js";
import DetailHeader from "../../views/common/components/DetailHeader.vue";
export default {
  name: "DataTables",
  components: {
    SoftButton,
    DetailHeader,
  },
  data() {
    return {
      dataTab: null,
      amcCodes: [],
      schemeTypes: [],
      choiceSchemTypes: {},
      choiceGdos: {},
      aplyValid: false,
      gdos: null,
      selectedAmc: "",
      selectedType: "",
      selectedGdos: "",
      fiterText: "",
      direction: "",
      pageNo: 1,
      bugFlag: true,
      lastModifiedDate: ",",
      nameParam: "",
    };
  },
  mounted() {
    const nameParam = this.$route.query.name;
    this.nameParam = nameParam;
    setTooltip(this.$store.state.bootstrap);
    this.amcCallS();
    if (this.dataTab) {
      this.dataTab.destroy();
      this.dataTab = null;
    }
   
    var element = document.getElementById("choices-schemetype");
    this.choiceSchemTypes = new Choices(element, {
      searchEnabled: true,
      allowHTML: false,
      placeholder: true,
      placeholderValue: "SCHEMETYPE",
      itemSelectText: '',
      searchPlaceholderValue: 'Search Types',
      shouldSort: false,
      position: "bottom",
      fuseOptions: { threshold: 0.0 }

    })

    element = document.getElementById("choices-gdos");
    this.choiceGdos = new Choices(element, {
      searchEnabled: true,
      placeholder: true,
      placeholderValue: "options",
      itemSelectText: '',
      searchPlaceholderValue: 'Search options',
      shouldSort: false,
      position: "bottom",
      fuseOptions: { threshold: 0.0 }


    });
    this.choiceSchemTypes.disable();
    this.choiceGdos.disable();
    if (this.nameParam !== null && this.nameParam !== undefined) {
      this.Filter();
    }
  },beforeUnmount() {
    if (this.dataTab) {
      this.dataTab.destroy();
      this.dataTab = null;
    }
  }, methods: {
    getTitle(nameParam) {
      // Set custom titles based on nameParam
      switch (nameParam) {
        case "LIQUID":
          return "Liquid Fund";
        case "EQUITY":
          return "Equity Fund ";
        case "DEBT":
          return "Debt Fund ";
        case "ELSS":
          return "Tax Saving Fund";
        case "HYBRID (NC)":
          return "Hybrid Fund ";
        case "FOF":
          return "International Fund ";
        default:
          return "Default Title";
      }
    },
    getDescriptionForNameParam(nameParam) {
      switch (nameParam) {
        case "LIQUID":
          return "rioritize high liquidity and short-term returns with Liquid Funds. These funds invest in short-term market instruments and are ideal for emergency funds or short-term goals.";
        case "EQUITY":
          return "Experience the potential for high returns with Equity Funds, ideal for long-term growth through investment in company stocks. Suitable for those with a higher risk appetite.";
        case "DEBT":
          return "Explore debt funds for a more stable and lower-risk investment path. Perfect for those prioritizing consistent income and capital preservation.";
        case "HYBRID (NC)":
          return "Get the best of both worlds with hybrid funds, balancing the growth of equities and the stability of debt. Suited for investors looking for a diversified approach.";
        case "FOF":
          return "Expand your portfolio globally with international funds. A great opportunity to diversify and tap into the potential of foreign markets";
        case "ELSS":
          return "Benefit from the dual advantage of tax saving and potential for growth with Equity-Linked Savings Schemes (ELSS). Ideal for tax-conscious investors.";
        default:
          return "Default Description";
      }
    },
    getFirstpointForNameParam(nameParam) {
      switch (nameParam) {
        case "LIQUID":
          return "High Liquidity";
        case "EQUITY":
          return "Portfolio Diversification";
        case "DEBT":
          return "High Return Potential";
        case "HYBRID (NC)":
          return "Balanced Exposure";
        case "FOF":
          return "Global Diversification";
        case "ELSS":
          return "Tax Efficiency";
        default:
          return "Default Description";
      }
    },
    getSecondpointForNameParam(nameParam) {
      switch (nameParam) {
        case "LIQUID":
          return "Short-Term Investment";
        case "EQUITY":
          return "Capital Growth";
        case "DEBT":
          return "Market-Linked Growth";
        case "HYBRID (NC)":
          return "Versatility";
        case "FOF":
          return "Access to Foreign Markets";
        case "ELSS":
          return "Growth Potential";
        default:
          return "Default Description";
      }
    },
    getThirdpointForNameParam(nameParam) {
      switch (nameParam) {
        case "LIQUID":
          return "Lower Risk";
        case "EQUITY":
          return "Diverse Options";
        case "DEBT":
          return " Capital Preservation";
        case "HYBRID (NC)":
          return "Diversification";
        case "FOF":
          return "Currency Advantage";
        case "ELSS":
          return "Dual Benefits";
        default:
          return "Default Description";
      }
    },

    inputControl() {

      if (this.fiterText.length > 0) {
        this.filterTables();
      }
      else {
        this.applyFilter();
      }
    },
    navigate() {
      this.$router.push({ path: '/e' });
    },
    validateFilter() {
      if (this.fiterText.length < 3) {
        return true;
      }
      return false;
    },
    amcCallS() {
      var self = this;
      var element = document.getElementById("choices-category");
      new Choices(element, {
        allowHTML: false,
        placeholder: true,
        placeholderValue: 'MUTUAL FUNDS',
        searchPlaceholderValue: 'Search Mutual Funds',
        itemSelectText: '',
        position: "bottom",
        searchFields: ['value'],
        fuseOptions: { threshold: 0.0 }
      }).setChoices(function () {
        return self.$store
          .dispatch("schemeStore/getAmcs", { root: true })
          .then(function (response) {
            self.amcCodes = response.server.data;
            return response.server.data;
          }).catch(function (error) {
            console.log(error);
            return [];
          });
      }, 'id', 'value', true);
    },
    hideValidation() {
      if (this.aplyValid == true) {
        this.aplyValid = false;
      }
    },
    setTypes() {
      if (this.dataTab != null) {
        this.dataTab.destroy();
        this.dataTab = null;
      }
      this.gdos = null;
      var self = this;
      this.hideValidation();
      this.selectedType = "";
      this.selectedGdos = "";
      this.choiceGdos.clearStore();
      this.choiceGdos.setChoices(function () {
        return [{ id: "", value: "Options" }];
      }, 'id', 'value', true);
      this.choiceGdos.setChoiceByValue('');
      this.choiceGdos.disable();
      this.choiceSchemTypes.clearStore();
      this.choiceSchemTypes.setChoices(function () {
        return self.$store
          .dispatch("schemeStore/getSchemeTypes", self.selectedAmc, { root: true })
          .then(function (response) {
            return response.server.data;
          }).catch(function (error) {
            console.log(error);
            return [];
          });
      });
      this.choiceSchemTypes.enable();
    },
    setGdos() {
      if (this.dataTab != null) {
        this.dataTab.destroy();
        this.dataTab = null;
      }
      this.gdos = null;
      this.hideValidation();
      let data = {
        "schemeType": this.selectedType,
        "amcs": this.selectedAmc
      };
      var self = this;
      this.choiceGdos.clearStore();
      this.choiceGdos.setChoices(function () {
        return self.$store
          .dispatch("schemeStore/getGdos", data, { root: true })
          .then(function (response) {
            return response.server.data;
          }).catch(function (error) {
            console.log(error);
            return [];
          });
      }, 'id', 'value', true);
    },
    selectGdos() {
      this.hideValidation();
      this.gdos = this.selectedGdos;
      if (this.dataTab != null) {
        this.dataTab.destroy();
        this.dataTab = null;
      }
    },
    setTables(pageNo, pageSize, sortBy, direction, filter = null) {
      // let gdos = this.gdos;
      if (this.gdos == null) {
        this.aplyValid = true;
        return
      }
      let data = {
        "schemeType": this.selectedType,
        "amcs": this.selectedAmc,
        "gdos": this.selectedGdos,
        "pageNo": pageNo - 1,
        "pageSize": pageSize,
        "sortBy": sortBy,
        "direction": direction,
        "filter": filter,
      };
      var self = this;
      this.$store
        .dispatch("schemeStore/getAllSchemes", data, { root: true })
        .then(response => {
          let totalElements = response.server.data.totalElements
          let currentPage = response.server.data.currentPage;
          let perPage = response.server.data.perPage;
          let runningIndex = currentPage * perPage;
          let schemeDetails = response.server.data.userProducts;
          let tbody = [];
          if (schemeDetails.length !== 0) {
            for (let i = 0; i < totalElements; i++) {
              var prependValue = "!";
              var appendValue = "~"
              if (direction == "DESC") {
                prependValue = "~"
                appendValue = "!"
              }
              if (i < runningIndex) {
                tbody.push([prependValue, '', '', '']);
              } else if (i >= (runningIndex + perPage)) {
                tbody.push([appendValue, '', '', '']);
              }
              else {
                tbody.push(["someThing", "someThing", "someThing", "someThing"])
              }
            }
            // let obj =[]
            for (let item of schemeDetails) {

              tbody[runningIndex] = [item.schemeName, item.minimumPurchaseAmount, item.uniqueNo];
              runningIndex++;
            }
            self.updateTable(tbody, self, pageNo, direction.toLowerCase());
          }
        }).catch(error => {
          console.log(error)
        });
    },
    Filter() {
      this.setTable("1", "10", "schemeName", "ASC", null);

    },
    setTable(pageNo, pageSize, sortBy, direction, filter = null) {
      // let gdos = this.gdos;

      let data = {
        "schemeType": this.nameParam,
        "pageNo": pageNo - 1,
        "pageSize": pageSize,
        "sortBy": sortBy,
        "direction": direction,
        "filter": filter,
      };
      var self = this;
      this.$store
        .dispatch("schemeStore/filterSchemesBySchemeType", data, { root: true })
        .then(response => {
          let totalElements = response.server.data.totalElements
          let currentPage = response.server.data.currentPage;
          let perPage = response.server.data.perPage;
          let runningIndex = currentPage * perPage;

          let schemeDetails = response.server.data.userProducts;
          let tbody = [];
          if (schemeDetails.length !== 0) {
            for (let i = 0; i < totalElements; i++) {
              var prependValue = "!";
              var appendValue = "~"
              if (direction == "DESC") {
                prependValue = "~"
                appendValue = "!"
              }
              if (i < runningIndex) {
                tbody.push([prependValue, '', '', '']);
              } else if (i >= (runningIndex + perPage)) {
                tbody.push([appendValue, '', '', '']);
              }
              else {
                tbody.push(["someThing", "someThing", "someThing", "someThing"])
              }
            }
            // let obj =[]
            for (let item of schemeDetails) {



              tbody[runningIndex] = [item.schemeName, item.minimumPurchaseAmount, item.uniqueNo];
              runningIndex++;
            }
            self.updateTable(tbody, self, pageNo, direction.toLowerCase());
          }
        }).catch(error => {
          console.log(error)
        });
    },

    filterTables() {
      if (this.nameParam) {
        this.setTable("1", "10", "schemeName", "ASC", this.fiterText);
      }
      else {
        this.setTables("1", "10", "schemeName", "ASC", this.fiterText);
      }
    },

    applyFilter() {
      this.fiterText = ""
      this.setTables("1", "10", "schemeName", "ASC", null);

    },
    applySort(column, direction) {
      this.direction = direction;
      if (this.fiterText == "") {
        this.setTables("1", "10", "schemeName", direction, null);
        this.setTable("1", "10", "schemeName", direction, null);
      }
      else {
        this.setTables("1", "10", "schemeName", direction, this.fiterText);
        this.setTable("1", "10", "schemeName", direction, this.fiterText);
      }
    },
    applyPagination(pageNo) {
      this.pageNo = pageNo;
      if (this.fiterText == "") {
        this.setTables(pageNo, "10", "schemeName", this.direction, null);
        this.setTable(pageNo, "10", "schemeName", this.direction, null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      else {
        this.setTables(pageNo, "10", "schemeName", this.direction, this.fiterText);
        this.setTable(pageNo, "10", "schemeName", this.direction, this.fiterText);

      }
    },



    updateTable(tbody, self, pageNo, direction) {

      if (self.dataTab != null) {
        self.dataTab.destroy();
      }
      self.dataTab = new DataTable("#datatable-search", {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        columns: [
          {
            select: 0, sort: direction, sortable: true,
            render: function (data, td, rowIndex, cellIndex) {
              const items = data.split('-');
              var tableData = `<div class="text-wrap " name='td_${cellIndex}' data-row='${rowIndex}'>`
              tableData = tableData + `<span class="text-sm font-weight-bolder text-capitalize">${items[0].toLowerCase()}</span>`;
              if (items[1]) {
                tableData = tableData + `
                                          <div>
                                              <span class="text-sm font-weight-lighter  text-capitalize">${items[1].toLowerCase()}
                                         `
              }
              if (items[2]) {
                tableData = tableData + `- ${items[2].toUpperCase()}`
              }
              tableData = tableData + `</span> </div> </div>`
              return tableData;
            }
          },
          {
            select: 1, sortable: false, render: function (data, td, rowIndex, cellIndex) {
              const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              };
              const formatted = Number(data).toLocaleString('en', options);
              return `<div class="text-sm font-weight-normal text-capitalize" name='td_${cellIndex}' data-row='${rowIndex}'><span>₹ ${formatted}</span></div>`;
            }
          },
          {
            select: [2], sortable: false, render: function (data) {
              return '<a class="btn btn-link text-primary px-3 mb-0" href="/#/details/' + data + '" target="_self"><i class="fas fa-check text-primary me-2"   aria-hidden="true"></i>Details </a>';
            }
          },

        ],
        data: {
          "data": tbody
        }
      });
      self.dataTab.page(Number(pageNo ? pageNo : 1))
      self.dataTab.on('datatable.sort', function (column, direction) {
        self.applySort(column, direction.toUpperCase());
      });
      self.dataTab.on('datatable.page', function (page) {
        self.applyPagination(page);
      });
      setTimeout(() => {
    document.querySelectorAll('.dataTable-pagination .ellipsis').forEach((ellipsis) => {
      ellipsis.addEventListener('click', (event) => {
        event.preventDefault();
      });
    });
  },);
    },
  }
};
</script>
<style>
.dataTable-wrapper .dataTable-top {
  padding: 5px !important;
}

.filter-img {
  font-size: 0.90em !important;
}

.info-icon {
  height: 5px;
  width: 5px;
}

.choices__list--dropdown .choices__item--selectable {
  padding-right: 0px !important;
}

.val_height {
  height: 10px;
}

.choices__item {
  font-size: .7em !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

/* clears the ‘X’ from Chrome */
input[type="search"].choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__list--dropdown .choices__list {
  max-height: 200px !important;
}

.header_width {
  width: 50%;
  min-width: 250px;
}</style>