<template>
    <div class="pt-5 mt-1 pt-md-6 mt-xl-0 pt-xl-6 custom-container">
        <SelectionBand v-if="activeStep < 3" class="fixed-banner" :folio-data="folioData"
            :selectedAmount="selectedAmount" :schemeData="toSchemes" />
    </div>
    <div class="container-fluid pt-md-7 pt-11">
        <div class="row">
            <div class="col-12">
                <div class="multisteps-form mb-5">
                    <!--form panels-->
                    <div class="row">
                        <div :class="['col-12 col-xl-10 m-auto']">
                            <form :class="['multisteps-form__form mb-5 mx-md-4 mx-xl-5']">
                                <folio :class="activeStep === 0 ? activeClass : ''" @update-folio="handleFolioUpdate" :currentStep="activeStep"/>
                                <SwitchAmount :class="activeStep === 1 ? activeClass : ''" :folioData="folioData"
                                    @update="handleUpdate" :currentStep="activeStep"/>
                                <SelectedScheme :class="activeStep === 2 ? activeClass : ''"
                                    @selection="handleSelection" :selectedAmount="selectedAmount" :currentStep="activeStep"/>
                                <Review :class="activeStep === 3 ? activeClass : ''" :folioData="folioData"
                                    :selectedAmount="selectedAmount" :schemeData="toSchemes" @order-id="updateOrderId" :currentStep="activeStep"/>
                                <SwitchAuthentication :class="activeStep === 4 ? activeClass : ''"
                                    @update="handleWorkFlow" :currentStep="activeStep" :orderId="orderId" :folioData="folioData" :schemeData="toSchemes"/>
                                <SwitchConfirm :class="activeStep === 5 ? activeClass : ''" :message="workFlow"
                                    :selectedAmount="selectedAmount" :orderId="orderId"  :currentStep="activeStep"/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Folio from "./components/Folios.vue";
import SelectionBand from "@/views/common/components/SelectionBand.vue";
import SwitchAmount from "./components/SwitchAmount.vue";
import SelectedScheme from "./components/SelectedToScheme.vue";
import Review from "./components/SwitchReview.vue";
import SwitchAuthentication from "./components/SwitchAuthentication.vue";
import SwitchConfirm from "./components/SwitchConfirmation.vue";
import { mapGetters } from "vuex";

export default {
    name: "Switch",
    components: {
        Folio,
        SelectionBand,
        SwitchAmount,
        SelectedScheme,
        Review,
        SwitchAuthentication,
        SwitchConfirm,
    },
    data() {
        return {
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 5,
            folioData: null,
            schemes: [],
            selectedAmount: "",
            toSchemes: null,
            workFlow: [],
            switchFund: [],
            orderId: "",
        };
    },
    mounted() {
        this.schemes = JSON.parse(sessionStorage.getItem('schemes'));

    },
    computed: {
        ...mapGetters("orderStore", ["getSwitchFund"]),
        selectedSchemes() {
            return this.schemes.filter((c) => c.fundId == this.getSwitchFund.fundId);
        },
    },
    methods: {
        nextStep() {
            if (this.activeStep < this.formSteps) {
                this.activeStep += 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            } else {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth' 
            });
            }
        },
        prevStep() {
            if (this.activeStep > 0) {
                this.activeStep -= 1;
                window.scrollTo({
                top: 0,
                behavior: 'smooth' 
            });
            }
        },
        handleFolioUpdate(data) {
            this.folioData = data;
        },
        handleUpdate(data) {
            this.selectedAmount = data;
        },
        handleSelection(data) {
            this.toSchemes = data;
        },
        handleWorkFlow(data) {
            this.workFlow = data;
        },
        updateOrderId(data) {
            this.orderId = data;
        }
    },
};
</script>