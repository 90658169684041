import requestCommon from "@/components/web-interface/requestCommon";

const state = {
};
const actions = {
    getAllSip(context, data) {
        return requestCommon.getInfo(
            "/us/sips?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
            "&sortBy=" + data.sortBy + "&direction=" + data.direction,
            data,
            null,
            context,
            "getAllSipSuccessHandler",
            "getAllSipErrorHandler"
        );
    },
    getAllStp(context, data) {
        return requestCommon.getInfo(
            "/us/stps?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
            "&sortBy=" + data.sortBy + "&direction=" + data.direction,
            data,
            null,
            context,
            "getAllStpSuccessHandler",
            "getAllStpErrorHandler"
        );
    },
    getAllSwp(context, data) {
        return requestCommon.getInfo(
            "/us/swps?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize +
            "&sortBy=" + data.sortBy + "&direction=" + data.direction,
            data,
            null,
            context,
            "getAllSwpSuccessHandler",
            "getAllSwpErrorHandler"
        );
    },
    manageOrders(context, data) {
        return requestCommon.postInfo(
            "/us/plans/" + data.planId + "/" + data.type + "/" + data.transaction,
            null,
            data,
            context,
            "manageOrdersSuccessHandler",
            "manageOrdersErrorHandler"
        );
    },
    skipOrder(context, data) {
        return requestCommon.putInfo(
            "/us/plans/" + data.orderId + "/" + data.type + "?subType=" + data.subType,
            null,
            data,
            context,
            "skipOrderSuccessHandler",
            "skipOrderErrorHandler"
        );
    },
    cancelOrders(context, data) {
        return requestCommon.putInfo(
            "/us/plans/" + data.orderId + "/" + data.type + "?subType=" + data.subType,
            null,
            data,
            context,
            "cancelOrdersSuccessHandler",
            "cancelOrdersErrorHandler"
        );
    },
    resentOtp(context, data) {
        return requestCommon.patchInfo(
            "/us/plans/" + data.orderId + "/" + data.type,
            null,
            data,
            context,
            "resentOtpSuccessHandler",
            "resentOtpErrorHandler"
        );
    },


};
const mutations = {
    getAllSipSuccessHandler(context, data) {
        console.log(data);
    },
    getAllSipErrorHandler(context, error) {
        console.log(error);
    },
    getAllStpSuccessHandler(context, data) {
        console.log(data);
    },
    getAllStpErrorHandler(context, error) {
        console.log(error);
    },
    getAllSwpSuccessHandler(context, data) {
        console.log(data);
    },
    getAllSwpErrorHandler(context, error) {
        console.log(error);
    },
    manageOrdersSuccessHandler(context, data) {
        console.log(data);
    },
    manageOrdersErrorHandler(context, error) {
        console.log(error);
    },
    skipOrderSuccessHandler(context, data) {
        console.log(data);
    },
    skipOrderErrorHandler(context, error) {
        console.log(error);
    },
    cancelOrdersSuccessHandler(context, data) {
        console.log(data);
    },
    cancelOrdersErrorHandler(context, error) {
        console.log(error);
    },
    resentOtpSuccessHandler() {
        console.log("successfull resent otp");
    },
    resentOtpErrorHandler(context, error) {
        console.log("otp resent failed" + error);
    },
};
const getters = {
};
export const manageStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};